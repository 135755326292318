import * as React from "react";
import Svg, { Path } from "react-native-svg";
const ClosedIcon = (props) => (
  <Svg
    width={11}
    height={11}
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M7.32306 4.82761C7.40667 4.73789 7.45219 4.61921 7.45002 4.49659C7.44786 4.37397 7.39819 4.25697 7.31146 4.17025C7.22474 4.08353 7.10775 4.03385 6.98512 4.03169C6.8625 4.02953 6.74383 4.07504 6.6541 4.15865L4.77974 6.03301L3.85203 5.1053C3.7623 5.02169 3.64363 4.97617 3.521 4.97834C3.39838 4.9805 3.28138 5.03017 3.19466 5.1169C3.10794 5.20362 3.05827 5.32061 3.0561 5.44324C3.05394 5.56586 3.09946 5.68453 3.18306 5.77426L4.44526 7.03645C4.53401 7.12509 4.65431 7.17488 4.77974 7.17488C4.90517 7.17488 5.02547 7.12509 5.11422 7.03645L7.32306 4.82761Z"
      fill="white"
    />
    <Path
      d="M10.3018 5.59755C10.3018 6.93656 9.76992 8.22074 8.82309 9.16757C7.87626 10.1144 6.59208 10.6463 5.25306 10.6463C3.91404 10.6463 2.62987 10.1144 1.68304 9.16757C0.736208 8.22074 0.204285 6.93656 0.204285 5.59755C0.204285 4.25853 0.736208 2.97435 1.68304 2.02752C2.62987 1.08069 3.91404 0.548767 5.25306 0.548767C6.59208 0.548767 7.87626 1.08069 8.82309 2.02752C9.76992 2.97435 10.3018 4.25853 10.3018 5.59755ZM9.3552 5.59755C9.3552 4.50959 8.92301 3.4662 8.15371 2.6969C7.38441 1.9276 6.34102 1.49541 5.25306 1.49541C4.16511 1.49541 3.12172 1.9276 2.35242 2.6969C1.58312 3.4662 1.15093 4.50959 1.15093 5.59755C1.15093 6.6855 1.58312 7.72889 2.35242 8.49819C3.12172 9.26749 4.16511 9.69968 5.25306 9.69968C6.34102 9.69968 7.38441 9.26749 8.15371 8.49819C8.92301 7.72889 9.3552 6.6855 9.3552 5.59755Z"
      fill="white"
    />
  </Svg>
);
export default ClosedIcon;
