import React, { useContext, useState, useEffect } from 'react';
import {
  useFonts,
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
} from '@expo-google-fonts/inter';
import { StyleSheet, View, Platform, Dimensions } from 'react-native';
import { Card, Text } from 'react-native-paper';
import { Column as Col, Row } from 'react-native-flexbox-grid';
import tw from 'twrnc';
import { colors } from '../config/theme';
import { ThemeContext } from '../contexts/themeContext';
import CustomCarousel from './customCarousel';
import BannerGitHub from '../assets/images/bannerGitHub';
import BannerAssetsLogo from '../assets/images/bannerAssetsLogo';
import BannerFuelLogo from '../assets/images/bannerFuelLogo';
import CustomPopup from './customPopup';

export default function Banner() {

 let [fontsLoaded] = useFonts({
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
  });
  
  const { theme } = useContext(ThemeContext);
  const activeColors = colors[theme.mode];

  const isWeb = Platform.OS === 'web';

  // Responsive column grid
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width);

  useEffect(() => {
    const handleWindowResize = ({ window }) => {
      setWindowWidth(window.width);
    };

    Dimensions.addEventListener('change', handleWindowResize);

    return () => {
      Dimensions.removeEventListener('change', handleWindowResize);
    };
  }, []);

  const calculateWidth = (sm, md, lg) => {
    let columns = 12; // Default column number for extra small devices

    if (windowWidth >= 320 && windowWidth < 1200) {
      columns = sm;
    } else if (windowWidth >= 1200 && windowWidth < 1230) {
      columns = md;
    } else if (windowWidth >= 1230) {
      columns = lg;
    }

    // Calculate percentage (i.e., '4 columns' out of '12 columns' is '33.33%')
    return `${(columns / 12) * 100}%`;
  };

  const notMobile = windowWidth >= 1200; // Not mobile devices

  const handleModalClose = () => {
  };

  const texts = [
    {
      content: (
        <View style={tw`w-full`}>
          <Row size={12}>
            <Col style={{ width: calculateWidth(12, 6, 6) }}>
              <View style={tw`pl-5`}>
                <Text style={[tw`mt-8 mb-5`, notMobile ? tw`text-2xl` : tw`text-xl`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interExtraBold }]}>
                  Connect Your Github
                </Text>
                <Text style={[notMobile ? tw`text-base` : tw`text-sm `, { color: activeColors.secondaryTextColor }]}>
                  If you’re a developer looking to solve issues, make sure your GitHub account is connected in settings.
                </Text>
              </View>
            </Col>
            <Col style={[{ width: calculateWidth(12, 6, 6) }]}>
              <View style={tw`pl-5`}>
                <View style={tw`flex-row justify-start items-center ml-32`}>
                  {notMobile ? <BannerGitHub />  : ""}
                </View>
              </View>
            </Col>
          </Row>
        </View>
      ),
    },
    
    {
      content: (
        <View style={tw`w-full`}>
          <Row size={12}>
            <Col style={{ width: calculateWidth(12, 6, 6) }}>
              <View style={tw`pl-5`}>
                <Text style={[tw`mt-8 mb-5`, notMobile ? tw`text-2xl` : tw`text-xl`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interExtraBold }]}>
                  Explore Funded Issues
                </Text>
                <Text style={[notMobile ? tw`text-base` : tw`text-sm `, { color: activeColors.secondaryTextColor }]}>
                  Visit the Explore tab to view funded issues. Work on these issues as you normally would on GitHub. We'll monitor your pull requests.
                </Text>
              </View>
            </Col>
            <Col style={[{ width: calculateWidth(12, 6, 6) }]}>
              <View style={tw`pl-5`}>
                <View style={tw`flex-row justify-start items-center ml-32`}>
                  {notMobile ? <BannerFuelLogo style={[tw`relative top-2.5`, {width: 109, height: 144}]} />  : ""}
                </View>
              </View>
            </Col>
          </Row>
        </View>
      ),
    },

    {
      content: (
        <View style={tw`w-full`}>
          <Row size={12}>
            <Col style={{ width: calculateWidth(12, 6, 6) }}>
              <View style={tw`pl-5`}>
                <Text style={[tw`mt-8 mb-5`, notMobile ? tw`text-2xl` : tw`text-xl`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interExtraBold }]}>
                  Fund Your Wallet
                </Text>
                <Text style={[notMobile ? tw`text-base` : tw`text-sm `, { color: activeColors.secondaryTextColor }]}>
                  Looking to fund issues or withdraw your assets? Make sure you sufficient funds and gas.
                </Text>
              </View>
            </Col>
            <Col style={[{ width: calculateWidth(12, 6, 6) }]}>
              <View style={tw`pl-5`}>
                <View style={tw`flex-row justify-start items-center ml-32`}>
                  {notMobile ? <BannerAssetsLogo />  : ""}
                </View>
              </View>
            </Col>
          </Row>
        </View>
      ),
    },
  ];

  return (

    <View style={tw`w-full`}>
      <Card elevation={0} style={[tw`mb-0 pt-0.5 pr-2.5 pl-2.5 pb-2`]}>
        <Card.Content style={[tw`rounded-xl pt-0 pb-0`, { backgroundColor: activeColors.cardBgColor }, theme.mode === 'dark' ? styles.darkBoxshadow : styles.boxShadow]}>
          <Row size={12} style={tw`justify-between`}>
            <Col style={[tw``, { width: calculateWidth(12, 12, 12) }]}>
              <CustomCarousel texts={texts} />
            </Col>
          </Row>
        </Card.Content>
      </Card>
    </View>

  );
}

const styles = StyleSheet.create({
  boxShadow: {
    boxShadow: '0px 2px 14px 0px rgba(189, 193, 209, 0.30)',
  },
  darkBoxshadow: {
    boxShadow: 'none',
  },
});