import {API_BASE_PATH} from '@env'
import axios from 'axios';

export class walletApiService {
    static instance: walletApiService = new walletApiService();

	async getWalletAddress(assetId: string) {
        var res = await axios.get(`${API_BASE_PATH}/wallet/asset/${assetId}/address`);
        return res.data;
    }
    async getVault() {
        var res = await axios.get(`${API_BASE_PATH}/wallet/vault`);
        return res.data;
    }
	async getSupportedAssets() {
        var res = await axios.get(`${API_BASE_PATH}/wallet/supportedAssets`);
        return res.data;
    }
    async getTransactions() {
        var res = await axios.get(`${API_BASE_PATH}/wallet/transactions`);
        return res.data;
    }
    async transferToMtv(assetId: string, amount: number) {
        var res = await axios.post(`${API_BASE_PATH}/wallet/transferToMtv`, {AssetId: assetId, Amount: +amount});
        return res.data;
    }
    async withdraw(assetId: string, amount: number, walletId: string) {
        var res = await axios.put(`${API_BASE_PATH}/wallet/withdraw`, {AssetId: assetId, Amount: +amount, ExternalWalletId : walletId});
        return res.data;
    }
}