import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, View, SafeAreaView, ScrollView, Dimensions } from 'react-native';
import { Column as Col, Row } from 'react-native-flexbox-grid';
import { Text } from 'react-native-paper';
import tw from 'twrnc';
import { colors } from '../config/theme';
import { ThemeContext } from '../contexts/themeContext';
import { authTokenProvider } from '../services/authTokenProvider';
import ContainerWrapper from '../components/containerWrapper';
import Header from '../components/header';
import { modelStore, modelStoreNameEnum } from '../services/modelStore';
import SwitchableContentTab from '../components/switchTab';
import GitHub from '../components/gitHub';
import StackOverflow from '../components/stackoverflow';

export default function Explore() {
  const { theme } = useContext(ThemeContext);
  const activeColors = colors[theme.mode];

  const authToken = authTokenProvider.instance.authToken;
  var subOwnerName = "explore";

  var [user, setUser] = useState(null);
  useEffect(() => {
    modelStore.instance.subscribe(subOwnerName, modelStoreNameEnum.User, (usr) => {
        setUser(usr);
    });
  }, []);


  // Responsive column grid
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width);

  useEffect(() => {
    const handleWindowResize = ({ window }) => {
      setWindowWidth(window.width);
    };

    Dimensions.addEventListener('change', handleWindowResize);

    return () => {
      Dimensions.removeEventListener('change', handleWindowResize);
    };
  }, []);

  const calculateWidth = (sm, md, lg) => {
    let columns = 12; // Default column number for extra small devices

    if (windowWidth >= 320 && windowWidth < 1200) {
      columns = sm;
    } else if (windowWidth >= 1200 && windowWidth < 1230) {
      columns = md;
    } else if (windowWidth >= 1230) {
      columns = lg;
    }

    // Calculate percentage (i.e., '4 columns' out of '12 columns' is '33.33%')
    return `${(columns / 12) * 100}%`;
  };

  const notMobile = windowWidth >= 1200; // Not mobile devices

  const exploreTabs = [
    // { name: 'ALL', width: 50, content: <AllTasks screenType="Explore"/> },
    { name: 'GITHUB', width: 80, content: <GitHub screenType="Explore"/> },
    { name: 'STACKOVERFLOW', width: 150, content: <StackOverflow screenType="Explore"/> },
  ];
  
  return (
    <SafeAreaView style={{ backgroundColor: activeColors.appBackgroundColor, overflowY: 'scroll', height: '100%' }}>
      <View>
        <Header />
        <ContainerWrapper>
          <ScrollView>
            {user && (
              <View>
                 <Row size={12}>
                    <Col sm={12} md={12} lg={12}>
                      <View>
                        <Text style={[tw`mb-2 text-lg pr-2.5 pl-2.5`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold}]}>Explore</Text>
                        <SwitchableContentTab tabs={exploreTabs} />
                      </View>
                    </Col>
                  </Row>
              </View>
            )}
          </ScrollView>
        </ContainerWrapper>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  boxShadow: {
    boxShadow: '4px 3px 19px 0px rgba(189, 193, 209, 0.30)',
  },
  darkBoxshadow: {
    boxShadow: 'none',
  },
  walletOverviewValueAmountContainer: {
    width: '36.666667%',
  },
  subText: {
    fontSize: 10,
  },
  connectGitImg: {
    width: 112,
    height: 90,
  },
});