import * as React from "react";
import Svg, {
  Mask,
  Path,
  G,
  Defs,
  LinearGradient,
  Stop,
  RadialGradient,
} from "react-native-svg";
const Chrome = (props) => (
  <Svg
    width={34}
    height={34}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Mask
      id="mask0_375_275"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={34}
      height={34}
    >
      <Path
        d="M16.8889 33.7778C26.2164 33.7778 33.7778 26.2164 33.7778 16.889C33.7778 7.56147 26.2164 6.10352e-05 16.8889 6.10352e-05C7.56141 6.10352e-05 0 7.56147 0 16.889C0 26.2164 7.56141 33.7778 16.8889 33.7778Z"
        fill="white"
      />
    </Mask>
    <G mask="url(#mask0_375_275)">
      <Path
        d="M2.67969 6.10352e-05V20.7273H10.2394L16.8875 9.21218H33.7764V6.10352e-05H2.67969Z"
        fill="#DB4437"
      />
      <Path
        d="M2.67969 6.10352e-05V20.7273H10.2394L16.8875 9.21218H33.7764V6.10352e-05H2.67969Z"
        fill="url(#paint0_linear_375_275)"
      />
    </G>
    <Mask
      id="mask1_375_275"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={34}
      height={34}
    >
      <Path
        d="M16.8889 33.7778C26.2164 33.7778 33.7778 26.2164 33.7778 16.889C33.7778 7.56147 26.2164 6.10352e-05 16.8889 6.10352e-05C7.56141 6.10352e-05 0 7.56147 0 16.889C0 26.2164 7.56141 33.7778 16.8889 33.7778Z"
        fill="white"
      />
    </Mask>
    <G mask="url(#mask1_375_275)">
      <Path
        d="M10.4234 20.6602L2.77928 7.55017L2.66797 7.74209L10.2565 20.7542L10.4234 20.6602Z"
        fill="#3E2723"
        fillOpacity={0.15}
      />
    </G>
    <Mask
      id="mask2_375_275"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={34}
      height={34}
    >
      <Path
        d="M16.8889 33.7778C26.2164 33.7778 33.7778 26.2164 33.7778 16.889C33.7778 7.56147 26.2164 6.10352e-05 16.8889 6.10352e-05C7.56141 6.10352e-05 0 7.56147 0 16.889C0 26.2164 7.56141 33.7778 16.8889 33.7778Z"
        fill="white"
      />
    </Mask>
    <G mask="url(#mask2_375_275)">
      <Path
        d="M0 33.7779H16.0771L23.5389 26.316V20.7273H10.2408L0 3.1629V33.7779Z"
        fill="#0F9D58"
      />
      <Path
        d="M0 33.7779H16.0771L23.5389 26.316V20.7273H10.2408L0 3.1629V33.7779Z"
        fill="url(#paint1_linear_375_275)"
      />
    </G>
    <Mask
      id="mask3_375_275"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={34}
      height={34}
    >
      <Path
        d="M16.8889 33.7778C26.2164 33.7778 33.7778 26.2164 33.7778 16.889C33.7778 7.56147 26.2164 6.10352e-05 16.8889 6.10352e-05C7.56141 6.10352e-05 0 7.56147 0 16.889C0 26.2164 7.56141 33.7778 16.8889 33.7778Z"
        fill="white"
      />
    </Mask>
    <G mask="url(#mask3_375_275)">
      <Path
        d="M23.3825 20.9826L23.2232 20.8904L15.8555 33.7778H16.0762L23.3883 20.9883L23.3825 20.9826Z"
        fill="#263238"
        fillOpacity={0.15}
      />
    </G>
    <Mask
      id="mask4_375_275"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={34}
      height={34}
    >
      <Path
        d="M16.8889 33.7778C26.2164 33.7778 33.7778 26.2164 33.7778 16.889C33.7778 7.56147 26.2164 6.10352e-05 16.8889 6.10352e-05C7.56141 6.10352e-05 0 7.56147 0 16.889C0 26.2164 7.56141 33.7778 16.8889 33.7778Z"
        fill="white"
      />
    </Mask>
    <G mask="url(#mask4_375_275)">
      <Mask
        id="mask5_375_275"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={3}
        width={24}
        height={31}
      >
        <Path
          d="M0 33.7779H16.0771L23.5389 26.3161V20.7274H10.2408L0 3.16296V33.7779Z"
          fill="white"
        />
      </Mask>
      <G mask="url(#mask5_375_275)">
        <Path
          d="M16.888 9.21222L23.538 20.7274L16.0762 33.7779H33.7769V9.21222H16.888Z"
          fill="#FFCD40"
        />
        <Path
          d="M16.888 9.21222L23.538 20.7274L16.0762 33.7779H33.7769V9.21222H16.888Z"
          fill="url(#paint2_linear_375_275)"
        />
      </G>
    </G>
    <Mask
      id="mask6_375_275"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={34}
      height={34}
    >
      <Path
        d="M16.8889 33.7778C26.2164 33.7778 33.7778 26.2164 33.7778 16.889C33.7778 7.56147 26.2164 6.10352e-05 16.8889 6.10352e-05C7.56141 6.10352e-05 0 7.56147 0 16.889C0 26.2164 7.56141 33.7778 16.8889 33.7778Z"
        fill="white"
      />
    </Mask>
    <G mask="url(#mask6_375_275)">
      <Path
        d="M16.888 9.21216L23.538 20.7273L16.0762 33.7778H33.7769V9.21216H16.888Z"
        fill="#FFCD40"
      />
      <Path
        d="M16.888 9.21216L23.538 20.7273L16.0762 33.7778H33.7769V9.21216H16.888Z"
        fill="url(#paint3_linear_375_275)"
      />
    </G>
    <Mask
      id="mask7_375_275"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={34}
      height={34}
    >
      <Path
        d="M16.8889 33.7778C26.2164 33.7778 33.7778 26.2164 33.7778 16.889C33.7778 7.56147 26.2164 6.10352e-05 16.8889 6.10352e-05C7.56141 6.10352e-05 0 7.56147 0 16.889C0 26.2164 7.56141 33.7778 16.8889 33.7778Z"
        fill="white"
      />
    </Mask>
    <G mask="url(#mask7_375_275)">
      <Mask
        id="mask8_375_275"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={16}
        y={9}
        width={18}
        height={25}
      >
        <Path
          d="M16.8899 9.21216L23.5399 20.7273L16.0781 33.7778H33.7788V9.21216H16.8899Z"
          fill="white"
        />
      </Mask>
      <G mask="url(#mask8_375_275)">
        <Path
          d="M2.68164 6.10352e-05V20.7273H10.2413L16.8894 9.21218H33.7783V6.10352e-05H2.68164Z"
          fill="#DB4437"
        />
        <Path
          d="M2.68164 6.10352e-05V20.7273H10.2413L16.8894 9.21218H33.7783V6.10352e-05H2.68164Z"
          fill="url(#paint4_linear_375_275)"
        />
      </G>
    </G>
    <Mask
      id="mask9_375_275"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={34}
      height={34}
    >
      <Path
        d="M16.8889 33.7778C26.2164 33.7778 33.7778 26.2164 33.7778 16.889C33.7778 7.56147 26.2164 6.10352e-05 16.8889 6.10352e-05C7.56141 6.10352e-05 0 7.56147 0 16.889C0 26.2164 7.56141 33.7778 16.8889 33.7778Z"
        fill="white"
      />
    </Mask>
    <G mask="url(#mask9_375_275)">
      <Path
        d="M16.8887 9.21216V13.2329L31.9351 9.21216H16.8887Z"
        fill="url(#paint5_radial_375_275)"
      />
    </G>
    <Mask
      id="mask10_375_275"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={34}
      height={34}
    >
      <Path
        d="M16.8889 33.7778C26.2164 33.7778 33.7778 26.2164 33.7778 16.889C33.7778 7.56147 26.2164 6.10352e-05 16.8889 6.10352e-05C7.56141 6.10352e-05 0 7.56147 0 16.889C0 26.2164 7.56141 33.7778 16.8889 33.7778Z"
        fill="white"
      />
    </Mask>
    <G mask="url(#mask10_375_275)">
      <Mask
        id="mask11_375_275"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={0}
        width={32}
        height={21}
      >
        <Path
          d="M2.67773 6.10352e-05V7.74208L10.2374 20.7273L16.8855 9.21218H33.7744V6.10352e-05H2.67773Z"
          fill="white"
        />
      </Mask>
      <G mask="url(#mask11_375_275)">
        <Path
          d="M-0.00195312 33.7779H16.0751L23.5369 26.316V20.7273H10.2389L-0.00195312 3.1629V33.7779Z"
          fill="#0F9D58"
        />
        <Path
          d="M-0.00195312 33.7779H16.0751L23.5369 26.316V20.7273H10.2389L-0.00195312 3.1629V33.7779Z"
          fill="url(#paint6_linear_375_275)"
        />
      </G>
    </G>
    <Mask
      id="mask12_375_275"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={34}
      height={34}
    >
      <Path
        d="M16.8889 33.7778C26.2164 33.7778 33.7778 26.2164 33.7778 16.889C33.7778 7.56147 26.2164 6.10352e-05 16.8889 6.10352e-05C7.56141 6.10352e-05 0 7.56147 0 16.889C0 26.2164 7.56141 33.7778 16.8889 33.7778Z"
        fill="white"
      />
    </Mask>
    <G mask="url(#mask12_375_275)">
      <Path
        d="M2.67969 7.76318L13.6671 18.7486L10.2394 20.7273L2.67969 7.76318Z"
        fill="url(#paint7_radial_375_275)"
      />
    </G>
    <Mask
      id="mask13_375_275"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={34}
      height={34}
    >
      <Path
        d="M16.8889 33.7778C26.2164 33.7778 33.7778 26.2164 33.7778 16.889C33.7778 7.56147 26.2164 6.10352e-05 16.8889 6.10352e-05C7.56141 6.10352e-05 0 7.56147 0 16.889C0 26.2164 7.56141 33.7778 16.8889 33.7778Z"
        fill="white"
      />
    </Mask>
    <G mask="url(#mask13_375_275)">
      <Path
        d="M16.0879 33.7567L20.1105 18.7487L23.5382 20.7273L16.0879 33.7567Z"
        fill="url(#paint8_radial_375_275)"
      />
    </G>
    <Mask
      id="mask14_375_275"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={34}
      height={34}
    >
      <Path
        d="M16.8889 33.7778C26.2164 33.7778 33.7778 26.2164 33.7778 16.889C33.7778 7.56147 26.2164 6.10352e-05 16.8889 6.10352e-05C7.56141 6.10352e-05 0 7.56147 0 16.889C0 26.2164 7.56141 33.7778 16.8889 33.7778Z"
        fill="white"
      />
    </Mask>
    <G mask="url(#mask14_375_275)">
      <Path
        d="M16.8877 24.5657C21.1275 24.5657 24.5645 21.1287 24.5645 16.8889C24.5645 12.6492 21.1275 9.21216 16.8877 9.21216C12.6479 9.21216 9.21094 12.6492 9.21094 16.8889C9.21094 21.1287 12.6479 24.5657 16.8877 24.5657Z"
        fill="#F1F1F1"
      />
      <Path
        d="M16.8875 23.0303C20.2793 23.0303 23.0289 20.2807 23.0289 16.8889C23.0289 13.4971 20.2793 10.7475 16.8875 10.7475C13.4957 10.7475 10.7461 13.4971 10.7461 16.8889C10.7461 20.2807 13.4957 23.0303 16.8875 23.0303Z"
        fill="#4285F4"
      />
    </G>
    <Mask
      id="mask15_375_275"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={34}
      height={34}
    >
      <Path
        d="M16.8889 33.7778C26.2164 33.7778 33.7778 26.2164 33.7778 16.889C33.7778 7.56147 26.2164 6.10352e-05 16.8889 6.10352e-05C7.56141 6.10352e-05 0 7.56147 0 16.889C0 26.2164 7.56141 33.7778 16.8889 33.7778Z"
        fill="white"
      />
    </Mask>
    <G mask="url(#mask15_375_275)">
      <Path
        d="M16.8877 9.02026C12.6482 9.02026 9.21094 12.4575 9.21094 16.697V16.889C9.21094 12.6495 12.6482 9.21218 16.8877 9.21218H33.7766V9.02026H16.8877Z"
        fill="#3E2723"
        fillOpacity={0.2}
      />
      <Path
        d="M23.5293 20.7273C22.2012 23.0189 19.7274 24.5657 16.8889 24.5657C14.0485 24.5657 11.5746 23.0189 10.2466 20.7273H10.2389L0 3.1629V3.35482L10.2408 20.9193H10.2485C11.5766 23.2108 14.0504 24.7577 16.8908 24.7577C19.7293 24.7577 22.2031 23.2127 23.5312 20.9193H23.5408V20.7273H23.5293Z"
        fill="white"
        fillOpacity={0.1}
      />
      <Path
        opacity={0.1}
        d="M17.0803 9.21216C17.0477 9.21216 17.017 9.216 16.9844 9.21792C21.1797 9.26973 24.5652 12.6821 24.5652 16.8889C24.5652 21.0958 21.1797 24.5081 16.9844 24.5599C17.017 24.5599 17.0477 24.5657 17.0803 24.5657C21.3198 24.5657 24.7571 21.1284 24.7571 16.8889C24.7571 12.6494 21.3198 9.21216 17.0803 9.21216Z"
        fill="#3E2723"
      />
      <Path
        d="M23.6052 20.9826C24.2577 19.8541 24.6358 18.549 24.6358 17.1518C24.6358 16.3419 24.5091 15.5628 24.2769 14.8315C24.4592 15.4879 24.5648 16.1769 24.5648 16.8908C24.5648 18.288 24.1867 19.5931 23.5342 20.7215L23.538 20.7292L16.0762 33.7797H16.2988L23.609 20.9902L23.6052 20.9826Z"
        fill="white"
        fillOpacity={0.2}
      />
    </G>
    <Mask
      id="mask16_375_275"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={34}
      height={34}
    >
      <Path
        d="M16.8889 33.7778C26.2164 33.7778 33.7778 26.2164 33.7778 16.889C33.7778 7.56147 26.2164 6.10352e-05 16.8889 6.10352e-05C7.56141 6.10352e-05 0 7.56147 0 16.889C0 26.2164 7.56141 33.7778 16.8889 33.7778Z"
        fill="white"
      />
    </Mask>
    <G mask="url(#mask16_375_275)">
      <Path
        d="M16.8889 0.19198C26.1835 0.19198 33.724 7.70178 33.7759 16.9849C33.7759 16.9523 33.7778 16.9216 33.7778 16.889C33.7778 7.56168 26.2162 6.10352e-05 16.8889 6.10352e-05C7.56162 6.10352e-05 0 7.56168 0 16.889C0 16.9216 0.00191924 16.9523 0.00191924 16.9849C0.0537374 7.70178 7.59424 0.19198 16.8889 0.19198Z"
        fill="white"
        fillOpacity={0.2}
      />
      <Path
        d="M16.8889 33.586C26.1835 33.586 33.724 26.0762 33.7759 16.793C33.7759 16.8257 33.7778 16.8564 33.7778 16.889C33.7778 26.2163 26.2162 33.7779 16.8889 33.7779C7.56162 33.7779 0 26.2163 0 16.889C0 16.8564 0.00191924 16.8257 0.00191924 16.793C0.0537374 26.0762 7.59424 33.586 16.8889 33.586Z"
        fill="#3E2723"
        fillOpacity={0.15}
      />
    </G>
    <Path
      d="M16.8889 33.7778C26.2164 33.7778 33.7778 26.2164 33.7778 16.8889C33.7778 7.56141 26.2164 0 16.8889 0C7.56141 0 0 7.56141 0 16.8889C0 26.2164 7.56141 33.7778 16.8889 33.7778Z"
      fill="url(#paint9_radial_375_275)"
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_375_275"
        x1={4.09356}
        y1={12.8627}
        x2={14.1693}
        y2={6.97709}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#A52714" stopOpacity={0.6} />
        <Stop offset={0.66} stopColor="#A52714" stopOpacity={0} />
      </LinearGradient>
      <LinearGradient
        id="paint1_linear_375_275"
        x1={19.7427}
        y1={30.0354}
        x2={8.5477}
        y2={23.4775}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#055524" stopOpacity={0.4} />
        <Stop offset={0.33} stopColor="#055524" stopOpacity={0} />
      </LinearGradient>
      <LinearGradient
        id="paint2_linear_375_275"
        x1={21.851}
        y1={8.02309}
        x2={24.6703}
        y2={20.3685}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#EA6100" stopOpacity={0.3} />
        <Stop offset={0.66} stopColor="#EA6100" stopOpacity={0} />
      </LinearGradient>
      <LinearGradient
        id="paint3_linear_375_275"
        x1={21.851}
        y1={8.02303}
        x2={24.6703}
        y2={20.3684}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#EA6100" stopOpacity={0.3} />
        <Stop offset={0.66} stopColor="#EA6100" stopOpacity={0} />
      </LinearGradient>
      <LinearGradient
        id="paint4_linear_375_275"
        x1={4.09551}
        y1={12.8627}
        x2={14.1713}
        y2={6.97709}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#A52714" stopOpacity={0.6} />
        <Stop offset={0.66} stopColor="#A52714" stopOpacity={0} />
      </LinearGradient>
      <RadialGradient
        id="paint5_radial_375_275"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.1555 9.20218) scale(16.1362)"
      >
        <Stop stopColor="#3E2723" stopOpacity={0.2} />
        <Stop offset={1} stopColor="#3E2723" stopOpacity={0} />
      </RadialGradient>
      <LinearGradient
        id="paint6_linear_375_275"
        x1={19.7408}
        y1={30.0354}
        x2={8.54574}
        y2={23.4775}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#055524" stopOpacity={0.4} />
        <Stop offset={0.33} stopColor="#055524" stopOpacity={0} />
      </LinearGradient>
      <RadialGradient
        id="paint7_radial_375_275"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(2.66241 7.77662) scale(14.9781)"
      >
        <Stop stopColor="#3E2723" stopOpacity={0.2} />
        <Stop offset={1} stopColor="#3E2723" stopOpacity={0} />
      </RadialGradient>
      <RadialGradient
        id="paint8_radial_375_275"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.8575 16.9154) scale(16.8639 16.8639)"
      >
        <Stop stopColor="#263238" stopOpacity={0.2} />
        <Stop offset={1} stopColor="#263238" stopOpacity={0} />
      </RadialGradient>
      <RadialGradient
        id="paint9_radial_375_275"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.04479 4.60875) scale(33.9217)"
      >
        <Stop stopColor="white" stopOpacity={0.1} />
        <Stop offset={1} stopColor="white" stopOpacity={0} />
      </RadialGradient>
    </Defs>
  </Svg>
);
export default Chrome;
