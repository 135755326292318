import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
const Failed = (props) => (
  <Svg
    width={8}
    height={9}
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.257936 0.790626C-0.0835039 1.13207 -0.0835045 1.68565 0.257936 2.02709L2.72996 4.49912L0.25608 6.973C-0.08536 7.31444 -0.0853602 7.86802 0.25608 8.20946C0.59752 8.5509 1.1511 8.5509 1.49254 8.20946L3.96643 5.73558L6.44026 8.20941C6.7817 8.55085 7.33528 8.55085 7.67672 8.20941C8.01816 7.86797 8.01816 7.31439 7.67672 6.97295L5.20289 4.49912L7.67487 2.02714C8.01631 1.6857 8.01631 1.13212 7.67487 0.790677C7.33343 0.449237 6.77984 0.449236 6.4384 0.790677L3.96643 3.26265L1.4944 0.790626C1.15296 0.449186 0.599377 0.449186 0.257936 0.790626Z"
      fill="#E06365"
    />
  </Svg>
);
export default Failed;
