import React, { useContext, useEffect, useState } from 'react';
import { webhookService, webhookTypeEnum } from '../services/webhookService';
import { useAccount } from 'wagmi'
import { authTokenProvider } from '../services/authTokenProvider';
import { walletApiService } from '../services/walletApiService';
import { colors } from '../config/theme';
import { ThemeContext } from '../contexts/themeContext';
import { StyleSheet, View, Dimensions, FlatList, TouchableOpacity, Linking, TouchableHighlight } from 'react-native';
import { Card, Text } from 'react-native-paper';
import { Column as Col, Row } from 'react-native-flexbox-grid';
import { Provider as PaperProvider } from 'react-native-paper';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import {useFonts, Inter_500Medium, Inter_600SemiBold, } from '@expo-google-fonts/inter';
import { modelStore, modelStoreNameEnum } from '../services/modelStore';
import tw from 'twrnc';
import moment from 'moment';
import 'moment-timezone';
import Button from './button';
import Failed from '../assets/images/failed';
import Successful from '../assets/images/successful';
import CustomPicker from './customPicker';
import Clipboard from '@react-native-clipboard/clipboard';
import NoTransactionLight from '../assets/images/noTransactionLight';
import NoTransactionDark from '../assets/images/noTransactionDark';
import GitTrxHashLightMode from '../assets/images/gitTrxHashLightMode';
import GitTrxHashDarkMode from '../assets/images/gitTrxHashDarkMode';
import CustomTooltip from './customToolTip';
import Hr from './hr';
import { useIsFocused } from "@react-navigation/native";

export default function Transactions({ screenType, toolTips }) {

  let [fontsLoaded] = useFonts({
    Inter_500Medium,
    Inter_600SemiBold,
  });

  const route = useRoute();

  // Check if the current screen is the wallet screen
  const [isWalletScreen, setIsWalletScreen] = useState(false);

  // Update isExploreScreen based on the current route
  useEffect(() => {
    const currentRouteName = route.name; // Access the current route name from the route object
    setIsWalletScreen(currentRouteName === 'My Wallet');
  }, [route]);

  // Convert timestamp to local time format
  const formatTransactionTime = (time) => {
  if (!time) {
    return '';
  }

  const transactionTime = moment(time);

  if (!transactionTime.isValid()) {
    return '';
  }

  const now = moment();

  if (transactionTime.isSame(now, 'day')) {
    // Transaction occurred today
    return 'Today ' + transactionTime.format('h:mmA');
    } else if (transactionTime.isSame(now.clone().subtract(1, 'day'), 'day')) {
      // Transaction occurred yesterday
      return transactionTime.format('DD MMM YYYY h:mmA');
    } else {
      // Transaction occurred on a different day
      return transactionTime.format('DD MMM YYYY h:mmA');
    }
  };

  var subOwnerName = "transaction";
  var [user, setUser] = useState(null);
  const [vault, setVault] = useState([]);
  const [listingResults, setListingResults] = useState([]);
  const [supportedAssets, setSupportedAssets] = useState([]);
  useEffect(() => {
    modelStore.instance.subscribe(subOwnerName, modelStoreNameEnum.Vault, (vault) => {
      setVault(vault);
    });

    modelStore.instance.subscribe(subOwnerName, modelStoreNameEnum.SupportedAssets, (sa) => {
      setSupportedAssets(sa);
    });

    modelStore.instance.subscribe(subOwnerName, modelStoreNameEnum.User, (usr) => {
      setUser(usr);
    });

    modelStore.instance.subscribe(subOwnerName, modelStoreNameEnum.Listings, (listings) => {
        setListingResults(listings);
    });
  }, []);

  const { theme } = useContext(ThemeContext);
  let activeColors = colors[theme.mode];

  // Responsive column grid
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width);

// Get USDT value of an asset
  const getUsdPrice = (asset) => {
    if (!listingResults) return;
    var sa = supportedAssets.find(q => q.coinSymbol == asset.coinSymbol);
    if (!sa) return;
    var sn = sa.supportedNetworks.find(q => q.id == asset.assetId);
    if (!sn) return;
    // start with address
    var fromAddress = listingResults.find(q => q.platform && q.platform.token_address == sn.contractAddress)
    if (fromAddress) return +fromAddress.quote.USD.price;

    var f = listingResults.find(q => q.symbol.toLowerCase() == asset.coinSymbol.toLowerCase());
    if (!f) return null;
    return +f.quote.USD.price;
  }

  useEffect(() => {
    const handleWindowResize = ({ window }) => {
      setWindowWidth(window.width);
    };

    Dimensions.addEventListener('change', handleWindowResize);

    return () => {
      Dimensions.removeEventListener('change', handleWindowResize);
    };
  }, []);

  const calculateWidth = (sm, md, lg) => {
    let columns = 12; // Default column number for extra small devices

    if (windowWidth >= 320 && windowWidth < 1200) {
      columns = sm;
    } else if (windowWidth >= 1200 && windowWidth < 1230) {
      columns = md;
    } else if (windowWidth >= 1230) {
      columns = lg;
    }

    // Calculate percentage (i.e., '4 columns' out of '12 columns' is '33.33%')
    return `${(columns / 12) * 100}%`;
  };

  const notMobile = windowWidth >= 1200; // Not mobile devices

  const { address } = useAccount()
  var authToken = authTokenProvider.instance.authToken;

  // Get transactions
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [transactions, setTransactions] = useState([]);

  const getTransactions = async () => {
    try {
        const fetchedTransactions = await walletApiService.instance.getTransactions();
        setTransactions(fetchedTransactions);
      } catch (error) {
        console.error("Failed to fetch transactions:", error);
        setTransactions([]); // Set transactions to an empty array in case of an error
      }
  };

  useEffect(() => {
    webhookService.instance.subscribe(subOwnerName, webhookTypeEnum.TransactionUpdate, (msg) => {
      getTransactions();
    })
  }, [address, authToken]);

  const isFocused = useIsFocused();
  useEffect(() => {
    if (address && authToken && isFocused) {
      getTransactions(); // Call the getTransactions function initially
    }
  }, [address, authToken, isFocused]);

  // Add state to store and sort filter
  const [activeFilter, setActiveFilter] = useState(''); // Default: 'Filter by'
  const [selectedSort, setSelectedSort] = useState('Most Recent'); // Default: 'Sort by'

  // Pagination variables
  const transactionsPerPage = screenType === 'Dashboard' ? 4 : 10;
  const paginationRange = screenType === 'Dashboard' ? 5 : 10;

  const [currentPage, setCurrentPage] = useState(1);
  const [startPageIndex, setStartPageIndex] = useState(1);
  const [endPageIndex, setEndPageIndex] = useState(1);

  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [sortedTransactions, setSortedTransactions] = useState([]);

  useEffect(() => {
    setFilteredAndSortedTransactions();
  }, [transactions, activeFilter, selectedSort]);

  useEffect(() => {
    setPagination();
  }, [sortedTransactions]);

  useEffect(() => {
    // Reset pagination whenever the sorting option changes
    setCurrentPage(1);
    setStartPageIndex(1);
    setEndPageIndex(Math.min(paginationRange, Math.ceil(sortedTransactions.length / transactionsPerPage)));
  }, [selectedSort]);

  const setFilteredAndSortedTransactions = () => {
    // Filter transactions based on selected filter
    let filtered = transactions;
    if (activeFilter === 'Deposit') {
      filtered = transactions.filter(t => t.transactionType === 0);
    } else if (activeFilter === 'Withdraw') {
      filtered = transactions.filter(t => t.transactionType === 1);
    } else if (activeFilter === 'Fund') {
      filtered = transactions.filter(t => t.transactionType === 2);
    } else if (activeFilter === 'Claim') {
      filtered = transactions.filter(t => t.transactionType === 3);
    } else if (activeFilter === 'Refund') {
      filtered = transactions.filter(t => t.transactionType === 4);
    } else if (selectedAsset) {
      filtered = transactions.filter(t => t.assetId === selectedAsset);
    }

    // Sort transactions based on selected sort
    let sorted = [...filtered];
    if (selectedSort === 'Most Recent') {
      sorted.sort((a, b) => b.created - a.created);
    } else if (selectedSort === 'Highest') {
      sorted.sort((a, b) => b.amount - a.amount);
    } else if (selectedSort === 'Lowest') {
      sorted.sort((a, b) => a.amount - b.amount);
    }

    setFilteredTransactions(filtered);
    setSortedTransactions(sorted);
  };

  const setPagination = () => {
    let newStartPageIndex = 1;
    let newEndPageIndex = Math.min(paginationRange, Math.ceil(sortedTransactions.length / transactionsPerPage));

    setStartPageIndex(newStartPageIndex);
    setEndPageIndex(newEndPageIndex);
  };

  const handleSortingChange = (criteria) => {
    setSelectedSort(criteria);
    setCurrentPage(1); // Reset the current page when sorting criteria changes
    setStartPageIndex(1); // Reset the startPageIndex to 1 when sorting criteria changes
  };

  const handleFilteringChange = (criteria) => {
    setActiveFilter(criteria);
    setCurrentPage(1); // Reset the current page when filtering criteria changes
    setStartPageIndex(1); // Reset the startPageIndex to 1 when filtering criteria changes
  };

  // Get paginated transactions based on current page and applied filters
  const getPaginatedTransactions = () => {
    if (!Array.isArray(sortedTransactions) || sortedTransactions.length === 0) {
      return []; // Return an empty array if transactions is empty or not an array
    }

    const startIndex = (currentPage - 1) * transactionsPerPage;
    const endIndex = startIndex + transactionsPerPage;

    // Filter transactions based on transaction type
    const paginatedTransactions = sortedTransactions
      .filter(t => [0, 1, 2, 3, 4].includes(t.transactionType))
      .slice(startIndex, endIndex);

    return paginatedTransactions;
  };

  const paginatedTransactions = getPaginatedTransactions();
  const hasFilteredTransactions = paginatedTransactions.length > 0; // Track whether there are transactions after filtering

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);

    if (pageNumber >= startPageIndex + paginationRange) {
      const newStartPageIndex = pageNumber - paginationRange + 1;
      const newEndPageIndex = Math.min(newStartPageIndex + paginationRange - 1, Math.ceil(sortedTransactions.length / transactionsPerPage));
      setStartPageIndex(newStartPageIndex);
      setEndPageIndex(newEndPageIndex);
    } else if (pageNumber < startPageIndex) {
      const newEndPageIndex = pageNumber;
      const newStartPageIndex = Math.max(1, newEndPageIndex - paginationRange + 1);
      setStartPageIndex(newStartPageIndex);
      setEndPageIndex(newEndPageIndex);
    }
  };

  // Render pagination buttons
  const renderPaginationButtons = () => {
    const buttons = [];
    const maxButtons = Math.ceil(sortedTransactions.length / transactionsPerPage);

    for (let i = startPageIndex; i <= endPageIndex; i++) {
      const startIndex = (i - 1) * transactionsPerPage;
      const endIndex = startIndex + transactionsPerPage;
      const pageTransactions = sortedTransactions
        .filter(t => [0, 1, 2, 3, 4].includes(t.transactionType)) // Filter transactions before slicing them
        .slice(startIndex, endIndex);

      // Only push the button into buttons array if pageTransactions.length !== 0
      if (pageTransactions.length !== 0) {
        buttons.push(
          <TouchableOpacity
            key={i}
            onPress={() => handlePageClick(i)}
            style={[
              {
                width: 28,
                height: 28,
                borderRadius: '50%',
                marginLeft: 3,
                marginRight: 3,
                backgroundColor: 'transparent',
                justifyContent: 'center', 
                alignItems: 'center', 
              },
              currentPage === i && { backgroundColor: activeColors.paginationActiveButtonColor }
            ]}
          >
            <Text
              style={[
                { fontSize: 12, color: activeColors.paginationButtonTextColor, fontFamily: activeColors.interSemiBold },
                currentPage === i && { color: activeColors.paginationActiveButtonTextColor }
              ]}
            >
              {i}
            </Text>
          </TouchableOpacity>
        );
      }
    }

    return buttons;
  };

  // Add icons for back and next pagination buttons
  const BackIcon = <Ionicons name={"chevron-back-outline"} size={24} style={{ color: activeColors.paginationNextBackBtnColor }} />;
  const NextIcon = <Ionicons name={"chevron-forward-outline"} size={24} style={{ color: activeColors.paginationNextBackBtnColor }} />;

  // To copy transaction hash
  const [transactionHash, setTransactionHash] = useState('');
  const [copied, setCopied] = useState(false);

  const handleCopyText = (hash) => {
    Clipboard.setString(hash);
    setTransactionHash(hash); // Set the transaction hash to copy
    setCopied(true);

    // Reset the copied state after 2 seconds
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handleLinkPress = (task) => {
    const url = task.fuelTask.url.replace("https://api.github.com/repos/", "https://github.com/");
    Linking.openURL(url);
  };

  // Tooltips
  const [activeTooltipId, setActiveTooltipId] = useState(null);

  return (
    <PaperProvider>
       
      {isWalletScreen && transactions && transactions.length > 0 && (
        <View style={[tw`mt-2 mb-3 pr-1.5 pl-1.5`, { zIndex: 9000, elevation: 5 }]}>
            <Row size={12}>
              <Col style={{ width: calculateWidth(12, 12, 12) }}>
                <View style={tw`flex-row mb-5 justify-end items-center`}>
                  <View style={{ flexDirection: 'row', alignItems: 'flex-start', zIndex: 9000, elevation: 5}}>
                    <View style={tw`relative top-3.5 mr-2`}>
                    <Text style={[tw`text-xs`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interMedium }]}>
                      Filter by:
                    </Text>
                  </View>
                    <CustomPicker
                      items={[
                        { label: 'None', value: '' },
                        { label: 'Claim', value: 'Claim' },
                        { label: 'Deposit', value: 'Deposit' },
                        { label: 'Fund', value: 'Fund' },
                        { label: 'Refund', value: 'Refund' },
                        { label: 'Withdraw', value: 'Withdraw' },
                      ]}
                      activeColors={activeColors}
                      selectedValue={activeFilter}
                      onValueChange={handleFilteringChange}
                      containerStyle={tw`rounded-lg h-11 w-40 text-xs mr-4`}
                      dropdownStyle={{ 
                        color: activeColors.paginationButtonTextColor,
                        borderColor: activeColors.paginationButtonColor,
                        borderWidth: 0,
                        borderRadius: 8,
                        backgroundColor: activeColors.selectionBgColor,
                        width: 160
                      }}
                    />     
                    <View style={tw`relative top-3.5 mr-2`}>
                    <Text style={[tw`text-xs`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interMedium }]}>
                      Sort by:
                    </Text>
                  </View>
                    <CustomPicker
                      items={[
                        { label: 'Most Recent', value: 'Most Recent' },
                        { label: 'Lowest Amount', value: 'Lowest' },
                        { label: 'Highest Amount', value: 'Highest' },
                      ]}
                      activeColors={activeColors}
                      selectedValue={selectedSort}
                      onValueChange={handleSortingChange}
                      containerStyle={tw`rounded-lg h-11 w-40 text-xs`}
                      dropdownStyle={{ 
                        color: activeColors.paginationButtonTextColor,
                        borderColor: activeColors.paginationButtonColor,
                        borderWidth: 0,
                        borderRadius: 8,
                        backgroundColor: activeColors.selectionBgColor,
                        width: 160
                      }}
                    />
                  </View>
                </View>
              </Col>
            </Row>
        </View>
      )}

      {transactions && transactions.length > 0 && (
        <Card elevation={0} style={[tw`mb-0 pt-0.5 pr-2.5 pl-2.5 pb-2`]}>
          <Card.Content style={[tw`rounded-xl`, { backgroundColor: activeColors.cardBgColor }, theme.mode === 'dark' ? styles.darkBoxshadow : styles.boxShadow]}>
            <View style={{ zIndex: 8000, elevation: 4}}>
              <Row size={12}>
                <Col style={[tw`pr-3`, { width: calculateWidth(4, 3, 3) }]}>
                  <Text style={[tw`relative text-xs`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interMedium }]}>Type</Text>
                </Col>
                <Col style={[notMobile ? tw`block` : tw`hidden`, tw`pr-16`, { width: calculateWidth(4, 4, 4) }]}>
                  <Text style={[tw`relative text-xs ml-1.5`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interMedium }]}>Txn Hash/Issue</Text>
                </Col>
                <Col style={[tw`pr-3`, { width: calculateWidth(4, 3, 3) }]}>
                  <Text style={[tw`relative text-xs`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interMedium }]}>Amount</Text>
                </Col>
                <Col style={[tw`pr-3`, { width: calculateWidth(4, 2, 2) }]}>
                  <Text style={[tw`relative text-xs`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interMedium }]}>Status</Text>
                </Col>
              </Row>
            </View>
          </Card.Content>
        </Card>
      )}

      {user && (
        <>
          {transactions && transactions.length > 0 && (
            <>
              <FlatList
                style={{minHeight: 300, overflow: 'hidden'}}
                data={getPaginatedTransactions()}
                keyExtractor={(item, index) => index.toString()}
                renderItem={({ item: t }) => (
                  <Card elevation={0} style={[tw`mb-0 pt-0.5 pr-2.5 pl-2.5 pb-2`]}>
                    <Card.Content style={[tw`rounded-xl`, { backgroundColor: activeColors.cardBgColor, zIndex: 5000, elevation: 1 }, theme.mode === 'dark' ? styles.darkBoxshadow : styles.boxShadow]}>
                      <Row size={12}>
                        <Col style={[tw`pr-3 pb-2`, { width: calculateWidth(4, 3, 3) }]}>
                          <View style={tw`flex-1 w-full`}>
                            {t.transactionType === 0 && <Text style={[notMobile ? tw`text-base` : tw`text-sm`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold }]}>Deposit</Text>}
                            {t.transactionType === 1 && <Text style={[notMobile ? tw`text-base` : tw`text-sm`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold }]}>Withdraw</Text>}
                            {t.transactionType === 2 && <Text style={[notMobile ? tw`text-base` : tw`text-sm`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold }]}>Fund</Text>}
                            {t.transactionType === 4 && <Text style={[notMobile ? tw`text-base` : tw`text-sm`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold }]}>Refund</Text>}
                            {t.transactionType === 3 && <Text style={[notMobile ? tw`text-base` : tw`text-sm`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold }]}>Claim</Text>}
                            <Text style={[notMobile ? tw`text-sm` : tw`text-xs`,{ color: activeColors.secondaryTextColor, fontFamily: activeColors.interMedium }]}>
                              {t.created && formatTransactionTime(t.created)}
                            </Text>
                          </View>
                        </Col>
                        <Col style={[notMobile ? tw`block` : tw`hidden`, tw`pr-16 pb-2`, { width: calculateWidth(4, 4, 4) }]}>
                          <View style={[tw`pl-2 w-full`, { flexDirection: 'row', alignItems: 'center' }]}>
                            {t.hash && (
                              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Text style={[notMobile ? tw`text-base` : tw`text-sm`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold }]}>
                                  {t.hash && t.hash.length < 20 ? `${t.hash}` : t.hash && `${t.hash.substring(0, 13)}...`}
                                </Text>
                                <View style={[tw`justify-end ml-2`, { flexDirection: 'row', alignItems: 'center' }]}>  
                                  <TouchableOpacity onPress={() => handleCopyText(t.hash)}>
                                    <Ionicons name="copy-outline" size={20} color={activeColors.primaryTextColor} />
                                  </TouchableOpacity>
                                  {copied && transactionHash === t.hash && <Text style={{ color: activeColors.primaryTextColor, marginLeft: 5 }}>Copied!</Text>}
                                </View>
                              </View>
                            )}

                            {!t.hash && (
                              <View style={tw`flex-1`}>
                               <TouchableHighlight onPress={() => handleLinkPress(t)} underlayColor="transparent">
                                <View>
                                  {
                                    t.fuelTask && <Text style={[notMobile ? tw`text-base` : tw`text-sm`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold }]}>
                                    {theme.mode === 'dark' ? <GitTrxHashDarkMode style={tw`relative top-1 w-5 h-5 mr-2`} /> : <GitTrxHashLightMode  style={tw`relative top-1 w-5 h-5 mr-2`} />}{t.fuelTask.id.split('_')[2]}
                                    </Text> 
                                  }
                                </View>
                              </TouchableHighlight>
                            </View>
                            )}
                            
                          </View>
                        </Col>
                        <Col style={[tw`pr-3 pb-2`, { width: calculateWidth(4, 3, 3) }]}>
                          <View style={tw`flex-1`}>
                            <Text style={[notMobile ? tw`text-base` : tw`text-sm`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold }]}>
                              {t.coinSymbol} {t.amount && t.amount.toString().substring(0, 8)} 
                            </Text>
                            <Text style={[notMobile ? tw`text-sm` : tw`text-xs`, { color: activeColors.secondaryTextColor, fontFamily: activeColors.interMedium }]}>
                              {getUsdPrice({ coinSymbol: t.coinSymbol, assetId: t.assetId}) ? '$' + (getUsdPrice({ coinSymbol: t.coinSymbol, assetId: t.assetId}) * t.amount).toFixed(2) : '$0.00'}
                            </Text>
                            {t.transactionType === 1 && 
                              <>
                                <View styles={tw`w-full`}>
                                  <Hr thickness={0.5} style={[tw`mt-1 mb-1 w-1/4`, { backgroundColor: activeColors.hrColor }]} />
                                </View>
                                <View styles={tw`w-full`}>
                                    <View style={{ flex: 1, zIndex: 9000, elevation: 4 }}>
                                      <CustomTooltip
                                        id={`${`fees_${t.id}`}`}
                                        tooltipTop={24}
                                        text={`- ${t.fee.toFixed(7).toString().replace(/(\.[0-9]*[1-9])0+$/, '$1')} ${t.assetId} Platform fees`}
                                        toolTipRightAlign={notMobile ? toolTips : 15}
                                        toolTipTopArrowAlign={notMobile ? 188 : 90}
                                        tooltipBgColor={activeColors.toolTipBgColor}
                                        tooltipColor={activeColors.primaryTextColor}
                                        tooltipFont={activeColors.interMedium}
                                        tooltipFontSize={notMobile ? 11 : 9}
                                        tooltipWidth={notMobile ? 210 : 185}
                                        isActive={activeTooltipId === `fees_${t.id}`}
                                        setActiveTooltipId={(id) => setActiveTooltipId(id)}
                                      >
                                      <Text style={[notMobile ? tw`text-xs` : {fontSize: 10}, { color: activeColors.secondaryTextColor, fontFamily: activeColors.interMedium }]}>
                                        - {t.fee.toFixed(7).toString().replace(/(\.[0-9]*[1-9])0+$/, '$1')} {t.assetId} </Text>
                                      </CustomTooltip>
                                    </View>
                                    {t.gasFee && (
                                      <View style={{ flex: 1, zIndex: 8000, elevation: 3 }}>
                                        <CustomTooltip
                                          id={`${`plaformfees_${t.id}`}`}
                                          tooltipTop={24}
                                          text={`${t.gasFee.amount ? '-' : ''} ${t.gasFee.amount ? `${t.gasFee.amount}` : ''} ${t.gasFee.amount.toFixed(12).replace(/\.?0+$/, '') ?  'Transfer fee' : ''} ${t.gasFee.assetId}`}
                                          toolTipRightAlign={notMobile ? toolTips : 15}
                                          toolTipTopArrowAlign={notMobile ? 153 : 90}
                                          tooltipBgColor={activeColors.toolTipBgColor}
                                          tooltipColor={activeColors.primaryTextColor}
                                          tooltipFont={activeColors.interMedium}
                                          tooltipFontSize={notMobile ? 11 : 9}
                                          tooltipWidth={notMobile ? 160 : 180}
                                          isActive={activeTooltipId === `plaformfees_${t.id}`}
                                          setActiveTooltipId={(id) => setActiveTooltipId(id)}
                                        >
                                        <Text style={[notMobile ? tw`text-xs` : {fontSize: 10}, { color: activeColors.secondaryTextColor, fontFamily: activeColors.interMedium }]}>
                                          {t.gasFee.amount ? '-': ''} {t.gasFee.amount ? `${t.gasFee.amount.toFixed(7).toString().replace(/(\.[0-9]*[1-9])0+$/, '$1')}` : ''} {t.gasFee.assetId}
                                          </Text>
                                        </CustomTooltip>
                                      </View>
                                    )} 
                                </View>
                              </>
                            }
                            {t.transactionType === 2 && 
                              <>
                                <View styles={tw`w-full`}>
                                  <Hr thickness={0.5} style={[tw`mt-1 mb-1 w-1/4`, { backgroundColor: activeColors.hrColor }]} />
                                </View>
                                <View styles={tw`w-full`}>
                                    <View style={{ flex: 1, zIndex: 9000, elevation: 4 }}>
                                      <CustomTooltip
                                        id={`${`fees_${t.id}`}`}
                                        tooltipTop={24}
                                        text={`- ${t.fee.toFixed(7).toString().replace(/(\.[0-9]*[1-9])0+$/, '$1')} ${t.assetId} Platform fees`}
                                        toolTipRightAlign={notMobile ? toolTips : 15}
                                        toolTipTopArrowAlign={notMobile ? 188 : 90}
                                        tooltipBgColor={activeColors.toolTipBgColor}
                                        tooltipColor={activeColors.primaryTextColor}
                                        tooltipFont={activeColors.interMedium}
                                        tooltipFontSize={notMobile ? 11 : 9}
                                        tooltipWidth={notMobile ? 210 : 185}
                                        isActive={activeTooltipId === `fees_${t.id}`}
                                        setActiveTooltipId={(id) => setActiveTooltipId(id)}
                                      >
                                      <Text style={[notMobile ? tw`text-xs` : {fontSize: 10}, { color: activeColors.secondaryTextColor, fontFamily: activeColors.interMedium }]}>
                                        - {t.fee.toFixed(7).toString().replace(/(\.[0-9]*[1-9])0+$/, '$1')} {t.assetId} </Text>
                                      </CustomTooltip>
                                    </View>
                                    {t.gasFee && (
                                      <View style={{ flex: 1, zIndex: 8000, elevation: 3 }}>
                                        <CustomTooltip
                                          id={`${`plaformfees_${t.id}`}`}
                                          tooltipTop={24}
                                          text={`${t.gasFee.amount ? '-' : ''} ${t.gasFee.amount ? `${t.gasFee.amount}` : ''} ${t.gasFee.amount.toFixed(12).replace(/\.?0+$/, '') ?  'Transfer fee' : ''} ${t.gasFee.assetId}`}
                                          toolTipRightAlign={notMobile ? toolTips : 15}
                                          toolTipTopArrowAlign={notMobile ? 153 : 90}
                                          tooltipBgColor={activeColors.toolTipBgColor}
                                          tooltipColor={activeColors.primaryTextColor}
                                          tooltipFont={activeColors.interMedium}
                                          tooltipFontSize={notMobile ? 11 : 9}
                                          tooltipWidth={notMobile ? 160 : 180}
                                          isActive={activeTooltipId === `plaformfees_${t.id}`}
                                          setActiveTooltipId={(id) => setActiveTooltipId(id)}
                                        >
                                        <Text style={[notMobile ? tw`text-xs` : {fontSize: 10}, { color: activeColors.secondaryTextColor, fontFamily: activeColors.interMedium }]}>
                                          {t.gasFee.amount ? '-': ''} {t.gasFee.amount ? `${t.gasFee.amount.toFixed(7).toString().replace(/(\.[0-9]*[1-9])0+$/, '$1')}` : ''} {t.gasFee.assetId}
                                          </Text>
                                        </CustomTooltip>
                                      </View>
                                    )} 
                                </View>
                              </>
                            }
                            {t.transactionType === 3 && 
                              <>
                                <View styles={tw`w-full`}>
                                  <Hr thickness={0.5} style={[tw`mt-1 mb-1 w-1/4`, { backgroundColor: activeColors.hrColor }]} />
                                </View>
                                <View styles={tw`w-full`}>
                                    <View style={{ flex: 1, zIndex: 9000, elevation: 4 }}>
                                      <CustomTooltip
                                        id={`${`fees_${t.id}`}`}
                                        tooltipTop={24}
                                        text={`- ${t.fee.toFixed(7).toString().replace(/(\.[0-9]*[1-9])0+$/, '$1')} ${t.assetId} Platform fees`}
                                        toolTipRightAlign={notMobile ? toolTips : 15}
                                        toolTipTopArrowAlign={notMobile ? 188 : 90}
                                        tooltipBgColor={activeColors.toolTipBgColor}
                                        tooltipColor={activeColors.primaryTextColor}
                                        tooltipFont={activeColors.interMedium}
                                        tooltipFontSize={notMobile ? 11 : 9}
                                        tooltipWidth={notMobile ? 210 : 185}
                                        isActive={activeTooltipId === `fees_${t.id}`}
                                        setActiveTooltipId={(id) => setActiveTooltipId(id)}
                                      >
                                      <Text style={[notMobile ? tw`text-xs` : {fontSize: 10}, { color: activeColors.secondaryTextColor, fontFamily: activeColors.interMedium }]}>
                                        - {t.fee.toFixed(7).toString().replace(/(\.[0-9]*[1-9])0+$/, '$1')} {t.assetId} </Text>
                                      </CustomTooltip>
                                    </View>
                                    {t.gasFee && (
                                      <View style={{ flex: 1, zIndex: 8000, elevation: 3 }}>
                                        <CustomTooltip
                                          id={`${`plaformfees_${t.id}`}`}
                                          tooltipTop={24}
                                          text={`${t.gasFee.amount ? '-' : ''} ${t.gasFee.amount ? `${t.gasFee.amount}` : ''} ${t.gasFee.amount.toFixed(12).replace(/\.?0+$/, '') ?  'Transfer fee' : ''} ${t.gasFee.assetId}`}
                                          toolTipRightAlign={notMobile ? toolTips : 15}
                                          toolTipTopArrowAlign={notMobile ? 153 : 90}
                                          tooltipBgColor={activeColors.toolTipBgColor}
                                          tooltipColor={activeColors.primaryTextColor}
                                          tooltipFont={activeColors.interMedium}
                                          tooltipFontSize={notMobile ? 11 : 9}
                                          tooltipWidth={notMobile ? 160 : 180}
                                          isActive={activeTooltipId === `plaformfees_${t.id}`}
                                          setActiveTooltipId={(id) => setActiveTooltipId(id)}
                                        >
                                        <Text style={[notMobile ? tw`text-xs` : {fontSize: 10}, { color: activeColors.secondaryTextColor, fontFamily: activeColors.interMedium }]}>
                                          {t.gasFee.amount ? '-': ''} {t.gasFee.amount ? `${t.gasFee.amount.toFixed(7).toString().replace(/(\.[0-9]*[1-9])0+$/, '$1')}` : ''} {t.gasFee.assetId}
                                          </Text>
                                        </CustomTooltip>
                                      </View>
                                    )} 
                                </View>
                              </>
                            }
                            {t.transactionType === 4 && 
                            <>
                              <View styles={tw`w-full`}>
                                <Hr thickness={0.5} style={[tw`mt-1 mb-1.5 w-1/4`, { backgroundColor: activeColors.hrColor }]} />
                              </View>
                               <View styles={tw`w-full`}>
                                <Text style={[notMobile ? tw`text-xs` : {fontSize: 10}, { color: activeColors.secondaryTextColor, fontFamily: activeColors.interMedium }]}>
                                  Fees Returned
                                </Text>
                              </View>
                              </>
                            }
                          </View>
                        </Col>
                        <Col style={[tw`pr-3 pb-2`, { width: calculateWidth(4, 2, 2) }]}>
                          <View style={tw`flex-row items-center mr-2 w-full pr-3`}>
                            <View style={tw`relative top-0.5 h-3 w-3`}>
                              {t.isSuccess && <Successful/>}
                              {t.isFailed && <Failed/>}
                              {t.isPending && <Ionicons name="ellipsis-horizontal" size={18} style={{ color: activeColors.secondaryTextColor }} />}
                            </View>
                            <View>
                              {t.isSuccess && <Text style={[notMobile ? tw`text-base ml-1` : tw`text-sm ml-1`,{ color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold  }]}> Successful </Text> }
                              {t.isFailed && <Text style={[notMobile ? tw`text-base ml-1` : tw`text-sm ml-1`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold  }]}> Failed </Text>}
                              {t.isPending && <Text style={[notMobile ? tw`text-base ml-1` : tw`text-sm ml-1`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold  }]}> Pending </Text>}
                            </View>
                          </View>
                        </Col>
                      </Row>
                    </Card.Content>
                  </Card>
                )}
              />
              {isWalletScreen && (
                <View>
                  {transactions.length > transactionsPerPage && hasFilteredTransactions && (
                    <Row size={12} style={tw`mt-6.5 mb-5 justify-center`}>
                      <Col style={{ width: calculateWidth(6, 6, 6) }}>
                        <Card elevation={0} style={[tw`mb-0 pt-0.5 pr-2.5 pl-2.5 pb-2`]}>
                          <Card.Content style={[tw`rounded-xl`, { backgroundColor: activeColors.cardBgColor, borderRadius: 50 }, theme.mode === 'dark' ? styles.darkBoxshadow : styles.boxShadow]}>
                            <View style={tw`flex-row justify-center`}>
                              {currentPage > 1 && (
                                <Button
                                  title={BackIcon}
                                  onClick={() => handlePageClick(currentPage - 1)}
                                  buttonStyle={[tw`relative, mx-1 mb-0 rounded-full bg-transparent`, { top: 2, width: 28, height: 28, }]}
                                />
                              )}

                              {renderPaginationButtons().map((button) => button)}

                              {sortedTransactions
                                .filter(t => [0, 1, 2, 3].includes(t.transactionType))
                                .slice(currentPage * transactionsPerPage, (currentPage + 1) * transactionsPerPage).length > 0 && (
                                  <Button
                                    title={NextIcon}
                                    onClick={() => handlePageClick(currentPage + 1)}
                                    buttonStyle={[tw`relative, mx-1 mb-0 rounded-full bg-transparent`, { top: 2, width: 28, height: 28, }]}
                                  />
                              )}
                            </View>
                          </Card.Content>
                        </Card>
                      </Col>
                    </Row>
                  )}
                </View>
              )}
            </>
          )}

          {transactions && transactions.length === 0 && (
            <Card elevation={0} style={[tw`mb-0 pt-0.5 pr-2.5 pl-2.5 pb-2`]}>
              <Card.Content style={[tw`rounded-xl h-106`, { backgroundColor: activeColors.cardBgColor }, theme.mode === 'dark' ? styles.darkBoxshadow : styles.boxShadow]}>
                <View style={{ zIndex: 8000, elevation: 4, flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <View style={tw`w-24 h-24`}>
                    { theme.mode === 'dark' ? <NoTransactionDark /> : <NoTransactionLight /> }
                  </View>
                  <Text style={[tw`mt-5 text-base`, { color: activeColors.secondaryTextColor, fontFamily: activeColors.interMedium }]}>
                    No transaction found.
                  </Text>
                </View>
              </Card.Content>
            </Card>
          )}
        </>
      )}
    </PaperProvider>
  );
}

const styles = StyleSheet.create({
  boxShadow: {
    boxShadow: '0px 2px 14px 0px rgba(189, 193, 209, 0.30)',
  },
  darkBoxshadow: {
    boxShadow: 'none',
  },
  subText: {
    fontSize: 10,
  },
  sortText: {
    fontSize: 12,
  }
});