import React from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';

const ContainerWrapper = ({ children, height }) => {
  const { width } = Dimensions.get('window');
  let containerWidth;

  if (width < 576) {
    containerWidth = '100%';
  } else if (width < 768) {
    containerWidth = 500;
  } else if (width < 992) {
    containerWidth = 580;
  } else if (width < 1020) {
    containerWidth = 750;
  } else if (width < 1120) {
    containerWidth = 800;
  } else if (width < 1200) {
    containerWidth = 850;
  } else if (width < 1400) {
    containerWidth = 1000;
  }  else if (width < 1520) {
    containerWidth = 1200;
  } else {
    containerWidth = 1320;
  }

  return (
    <View style={[styles.container, { width: containerWidth, height: height }]}>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignSelf: 'center',
    paddingLeft: 15,
    paddingRight: 15,
  },
});

export default ContainerWrapper;