import React, { useContext } from 'react';
import AppLoading from 'expo-app-loading';
import {
  useFonts,
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
} from '@expo-google-fonts/inter';
import { View, TouchableOpacity, Text, Dimensions } from 'react-native';
import tw from 'twrnc';
import { colors } from '../config/theme';
import { ThemeContext } from '../contexts/themeContext';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native'; // Import the navigation hook

const Button = ({
  onClick,
  navigateTo,
  title,
  icon,
  iconName,
  iconStyle,
  buttonStyle,
  buttonTextStyle,
  disabled,
}) => {

  let [fontsLoaded] = useFonts({
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
  });
  
  const { theme } = useContext(ThemeContext);
  const activeColors = colors[theme.mode];

  const windowWidth = Dimensions.get('window').width;
  const notMobile = windowWidth >= 1200; // Not mobile devices

  const containerStyle = [
    tw`rounded-lg h-12 mb-2.5 pt-2.5 pb-2.5 pr-4 pl-4 justify-center items-center`,
    { backgroundColor: activeColors.buttonColor },
    buttonStyle, // Custom button style
    disabled && { opacity: 0.5 }, // Apply opacity if disabled
  ];

  const textStyle = [
    notMobile ? tw`text-base` : tw`text-sm`,
    { color: activeColors.buttonTextColor, fontFamily: activeColors.interSemiBold },
    buttonTextStyle, // Custom button text style
  ];

  const navigation = useNavigation(); // Get the navigation object

  const handleButtonClick = () => {
    if (navigateTo) {
      // Navigate to the specified screen
      navigation.navigate(navigateTo);
    }

    // Call the onClick handler if provided
    if (onClick && typeof onClick === 'function') {
      onClick();
    }
  };

  const renderIcon = () => {
    if (icon) {
      return React.isValidElement(icon) ? (
        React.cloneElement(icon, {
          size: 20,
          color: activeColors.buttonTextColor,
          style: iconStyle,
        })
      ) : null;
    }

    if (iconName) {
      return (
        <Ionicons
          name={iconName}
          size={20}
          style={[{ color: activeColors.buttonTextColor }, iconStyle]}
        />
      );
    }

    return null;
  };

  return (
    <TouchableOpacity onPress={handleButtonClick} style={containerStyle}>
      <View style={tw`items-center flex-row`}>
        {renderIcon()}
        <Text style={textStyle}>{title}</Text>
      </View>
    </TouchableOpacity>
  );
};

export default Button;
