export class authTokenProvider {
    static instance: authTokenProvider = new authTokenProvider();
    authToken: string | undefined;
    expiry: Date | undefined;

    constructor(){
        this.reset();
    }
    set(authObj){
        localStorage.setItem('authToken', JSON.stringify(authObj));
        this.reset();
    }
    setAsMockUser(authObj){
        var origToken = localStorage.getItem('authToken');
        localStorage.setItem("mockAsUser", "true");
        localStorage.setItem("mockAsUserOriginalToken", origToken);
        localStorage.setItem('authToken', JSON.stringify(authObj));
        this.reset();
    }
    revertMockUser(){
        var origToken = this.isMockingUser();
        localStorage.removeItem("mockAsUser");
        localStorage.removeItem("mockAsUserOriginalToken");
        this.set(origToken);
    }
    isMockingUser(){
        var str = localStorage.getItem('mockAsUserOriginalToken');
        if (!str) return null;
        return JSON.parse(str);
    }
    logout() {
        localStorage.removeItem('authToken');
        this.authToken = undefined;
        this.expiry = undefined;
    }
    reset(){
        var authStr = localStorage.getItem('authToken');
        this.authToken = undefined;
        this.expiry = undefined;
        try{
            if (authStr){
                var authObj = JSON.parse(authStr);
                this.authToken = authObj.token;
                this.expiry = authObj.expiry;
            }
        }
        catch{}
    }
}