import * as React from "react";
import Svg, { G, Path, Defs, ClipPath, Rect } from "react-native-svg";
const TipsLightMode = (props) => (
  <Svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G clipPath="url(#clip0_840_449)">
      <Path
        d="M14.7833 6.55L14.4 5.71667L13.5667 5.33333L14.4 4.95L14.7833 4.11667L15.1667 4.95L16 5.33333L15.1667 5.71667L14.7833 6.55ZM12.6667 3.63333L12.0833 2.4L10.85 1.81667L12.0833 1.23333L12.6667 0L13.25 1.23333L14.4833 1.81667L13.25 2.4L12.6667 3.63333ZM6 14.6667C5.62222 14.6667 5.30278 14.5361 5.04167 14.275C4.78056 14.0139 4.65 13.6944 4.65 13.3167H7.35C7.35 13.6944 7.21944 14.0139 6.95833 14.275C6.69722 14.5361 6.37778 14.6667 6 14.6667ZM3.3 12.2833V11.2833H8.7V12.2833H3.3ZM3.38333 10.2667C2.65 9.78889 2.06944 9.19167 1.64167 8.475C1.21389 7.75833 1 6.95 1 6.05C1 4.69444 1.49444 3.52222 2.48333 2.53333C3.47222 1.54444 4.64444 1.05 6 1.05C7.35556 1.05 8.52778 1.54444 9.51667 2.53333C10.5056 3.52222 11 4.69444 11 6.05C11 6.95 10.7889 7.75833 10.3667 8.475C9.94444 9.19167 9.36111 9.78889 8.61667 10.2667H3.38333ZM3.75 9.26667H8.26667C8.8 8.91111 9.22222 8.45 9.53333 7.88333C9.84445 7.31667 10 6.70556 10 6.05C10 4.95 9.60833 4.00833 8.825 3.225C8.04167 2.44167 7.1 2.05 6 2.05C4.9 2.05 3.95833 2.44167 3.175 3.225C2.39167 4.00833 2 4.95 2 6.05C2 6.70556 2.15556 7.31667 2.46667 7.88333C2.77778 8.45 3.20556 8.91111 3.75 9.26667Z"
        fill="#6A727C"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_840_449">
        <Rect width={16} height={16} fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default TipsLightMode;