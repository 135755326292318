import * as React from "react";
import Svg, { G, Mask, Path, Defs, ClipPath, Rect } from "react-native-svg";
const ActivityIconActive = (props) => (
  <Svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G clipPath="url(#clip0_1828_1343)">
      <Mask
        id="mask0_1828_1343"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={20}
        height={20}
      >
        <Path d="M19.8 0.466797H0.466675V19.8001H19.8V0.466797Z" fill="white" />
      </Mask>
      <G mask="url(#mask0_1828_1343)">
        <Path
          d="M19.9334 6.93339V6.7334C19.9334 5.9334 19.8001 5.1334 19.6001 4.40006C19.1334 2.66673 18.0667 1.46673 16.4001 0.866729C15.5334 0.533395 14.5334 0.333395 13.5334 0.333395C12.0001 0.333395 10.4667 0.333395 8.93339 0.333395H7.33339C6.13339 0.266729 5.13339 0.400062 4.26672 0.666729C2.60006 1.20006 1.40006 2.20006 0.866724 3.80006C0.466724 4.66673 0.33339 5.60006 0.266724 6.66673C0.266724 7.46673 0.266724 8.26673 0.266724 9.06673C0.266724 9.3334 0.266724 9.66673 0.266724 9.9334V10.8667C0.266724 11.4667 0.266724 12.0667 0.266724 12.7334C0.266724 14.0001 0.400057 14.9334 0.600057 15.8667C1.06672 17.5334 2.06672 18.6667 3.53339 19.3334C4.46672 19.7334 5.53339 19.9334 6.66672 19.9334C7.73339 19.9334 8.86672 19.9334 9.93339 19.9334C10.3334 19.9334 10.7334 19.9334 11.2001 19.9334H12.8667C13.9334 19.9334 14.8667 19.8001 15.7334 19.6001C17.4001 19.1334 18.6001 18.1334 19.2001 16.6667C19.6001 15.7334 19.8001 14.7334 19.8001 13.5334C19.9334 11.4667 19.9334 9.33339 19.9334 6.93339ZM1.93339 10.2667V9.06673C1.93339 8.26673 1.93339 7.5334 1.93339 6.7334C2.00006 6.06673 2.06672 5.3334 2.26672 4.66673C2.66672 3.3334 3.53339 2.5334 4.93339 2.20006C5.60006 2.00006 6.40006 1.9334 7.26672 1.9334H9.00006C10.4667 1.9334 12.0001 1.9334 13.4667 1.9334C14.0667 1.9334 14.7334 2.00006 15.4001 2.20006C16.8001 2.60006 17.6667 3.46673 18.0001 4.86673C18.1334 5.5334 18.2667 6.20006 18.2667 6.93339V7.1334C18.2667 9.20006 18.2667 11.2667 18.2667 13.3334C18.2667 14.0001 18.1334 14.7334 17.9334 15.4001C17.5334 16.6667 16.7334 17.4667 15.4667 17.8001C14.7334 18.0001 13.9334 18.1334 13.0001 18.0667H11.4667C9.93339 18.0667 8.40006 18.0667 6.86672 18.0667C6.13339 18.0667 5.40006 17.9334 4.66672 17.7334C3.33339 17.4667 2.60006 16.6667 2.20006 15.3334C1.93339 14.4001 1.93339 13.4667 1.93339 12.8001C1.93339 12.2001 1.93339 11.6001 1.93339 11.0667V10.2667Z"
          fill="#454545"
        />
        <Path
          d="M15.1334 6.4668C13.6001 6.4668 12.0668 6.4668 10.4668 6.4668C10.2001 6.4668 10.0001 6.53346 9.80011 6.73346C9.66678 6.8668 9.53345 7.13346 9.53345 7.33346C9.53345 7.80013 9.93345 8.13346 10.4668 8.13346H12.0001H15.0668C15.2001 8.13346 15.2668 8.13346 15.4001 8.0668C15.8001 7.93346 16.0001 7.53346 16.0001 7.13346C15.9334 6.73346 15.6001 6.4668 15.1334 6.4668Z"
          fill="#454545"
        />
        <Path
          d="M15.8667 13.1334C15.7334 12.9334 15.4001 12.8001 15.1334 12.7334C14.6001 12.7334 14.0667 12.7334 13.5334 12.7334H12.6667H12.1334C11.6001 12.7334 11.0001 12.7334 10.4667 12.7334C10.0001 12.7334 9.66675 13.1334 9.66675 13.6001C9.66675 14.0667 10.0667 14.4667 10.5334 14.4667C11.3334 14.4667 12.1334 14.4667 12.8667 14.4667C13.6001 14.4667 14.4667 14.4667 15.2001 14.4667C15.6001 14.4667 15.8667 14.2667 16.0001 13.8667C16.0667 13.6001 16.0001 13.3334 15.8667 13.1334Z"
          fill="#454545"
        />
        <Path
          d="M8.60007 12.1332C8.53341 11.7999 8.33341 11.5999 8.06674 11.4666C7.73341 11.3332 7.40007 11.3999 7.20007 11.6666L7.06674 11.7999C6.66674 12.1999 6.20007 12.6666 5.80007 13.0666C5.60007 12.8666 5.40007 12.7332 5.13341 12.7332C4.80007 12.7332 4.53341 12.9332 4.33341 13.1999C4.20007 13.5332 4.26674 13.8666 4.46674 14.1332C4.66674 14.3332 4.93341 14.5999 5.13341 14.7999C5.33341 14.9999 5.53341 15.0666 5.73341 15.0666C5.93341 15.0666 6.13341 14.9999 6.33341 14.7999C6.86674 14.2666 7.40007 13.7332 7.93341 13.1999L8.33341 12.7999C8.53341 12.6666 8.60007 12.3999 8.60007 12.1332Z"
          fill="#454545"
        />
        <Path
          d="M8.33336 5.33356C8.13336 5.13356 7.93336 5.06689 7.73336 5.06689C7.53336 5.06689 7.26669 5.20023 7.13336 5.33356L5.80002 6.66689C5.73336 6.73356 5.66669 6.73356 5.60002 6.66689C5.26669 6.33356 4.73336 6.33356 4.40002 6.66689C4.06669 7.00023 4.06669 7.53356 4.40002 7.86689C4.60002 8.06689 4.80002 8.26689 5.00002 8.46689L5.06669 8.53356C5.20002 8.66689 5.40002 8.80023 5.60002 8.80023C5.93336 8.80023 6.13336 8.60023 6.26669 8.53356L7.20002 7.60023C7.53336 7.26689 7.86669 6.93356 8.20002 6.60023C8.66669 6.20023 8.66669 5.73356 8.33336 5.33356Z"
          fill="#454545"
        />
      </G>
    </G>
    <Defs>
      <ClipPath id="clip0_1828_1343">
        <Rect width={20} height={20} fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default ActivityIconActive;
