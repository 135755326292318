import * as React from "react";
import Svg, { Line, Path } from "react-native-svg";
const DepositIconLightMode = (props) => (
  <Svg
    width={14}
    height={15}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Line
      x1={1.58398}
      y1={13.3}
      x2={12.8175}
      y2={13.3}
      stroke="#6A727C"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M3.13672 5.69983L7.20154 9.99998M7.20154 9.99998L11.2662 5.69983M7.20154 9.99998L7.20143 1.5"
      stroke="#6A727C"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default DepositIconLightMode;
