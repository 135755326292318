import * as React from "react";
import Svg, { Defs, Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: style */
const NoTransactionDark = (props) => (
  <Svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 80 80"
    {...props}
  >
    <Defs></Defs>
    <Path
      className="cls-1"
      fill="#666970"
      d="m17.99,73.71c-2.28,0-4.21-.79-5.8-2.37-1.58-1.58-2.37-3.51-2.37-5.8v-3.52c0-1.62.58-3.01,1.73-4.17,1.16-1.16,2.55-1.73,4.17-1.73h3.89V17.54c0-1.62.58-3.01,1.73-4.17,1.16-1.16,2.55-1.73,4.17-1.73h33.92c1.62,0,3.01.58,4.17,1.73,1.16,1.16,1.73,2.55,1.73,4.17v48c0,2.28-.79,4.21-2.37,5.8-1.58,1.58-3.51,2.37-5.8,2.37H17.99Zm39.2-4.9c.93,0,1.7-.31,2.33-.94s.94-1.4.94-2.33V17.54c0-.29-.09-.53-.28-.72s-.43-.28-.72-.28H25.53c-.29,0-.53.09-.72.28s-.28.43-.28.72v38.57h23.5c1.62,0,3.01.58,4.17,1.73s1.73,2.55,1.73,4.17v3.52c0,.93.31,1.7.94,2.33s1.4.94,2.33.94ZM30.87,31.87c-.69,0-1.28-.23-1.75-.7-.47-.47-.7-1.05-.7-1.75s.23-1.28.7-1.75c.47-.47,1.05-.7,1.75-.7h23.24c.69,0,1.28.23,1.75.7.47.47.7,1.05.7,1.75,0,.69-.23,1.28-.7,1.75-.47.47-1.05.7-1.75.7,0,0-23.24,0-23.24,0Zm0,9.42c-.69,0-1.28-.23-1.75-.7-.47-.47-.7-1.05-.7-1.75s.23-1.28.7-1.75c.47-.47,1.05-.7,1.75-.7h23.24c.69,0,1.28.23,1.75.7.47.47.7,1.05.7,1.75,0,.69-.23,1.28-.7,1.75-.47.47-1.05.7-1.75.7,0,0-23.24,0-23.24,0Zm-12.88,27.52h31.03v-6.78c0-.29-.09-.53-.28-.72-.19-.19-.43-.28-.72-.28H15.73c-.29,0-.53.09-.72.28-.19.19-.28.43-.28.72v3.52c0,.93.31,1.7.94,2.33s1.4.94,2.33.94Zm0,0h-3.27,34.3-31.03Z"
    />
  </Svg>
);
export default NoTransactionDark;