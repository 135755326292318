import React, { useContext, useState, useEffect } from 'react';
import { StyleSheet, View, Image, Dimensions } from 'react-native';
import { Card, Text } from 'react-native-paper';
import { Column as Col, Row } from 'react-native-flexbox-grid';
import tw from 'twrnc';
import { colors } from '../config/theme';
import { ThemeContext } from '../contexts/themeContext';
import {
  useFonts,
  Inter_500Medium,
  Inter_600SemiBold,
} from '@expo-google-fonts/inter';

export default function News() {

  let [fontsLoaded] = useFonts({
    Inter_500Medium,
    Inter_600SemiBold,
  });
  const { theme } = useContext(ThemeContext);
  let activeColors = colors[theme.mode];

  // Responsive column grid
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width);

  useEffect(() => {
    const handleWindowResize = ({ window }) => {
      setWindowWidth(window.width);
    };

    Dimensions.addEventListener('change', handleWindowResize);

    return () => {
      Dimensions.removeEventListener('change', handleWindowResize);
    };
  }, []);

  const calculateWidth = (sm, md, lg) => {
    let columns = 12; // Default column number for extra small devices

    if (windowWidth >= 320 && windowWidth < 1100) {
      columns = sm;
    } else if (windowWidth >= 1100 && windowWidth < 1230) {
      columns = md;
    } else if (windowWidth >= 1230) {
      columns = lg;
    }

    // Calculate percentage (i.e., '4 columns' out of '12 columns' is '33.33%')
    return `${(columns / 12) * 100}%`;
  };

  const notMobile = windowWidth >= 1100; // Not mobile devices

  const news = {
    img: 'https://raw.githubusercontent.com/CmdFury/tokenLogo/main/Teamcz.jpg',
  };

  return (

    <View>
      <Row size={12} style={tw`justify-between`}>
        <Col style={{ width: calculateWidth(12, 12, 12) }}>
          <View>
            <Text style={[tw`mb-5 text-lg pr-2.5 pl-2.5`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold}]}>News</Text>    
          </View>
        </Col>
      </Row>

      <Row size={12} style={tw`justify-between`}>
        <Col style={[notMobile ? tw`mb-10 pr-6` : tw`mb-4 pr-0`, { width: calculateWidth(12, 4, 4) }]}>
          <View style={tw`w-full pt-0.5 pr-2.5 pl-2.5 pb-2`}>
             <Card elevation={0} style={[tw`rounded-xl`, { backgroundColor: activeColors.cardBgColor }, theme.mode === 'dark' ? styles.darkBoxshadow : styles.boxShadow,  ]} >
              <Card.Cover source={{ uri: news.img  }} style={tw`rounded-b-none mb-3`} />
              <Card.Content>
                <Text style={[tw`text-base mb-5`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold }]}>
                  Binance Insists on Centralization of Fuel Before Listing
                </Text>
                <Text style={[tw`text-sm`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interMedium }]}>
                  12 hours ago  |  4min read
                </Text>
              </Card.Content>
            </Card>
          </View>
        </Col>

        <Col style={[notMobile ? tw`mb-10 pr-3 pl-3` : tw`mb-4 pr-0 pl-0`, { width: calculateWidth(12, 4, 4) }]}>
          <View style={tw`w-full pt-0.5 pr-2.5 pl-2.5 pb-2`}>
            <Card elevation={0} style={[ tw`rounded-xl`, { backgroundColor: activeColors.cardBgColor }, theme.mode === 'dark' ? styles.darkBoxshadow : styles.boxShadow, ]} >
              <Card.Cover source={{ uri: news.img  }} style={tw`rounded-b-none mb-3`} />
              <Card.Content>
                <Text style={[tw`text-base mb-5`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold }]}>
                  Fuel to Be Part of FullStack Bootcamp Curriculum 
                </Text>
                <Text style={[tw`text-sm`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interMedium }]}>
                  12 hours ago  |  4min read
                </Text>
              </Card.Content>
            </Card>
          </View>
        </Col>

        <Col style={[notMobile ? tw`mb-10 pl-6` : tw`mb-4 pl-0`, { width: calculateWidth(12, 4, 4) }]}>
          <View style={tw`w-full pt-0.5 pr-2.5 pl-2.5 pb-2`}>
            <Card elevation={0} style={[ tw`rounded-xl`, { backgroundColor: activeColors.cardBgColor }, theme.mode === 'dark' ? styles.darkBoxshadow : styles.boxShadow, ]} >
              <Card.Cover source={{ uri: news.img  }} style={tw`rounded-b-none mb-3`} />
              <Card.Content>
                <Text style={[tw`text-base mb-5`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold }]}>
                  Fuel Appplication Voltron 2.0 to be Launched Soon
                </Text>
                <Text style={[tw`text-sm`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interMedium }]}>
                  12 hours ago  |  4min read
                </Text>
              </Card.Content>
            </Card>
          </View>
        </Col>
      </Row>
    </View>
  );
}

const styles = StyleSheet.create({
  boxShadow: {
    boxShadow: '0px 2px 14px 0px rgba(189, 193, 209, 0.30)',
  },
  darkBoxshadow: {
    boxShadow: 'none',
  },
  newsFooter: {
    position: 'absolute',
    bottom: 0,
  },
  newImg: {
    width: 406,
    height: 151,
  }
});