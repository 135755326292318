import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SubmissionHigh = (props) => (
  <Svg
    width={16}
    height={13}
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M10.1372 4.32247C10.1372 3.92523 9.81651 3.60457 9.41927 3.60457H7.51444C7.11721 3.60457 6.79655 3.92523 6.79655 4.32247V10.8745H10.1372V4.32247ZM5.72448 7.34722C5.72448 6.94998 5.40382 6.62932 5.00658 6.62932H3.10176C2.70452 6.62932 2.38386 6.94998 2.38386 7.34722V10.8745H5.72448V7.34722ZM14.4876 1.068C14.4876 0.670759 14.167 0.350098 13.7697 0.350098H11.8649C11.4677 0.350098 11.147 0.670759 11.147 1.068V10.8745H14.4876V1.068ZM15.5214 11.6929H1.40273C1.1395 11.6929 0.924133 11.9083 0.924133 12.1715C0.924133 12.4347 1.1395 12.6501 1.40273 12.6501H15.5214C15.7846 12.6501 16 12.4347 16 12.1715C16 11.9083 15.7846 11.6929 15.5214 11.6929Z"
      fill="#E06365"
    />
  </Svg>
);
export default SubmissionHigh;
