import React, { useState, useEffect } from 'react';
import {  StyleSheet, View, TouchableOpacity, Text,Animated } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import tw from 'twrnc';
import { colors } from '../config/theme';
import { useContext } from 'react';
import { ThemeContext } from '../contexts/themeContext';
import HomeIconActive from '../assets/images/homeIconActive';
import HomeIconLightMode from '../assets/images/homeIconLightMode';
import HomeIconDarkMode from '../assets/images/homeIconDarkMode';
import SettingsIconActive from '../assets/images/settingsIconActive';
import SettingsIconLightMode from '../assets/images/settingsIconLightMode';
import SettingsIconDarkMode from '../assets/images/settingsIconDarkMode';
import WalletIconActive from '../assets/images/WalletIconActive';
import WalletIconLightMode from '../assets/images/walletIconLightMode';
import WalletIconDarkMode from '../assets/images/walletIconDarkMode';
import ExploreIconDefault from '../assets/images/exploreIconDefault';
import ExploreIconActive from '../assets/images/exploreIconActive'; 
import ExploreIconDark from '../assets/images/exploreIconDark';
import ActivityIconActive from '../assets/images/activityIconActive';
import ActivityIconDarkMode from '../assets/images/activityIconDarkMode';
import ActivityIconLightMode from '../assets/images/activityIconLightMode';
import MenuFuelLogo from '../assets/images/menuFuelLogo';
import { modelStore, modelStoreNameEnum } from '../services/modelStore';
import { authTokenProvider } from '../services/authTokenProvider';

let menuItemsDef = [
  {
    name: 'Dashboard',
  },
  {
    name: 'Explore',
  },
  {
    name: 'Activity Log',
  },
  {
    name: 'My Wallet',
  }  
];

const SideMenu = () => {

  const subOwnerName = 'sidemenu';
  const navigation = useNavigation();
  const menuWidth = 200;
  const [menuItems, setMenuItems] = useState(menuItemsDef);
  const [activeItem, setActiveItem] = useState(menuItems[0].name);

  const handlePress = (name) => {
    setActiveItem(name);
    navigation.navigate(name);
  };

  useEffect(() => {
    modelStore.instance.subscribe(subOwnerName, modelStoreNameEnum.User, (usr) => {
      if (localStorage.getItem('fuelAdm') == 'L9k;5x=65Yd]' || authTokenProvider.instance.isMockingUser()){
        menuItemsDef.push({
          name: 'Admin',
        });
        setMenuItems(menuItemsDef);
      }
    });
  }, []);

  useEffect(() => {
    const unsubscribe = navigation.addListener('state', () => {
      const activeScreen = navigation.getCurrentRoute()?.name;
      setActiveItem(activeScreen || menuItems[0].name);
    });

    return unsubscribe;
  }, [navigation]);

  const { theme, updateTheme } = useContext(ThemeContext);
  let activeColors = colors[theme.mode];

  const [isActive, setIsActive] = useState(theme.mode === 'dark');

  useEffect(() => {
    setIsActive(theme.mode === 'dark');
  }, [theme.mode]);

  const handleSwitch = () => {
    updateTheme();
    setIsActive((previousState) => !previousState);
  };

  return (
    <Animated.View
      style={[
        tw`pt-5 items-center h-full overflow-hidden`,
        { width: menuWidth, backgroundColor: activeColors.navbarBgColor, borderRightWidth: 1, borderRightColor: activeColors.navbarBorderRightColor },
        theme.mode === 'dark' ? styles.darkBoxshadow : styles.boxShadow,
      ]}
    >
      <View style={[tw`mt-0 mb-0 items-center flex-row justify-center w-full`, styles.logoContainer]}>
        <MenuFuelLogo style={[tw`mt-8, mb-11, -top-2`, { position: 'relative'}]} />
      </View>
      {menuItems.map((item) => (
        <TouchableOpacity
          key={item.name}
          style={[
            styles.menuItem,
            tw`flex-row items-center justify-start h-14`,
            activeItem === item.name && {
              ...styles.activeMenuItem,
              backgroundColor: activeColors.navbarActiveBgColor,
            },
            { width: '100%', borderColor: activeColors.navbarActiveBorder },
          ]}
          onPress={() => handlePress(item.name)}
        >
          <View style={[tw`w-6, h-6 justify-center items-center`, styles.iconContainerCentered]}>
            {activeItem === item.name && item.name === 'Dashboard' ? (
              theme.mode === 'dark' ? <HomeIconDarkMode /> : <HomeIconActive/>
            ) : item.name === 'Dashboard' ? (
              theme.mode === 'dark' ? <HomeIconActive/> : <HomeIconLightMode />
            ) : activeItem === item.name && item.name === 'Explore' ? (
              theme.mode === 'dark' ? <ExploreIconDark /> : <ExploreIconActive />
            ) : item.name === 'Explore' ? (
              theme.mode === 'dark' ? <ExploreIconActive /> : <ExploreIconDefault />
            ): activeItem === item.name && item.name === 'Activity Log' ? (
              theme.mode === 'dark' ? <ActivityIconDarkMode /> : <ActivityIconActive />
            ) : item.name === 'Activity Log' ? (
              theme.mode === 'dark' ? <ActivityIconActive /> : <ActivityIconLightMode />
            ) : activeItem === item.name && item.name === 'My Wallet' ? (
              theme.mode === 'dark' ? <WalletIconDarkMode /> : <WalletIconActive size={24} color={activeColors.navbarItemColor} />
            ) : (
              theme.mode === 'dark' ? <WalletIconActive size={24} color={activeColors.navbarItemColor} /> : <WalletIconLightMode />
            )}
          </View>
          <Text style={[tw`ml-5`, { color: activeColors.navbarItemColor, fontFamily: activeColors.interMedium }, styles.menuText, activeItem === item.name && { color: theme.mode === 'dark' ? activeColors.whiteColor : activeColors.primaryTextColor }]}>
            {item.name}
          </Text>
        </TouchableOpacity>
      ))}
      <View style={tw`flex-1`}></View>
      <TouchableOpacity
        style={[
          styles.menuItem,
          tw`flex-row items-center justify-start h-14 mb-8`,
          activeItem === 'Settings' && {
            ...styles.activeMenuItem,
            backgroundColor: activeColors.navbarActiveBgColor,
          },
          { width: '100%', borderColor: activeColors.navbarActiveBorder },
        ]}
        onPress={() => handlePress('Settings')}
      >
        <View style={[tw`w-6 h-6 justify-center items-center`, styles.iconContainerCentered]}>
          {activeItem === 'Settings' ? (
            theme.mode === 'dark' ? <SettingsIconDarkMode /> : <SettingsIconActive />
          ) : (
            theme.mode === 'dark' ? <SettingsIconActive /> : <SettingsIconLightMode />
          )}
        </View>
        <Text style={[
          tw`ml-5`,
          { color: activeColors.navbarItemColor, fontFamily: activeColors.interMedium },
          styles.menuText,
          activeItem === 'Settings' && { color: theme.mode === 'dark' ? activeColors.whiteColor : activeColors.primaryTextColor },
        ]}>
          Settings
        </Text>
      </TouchableOpacity>
    </Animated.View>
  );
};


const styles = StyleSheet.create({
  menuItem: {
    paddingHorizontal: 20,
  },
  activeMenuItem: {
    borderLeftWidth: 10,
    paddingHorizontal: 10,
  },
  menuText: {
    fontSize: 15,
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: 'normal',
  },
  iconContainerCentered: {
    marginLeft: 10,
  },
  logoContainer: {
    paddingHorizontal: 20,
  },
  toggleMenuButton: {
    top: 26,
  },
  closeButton: {
    top: 65,
  },
  switchContainer: {
    paddingHorizontal: 20,
    marginTop: 'auto',
  },
  boxShadow: {
      boxShadow: '4px 3px 19px 0px rgba(189, 193, 209, 0.30)',
  },
  darkBoxshadow: {
    boxShadow: "none",
  }
});

export default SideMenu;
