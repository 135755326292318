import * as React from "react";
import Svg, { Path } from "react-native-svg";
const OpenIcon = (props) => (
  <Svg
    width={11}
    height={11}
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M5.45312 6.71094C5.70798 6.71094 5.9524 6.6097 6.13261 6.42949C6.31282 6.24927 6.41406 6.00486 6.41406 5.75C6.41406 5.49514 6.31282 5.25073 6.13261 5.07051C5.9524 4.8903 5.70798 4.78906 5.45312 4.78906C5.19827 4.78906 4.95385 4.8903 4.77364 5.07051C4.59343 5.25073 4.49219 5.49514 4.49219 5.75C4.49219 6.00486 4.59343 6.24927 4.77364 6.42949C4.95385 6.6097 5.19827 6.71094 5.45312 6.71094Z"
      fill="white"
    />
    <Path
      d="M5.45312 0.625C6.81236 0.625 8.11592 1.16495 9.07705 2.12608C10.0382 3.0872 10.5781 4.39077 10.5781 5.75C10.5781 7.10923 10.0382 8.4128 9.07705 9.37392C8.11592 10.335 6.81236 10.875 5.45312 10.875C4.09389 10.875 2.79033 10.335 1.8292 9.37392C0.868079 8.4128 0.328125 7.10923 0.328125 5.75C0.328125 4.39077 0.868079 3.0872 1.8292 2.12608C2.79033 1.16495 4.09389 0.625 5.45312 0.625ZM1.28906 5.75C1.28906 6.85438 1.72777 7.91352 2.50869 8.69444C3.2896 9.47535 4.34875 9.91406 5.45312 9.91406C6.5575 9.91406 7.61665 9.47535 8.39756 8.69444C9.17848 7.91352 9.61719 6.85438 9.61719 5.75C9.61719 4.64562 9.17848 3.58648 8.39756 2.80556C7.61665 2.02465 6.5575 1.58594 5.45312 1.58594C4.34875 1.58594 3.2896 2.02465 2.50869 2.80556C1.72777 3.58648 1.28906 4.64562 1.28906 5.75Z"
      fill="white"
    />
  </Svg>
);
export default OpenIcon;
