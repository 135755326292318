import React, { useContext, useState, useEffect } from 'react';
import { StyleSheet, View, Dimensions,Image } from 'react-native';
import { Card, Text } from 'react-native-paper';
import { Column as Col, Row } from 'react-native-flexbox-grid';
import tw from 'twrnc';
import { colors } from '../config/theme';
import { ThemeContext } from '../contexts/themeContext';
import { Ionicons } from '@expo/vector-icons';
import FuelGrey from '../assets/images/fuelGrey';
import {
  useFonts,
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
} from '@expo-google-fonts/inter';

export default function StackOverflow() {
  let [fontsLoaded] = useFonts({
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
  });

  const { theme } = useContext(ThemeContext);
  let activeColors = colors[theme.mode];
// Responsive column grid
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width);

  useEffect(() => {
    const handleWindowResize = ({ window }) => {
      setWindowWidth(window.width);
    };

    Dimensions.addEventListener('change', handleWindowResize);

    return () => {
      Dimensions.removeEventListener('change', handleWindowResize);
    };
  }, []);

  const calculateWidth = (sm, md, lg) => {
    let columns = 12; // Default column number for extra small devices

    if (windowWidth >= 320 && windowWidth < 1200) {
      columns = sm;
    } else if (windowWidth >= 1200 && windowWidth < 1230) {
      columns = md;
    } else if (windowWidth >= 1230) {
      columns = lg;
    }

    // Calculate percentage (i.e., '4 columns' out of '12 columns' is '33.33%')
    return `${(columns / 12) * 100}%`;
  };

  const notMobile = windowWidth >= 1200; // Not mobile devices
  
  const newsLink2 = 'stackoverflow.com/questions...';
  const newsH2 = 'Failed to subscribe to multiple topics ';

  const newTaskLogo = {
    StackOverflow: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJ4AAACeCAMAAAD0W0NJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABhQTFRF/3IA/5ZB/////86m/+fU//Pp/7d8/9u+AtmlhgAAAh5JREFUeNrsmMGSwiAQRJ0Bhv//41WjMQlDEq116LL6Hffgvhq6gXC5EEIIIYQQQgghhBDyISkBy0lWFVy7oqoF1S/pnQy7sBMGPLsbkMs7T08L5Oq+xlcR/ezlx/gxfowfdPxse8RCxU+aKSHF735F2aQMKH6PlUyY8atPjYwYv8WYVgXBiN/CYrOOGPGbiuGJYMTvdUHeFqRgxK8u/TLe7mdLv0VBUHY/6xSkgux+vYIUlMPXL4j4pYEpCM7h6xekwtz9/IIU9Q9lkIKMiV9OcrIgI+J3m0mV3QvMvJoD4jeNKcupgoTHbw5UsRNXrPlPWcI3uUbQKYjFVmM9oebftgWpocVIumFT47YgJWxhr2jLusZNQST0yLUjwf43SNTDRSuYrVeQAcet5VZwWeM8+i4v+4Jp+F1AqhPCucbmfJ5HC6YdQQG457mCjxrfCjL+hdSt8SSYId5vj/aZ8YIH+8xwvH0m/PPR5J19Jrq191u8iZyrcfzwnrGqnTGuBMO3vNV0SnId5xrHn7XtBpIdx4dg/HmhPtfPXmlqHH8VEO2ziaPUAbdQPaATxxHPZF3yMEexWvSc46gpytXxhN/Y52RLeT+FcbvIjmN3qevZH/me3l4cDUSvE0cB0nPieEHTWzkWTL1nHBOw3r//yE/o6RtQj3rUox71eKhRj3rUo95XHvM+hHrUox71qEcIIYQQQgghhBBCCPlN/gQYAGR+G2jYjqfiAAAAAElFTkSuQmCC',
  };

  
  return (
    <Col style={{ width: calculateWidth(12, 12, 12) }}>
        <Row size={12} style={tw`justify-between`}>
            <Col style={{ width: calculateWidth(12, 12, 12) }}>
              <Card elevation={0} style={[tw`mb-0 pt-0.5 pr-2.5 pl-2.5 pb-2`]}>
                <Card.Content style={[tw`rounded-xl h-122`, { backgroundColor: activeColors.cardBgColor }, theme.mode === 'dark' ? styles.darkBoxshadow : styles.boxShadow]}>
                  <View style={{ zIndex: 8000, elevation: 4, flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <View>
                      <Ionicons name="construct-outline" size={80} color={activeColors.secondaryTextColor} />
                    </View>
                    <Text style={[tw`mt-5 text-base`, { color: activeColors.secondaryTextColor, fontFamily: activeColors.interMedium }]}>
                        Coming soon.
                    </Text>
                  </View>
                </Card.Content>
              </Card>
            </Col>
        </Row>
    </Col>
  );
}

const styles = StyleSheet.create({
  boxShadow: {
    boxShadow: '0px 2px 14px 0px rgba(189, 193, 209, 0.30)',
  },
  darkBoxshadow: {
    boxShadow: 'none',
  },
  tokenLogo:{
    top: 5,
  },
  subText: {
    fontSize: 11,
  },
  tabTextBlue:{
    color: '#5C80ED',
  },
});