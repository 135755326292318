import {API_BASE_PATH} from '@env'
import axios from 'axios';

export class tokenApiService {
    static instance: tokenApiService = new tokenApiService();
    // async get() {
    //     var res = await axios.get(`${API_BASE_PATH}/token`);
    //     return res.data;
    // }
    async purchase(qty: number) {
        var res = await axios.post(`${API_BASE_PATH}/token/purchase/${qty}`);
        return res.data;
    }
}