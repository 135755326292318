import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
import { Ionicons } from '@expo/vector-icons';

const CustomPicker = ({ items, activeColors, selectedValue, onValueChange, containerStyle, dropdownStyle, placeholder = "Select an item", searchable }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(selectedValue);
  const [itemsList, setItemsList] = useState(items);

  useEffect(() => {
    if (items !== itemsList) {
      setItemsList(items);
    }
  }, [items]);

  useEffect(() => {
    if (selectedValue !== value) {
      setValue(selectedValue);
    }
  }, [selectedValue]);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 9000, 
      ...containerStyle,
    },
    dropdown: {
      backgroundColor: activeColors.cardBgColor,
      borderColor: activeColors.textInputOutline,
      borderWidth: 1,
      borderRadius: 8,
      height: 44,  
      paddingLeft: 5,  
       ...dropdownStyle,
    },
    labelStyle: {
      color: activeColors.primaryTextColor,
      fontSize: 12, 
      textAlign: 'left',
    },
    itemStyle: {
      justifyContent: 'flex-start',
      height: 44,
    },
    textStyle: {
      fontSize: 12,
      color: activeColors.primaryTextColor,
    },
    placeholderStyle: {
      color: activeColors.secondaryTextColor,
      fontSize: 12, 
    },
    dropdownStyle: {
      borderColor: activeColors.textInputOutline,
      borderWidth: 1,
      borderRadius: 8,
      backgroundColor: activeColors.cardBgColor,
    },
    searchTextInputStyle: {
      borderColor: activeColors.paginationButtonColor,
      color: activeColors.paginationButtonTextColor,
      fontSize: 12, 
      height: 44,
    },
    searchContainerStyle: {
      borderBottomColor: activeColors.paginationButtonColor,
    }
  });

  return (
    <View style={styles.container}>
      <DropDownPicker
        open={open}
        value={value}
        items={itemsList}
        setOpen={setOpen}
        setValue={(val) => {
          setValue(val);
          if(onValueChange) {
            onValueChange(val);
          }
        }}
        setItems={setItemsList}
        listMode="SCROLLVIEW"
        maxHeight={170} // Set height of the select options container
        style={[styles.dropdown, containerStyle]} 
        dropDownContainerStyle={[styles.dropdownStyle, dropdownStyle]} 
        labelStyle={styles.labelStyle}
        itemStyle={styles.itemStyle}
        textStyle={styles.textStyle}
        placeholderStyle={styles.placeholderStyle}
        searchTextInputStyle={styles.searchTextInputStyle}
        placeholder={placeholder}
        searchPlaceholderTextColor={activeColors.secondaryTextColor}
        searchContainerStyle={styles.searchContainerStyle}
        showTickIcon={false}
        searchable={searchable}
        searchTextInputProps={{
          maxLength: 25
        }}
        dropDownDirection="BOTTOM"
        searchPlaceholder="Search Coin"
        ArrowUpIconComponent={({style}) => <Ionicons name="caret-up" size={15} color={activeColors.customPickerIcon} />}
        ArrowDownIconComponent={({style}) => <Ionicons name="caret-down" size={15} color={activeColors.customPickerIcon} />}
      />
    </View>
  );
};

export default CustomPicker;