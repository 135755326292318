import * as React from "react";
import Svg, { Circle } from "react-native-svg";
const StatusPending = (props) => (
  <Svg
    width={13}
    height={12}
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={6.92413} cy={6} r={5.5} fill="#F0B90B" stroke="white" />
  </Svg>
);
export default StatusPending;
