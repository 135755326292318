import React from 'react';
import { View, StyleSheet } from 'react-native';
import tw from 'twrnc';

const Hr = ({ thickness, style }) => {
  return (
    <View style={[tw`w-full`, { height: thickness }, style]} />
  );
};

export default Hr;
