import React, { useContext, useState, useEffect } from 'react';
import { useAccount } from 'wagmi';
import { authTokenProvider } from '../services/authTokenProvider';
import { modelStore, modelStoreNameEnum } from '../services/modelStore';
import { StyleSheet, View, SafeAreaView, ScrollView, Dimensions } from 'react-native';
import { Column as Col, Row } from 'react-native-flexbox-grid';
import { Text } from 'react-native-paper';
import { colors } from '../config/theme';
import { ThemeContext } from '../contexts/themeContext';
import { useFonts, Inter_500Medium } from '@expo-google-fonts/inter';
import tw from 'twrnc';
import ContainerWrapper from '../components/containerWrapper';
import Header from '../components/header';
import Banner from '../components/banner';
import Transactions from '../components/mainTransaction';
import WalletOverview from '../components/mainWallet';
import News from '../components/news';
import Guide from '../components/guide';
import AllTasks from '../components/allTasks';

export default function Home() {

  const [fontsLoaded] = useFonts({
    Inter_500Medium,
  });

  const { isDisconnected } = useAccount();

  useEffect(() => {
    if(isDisconnected){
      window.location.reload();
    }
  }, [isDisconnected])

  const { theme } = useContext(ThemeContext);
  const activeColors = colors[theme.mode];

  const authToken = authTokenProvider.instance.authToken;
  var subOwnerName = "home";

  var [user, setUser] = useState(null);
  useEffect(() => {
    modelStore.instance.subscribe(subOwnerName, modelStoreNameEnum.User, (usr) => {
        setUser(usr);
    });
  }, []);

  // Responsive column grid
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width);

  useEffect(() => {
    const handleWindowResize = ({ window }) => {
      setWindowWidth(window.width);
    };

    Dimensions.addEventListener('change', handleWindowResize);

    return () => {
      Dimensions.removeEventListener('change', handleWindowResize);
    };
  }, []);

  const calculateWidth = (sm, md, lg) => {
    let columns = 12; // Default column number for extra small devices

    if (windowWidth >= 320 && windowWidth < 1200) {
      columns = sm;
    } else if (windowWidth >= 1200 && windowWidth < 1230) {
      columns = md;
    } else if (windowWidth >= 1230) {
      columns = lg;
    }

    // Calculate percentage (i.e., '4 columns' out of '12 columns' is '33.33%')
    return `${(columns / 12) * 100}%`;
  };

  const notMobile = windowWidth >= 1200; // Not mobile devices

  const columnSizes = [3, 9]; // Set allocation width from mainWallet component to a different width in wallet screen

  const toolTips = [-35]; // Transaction and network fees tooltip position

  return (
    <SafeAreaView style={{ backgroundColor: activeColors.appBackgroundColor, overflowY: 'scroll', height: '100%' }}>
      <Header />
      <ContainerWrapper>
        <ScrollView>
          {user && (
            <>
              <View style={tw`w-full`}>
                <Row size={12} style={tw`justify-between`}>
                  <Col style={[notMobile ? tw`mb-14` : tw`mb-3 pr-0`, { width: calculateWidth(12, 12, 12) } ]}>
                    <Banner />
                  </Col>
                </Row>
              </View>

              <View style={tw`w-full`}>
                <Row size={12} style={tw`justify-between`}>
                  <Col style={[notMobile ? tw`mb-14 pr-4` : tw`mb-3 pr-0`, { width: calculateWidth(12, 8, 8) } ]}>
                    <Text style={[tw`mb-5 text-lg leading-7 pr-2.5 pl-2.5`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold}]}>Wallet Overview</Text>
                    <WalletOverview columnSizes={columnSizes} screenType="Dashboard" /> 
                  </Col>

                  <Col style={[notMobile ? tw`mb-14 pl-4 block` : tw`mb-3 pl-0 hidden`, { width: calculateWidth(12, 4, 4) }]}>
                    <Text style={[tw`mb-5 text-lg pr-2.5 pl-2.5`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold}]}>New Tasks</Text>
                    <AllTasks />
                  </Col>
                </Row>
              </View>

              <View style={tw`w-full`}>
                <Row size={12} style={tw`justify-between`}>
                  <Col style={[notMobile ? tw`mb-14 pr-4` : tw`mb-3 pr-0`, { width: calculateWidth(12, 8, 8) }]}>
                    <Text style={[tw`mb-5 text-lg pr-2.5 pl-2.5`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold}]}>Transactions</Text>
                    <Transactions screenType="Dashboard" />
                  </Col>

                  <Col style={[notMobile ? tw`mb-14 pl-4` : tw`mb-3 pl-0`, { width: calculateWidth(12, 4, 4) }]}>
                    <Text style={[tw`mb-5 text-lg pr-2.5 pl-2.5`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold}]}>Guide</Text>
                    <Guide />
                  </Col>
                </Row>
              </View>

              {/* <News /> */}
            </>
          )}
        </ScrollView>
      </ContainerWrapper>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  boxShadow: {
    boxShadow: '4px 3px 19px 0px rgba(189, 193, 209, 0.30)',
  },
  darkBoxshadow: {
    boxShadow: 'none',
  },
});