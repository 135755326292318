import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, Text, View, Dimensions, Platform, ImageBackground } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import ContainerWrapper from '../components/containerWrapper';
import { Column as Col, Row } from 'react-native-flexbox-grid';
import tw from 'twrnc';
import { colors } from '../config/theme';
import { ThemeContext } from '../contexts/themeContext';
import { useWeb3ModalTheme, useWeb3Modal, Web3Button } from '@web3modal/react';
import { useAccount, useSignMessage, useDisconnect } from 'wagmi';
import { authTokenProvider } from '../services/authTokenProvider';
import { authService } from '../services/authService';
import { userApiService } from '../services/userApiService';
import { modelStore, modelStoreNameEnum } from '../services/modelStore';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { StackActions, NavigationActions } from 'react-navigation';
import {
  useFonts,
  Inter_300Light,
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
  Inter_700Bold,
} from '@expo-google-fonts/inter';
import { Ionicons } from '@expo/vector-icons';
import Button from '../components/button';
import MenuFuelLogo from '../assets/images/menuFuelLogo';

function Login() {

  let [fontsLoaded] = useFonts({
    Inter_300Light,
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
  });

  // Responsive column grid
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width);

  useEffect(() => {
    const handleWindowResize = ({ window }) => {
      setWindowWidth(window.width);
    };

    Dimensions.addEventListener('change', handleWindowResize);

    return () => {
      Dimensions.removeEventListener('change', handleWindowResize);
    };
  }, []);

  const calculateWidth = (sm, md, lg) => {
    let columns = 12; // Default column number for extra small devices

    if (windowWidth >= 320 && windowWidth < 1200) {
      columns = sm;
    } else if (windowWidth >= 1200 && windowWidth < 1230) {
      columns = md;
    } else if (windowWidth >= 1230) {
      columns = lg;
    }

    // Calculate percentage (i.e., '4 columns' out of '12 columns' is '33.33%')
    return `${(columns / 12) * 100}%`;
  };

  const notMobile = windowWidth >= 1200; // Not mobile devices
  const isWeb = Platform.OS === 'web'; // Check if it's web
  const { theme } = useContext(ThemeContext);
  const activeColors = colors[theme.mode];
  const { setTheme } = useWeb3ModalTheme(); // To customize web3modal
  const { address, isConnected, status, isDisconnected } = useAccount();
  const { signMessageAsync } = useSignMessage();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [user, setUser] = useState(null);
  const navigation = useNavigation(); // Initialize navigation
  const { disconnectAsync } = useDisconnect();
  const { open } = useWeb3Modal()
  var authToken = authTokenProvider.instance.authToken;

  const doAuth = async () => {
    var resp = await authService.instance.auth(address);
    try {
      var sigData = await signMessageAsync({ message: resp.nonce });
    } catch (e) {
      await disconnectAsync();
      return;
    }
    resp = await authService.instance.verify(address, sigData);
    authTokenProvider.instance.set(resp);
    modelStore.instance.setData(
      modelStoreNameEnum.User,
      await userApiService.instance.getUser(forceRefresh)
    );
  };

  const resetApp = (navigation) => {
    const resetAction = StackActions.reset({
      index: 0, // Reset the navigation stack to the first screen
      actions: [NavigationActions.navigate({ routeName: 'Dashboard' })], // Navigate to your initial screen
    });

    navigation.dispatch(resetAction); // Dispatch the reset action
  };
  
  useEffect(() => {
    if (isConnected && navigation) {
      //navigation.navigate('Dashboard'); // Navigate to the Dashboard screen
      resetApp(navigation); 
    }
    if (isDisconnected) {
      authTokenProvider.instance.logout();
    }
    if (status != 'connected') return;
    if (address && !authToken) {
      doAuth();
    }
    if (!address) {
      authTokenProvider.instance.logout();
      user = null;
    }
  }, [address, status, isConnected, isDisconnected, navigation]);

  // Set modal theme
  useEffect(() => {
    setTheme({
      themeMode: 'dark',
      themeVariables: {
        '--w3m-font-family': activeColors.interLight,
        '--w3m-accent-color': activeColors.walletBtnColour,
        '--w3m-background-color': activeColors.greyColor,
        '--w3m-logo-image-url': 'https://res.cloudinary.com/doguh0jje/image/upload/v1701102662/Fuel_logo_3D50px50px_b91n0k.png',
        '--w3m-text-medium-regular-size': '0.85rem',
        
      },
    });
  }, []);

  function getCurrentYear() {
    const currentYear = new Date().getFullYear();
    return currentYear;
  }

  const bgImage= {uri: 'https://res.cloudinary.com/doguh0jje/image/upload/v1701041010/Background_Gradient_Login_wiq708.png'};

  return (
    <>
      <View style={[tw`w-full h-full flex-1 justify-center items-center`, { backgroundColor: activeColors.topBarBgColor }]}>
        <ImageBackground source={bgImage} resizeMode="cover" style={[tw`w-full`,{flex: 1}]}>
          <ContainerWrapper height={'100%'}>
            <View style={tw`flex-1 justify-center items-center`}>
              <Row size={12}>
                <Col style={[tw`justify-center items-center`, { width: calculateWidth(12, 12, 12) }]}>
                  <MenuFuelLogo style={[tw`mb-5`, {width: 136, height: 177}]} />
                  <Text style={[tw`mb-2 text-center`, notMobile ? tw`text-5xl` : tw`text-3xl`, {color: activeColors.primaryTextColor, fontFamily: activeColors.interBold}]}>
                    Connect Your Wallet to
                  </Text>
                  <Text style={[tw`mb-5 text-center`, notMobile ? tw`text-5xl` : tw`text-3xl`, {color: activeColors.tertiaryTextColor, fontFamily: activeColors.interBold}]}>
                    Get Started
                  </Text>
                  <Text style={[tw`mb-5 text-base text-center`, {color: activeColors.primaryTextColor, fontFamily: activeColors.interRegular}]}>
                    Whether you're a developer ready to tackle funded Github issues <br />
                    or a funder aiming to accelerate issue resolution, connect <br />
                    your wallet and dive in!
                  </Text>
                  <View style={styles.buttonContainer}>
                    {/* <Button title={isConnected ? `${address.slice(0, 8)}...${address.slice(-8)}` : "Connect"}
                      icon={isConnected ? <Ionicons name="person-circle-sharp" size={18} /> : '' }
                      buttonStyle={[tw`items-center justify-center h-12 w-56 mt-3 mb-8`, { backgroundColor: activeColors.walletBtnColour }]}
                      buttonTextStyle = {[{ color: activeColors.whiteColor, fontFamily: activeColors.interRegular}, notMobile ? tw`text-base` : tw`text-sm`]}
                      iconStyle = {[tw`mr-1`,{ color: activeColors.whiteColor}]}
                      onClick={() => open()}
                    /> */}
                    <Web3Button block icon='false'/>
                  </View>
                  
                </Col>
                <Col style={[tw`justify-center items-center`, { width: calculateWidth(12, 12, 12) }]}>
                  <View style={tw`flex-1 mt-20 w-full justify-center items-center`}>
                    <Row size={12}>
                      <Col style={tw`justify-center items-center`}>
                        <Text style={[tw`text-sm text-center`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interRegular }]}>
                          <MaterialCommunityIcons name="copyright" size={16} color={activeColors.primaryTextColor} /> {getCurrentYear()} Fuel Accelerator Inc.
                        </Text>
                      </Col>
                    </Row>
                  </View>
                </Col>
              </Row>
            </View>
          </ContainerWrapper>
        </ImageBackground>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  boxShadow: {
    shadowColor: 'rgba(189, 193, 209, 0.3)',
    shadowOffset: { width: 4, height: 3 },
    shadowOpacity: 1,
    shadowRadius: 19,
  },
  darkBoxshadow: {
    shadowColor: 'transparent',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    shadowRadius: 0,
  },
  subText: {
    fontSize: 10,
  }
});

export default Login;