import {API_BASE_PATH,GITHUB_CLIENTID,BACKEND_BASE_PATH} from '@env'
import { userApiService } from '../services/userApiService';
import {StatusBar, View, StyleSheet, SafeAreaView } from 'react-native';
import { Column as Col, Row } from 'react-native-flexbox-grid';
import { Web3Button } from '@web3modal/react'
import { useEffect, useState } from 'react'
import { useAccount, useBalance, useNetwork, useSignMessage, useConnect, useDisconnect } from 'wagmi'
import { authTokenProvider } from '../services/authTokenProvider';
import { authService } from '../services/authService';
import { walletApiService } from '../services/walletApiService';
import { taskApiService } from '../services/taskApiService';
import { Card, Text } from 'react-native-paper';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { cryptoCoinApiService } from '../services/cryptoCoinApiService';
import { webhookService, webhookTypeEnum } from '../services/webhookService';
import { modelStore, modelStoreNameEnum } from '../services/modelStore';
import { tokenApiService } from '../services/tokenService';

function BackendTest(){
    const { disconnectAsync  } = useDisconnect();
    const { address } = useAccount()
    const queryParams = new URLSearchParams(window.location.search);
    var githubAuth = queryParams.get("code");
    var authToken = authTokenProvider.instance.authToken;
    var [user, setUser] = useState(null);
    var [vault, setVault] = useState(null);
    var subOwnerName = "backendTest";

    useEffect(() => {
        modelStore.instance.subscribe(subOwnerName, modelStoreNameEnum.User, (usr) => {
            setUser(usr);
        });
        modelStore.instance.subscribe(subOwnerName, modelStoreNameEnum.Vault, (vault) => {
            setVault(vault);
        });
        modelStore.instance.subscribe(subOwnerName, modelStoreNameEnum.Listings, (listings) => {
            setListingResults(listings);
        });
        modelStore.instance.subscribe(subOwnerName, modelStoreNameEnum.FuelToken, (fuelToken) => {
            console.log(JSON.stringify(fuelToken));
            setFuelTokenResult(fuelToken);
        });
    }, [])

    const getUser = async (forceRefresh = false) => {
        modelStore.instance.setData(modelStoreNameEnum.User, await userApiService.instance.getUser(forceRefresh));
    }

    const connectGithubBackend = async (code) => {
        await userApiService.instance.connectConnector("github", code); 
        getUser(true);
        location.href = window.location.origin;
    }
    useEffect(() => {
        if (githubAuth){
            connectGithubBackend(githubAuth);
        }
    }, [githubAuth])
    const disconnectGithub = async () => {
        await userApiService.instance.disconnectConnector("github");
        getUser(true);
    }
    const connectGithub = () => {
        var bp = window.location.origin;
        console.log(GITHUB_CLIENTID)
        location.href = `https://github.com/login/oauth/authorize?scope=user&client_id=${GITHUB_CLIENTID}&redirect_uri=${bp}`;
    }
    const logout = async() => {
        await disconnectAsync();
        authTokenProvider.instance.logout();
        user.actions.set(null);
    }
    
    const [isGithubConnected, setGithubConnected] = useState(false);
    var [supportedAssets, setSupportedAssets] = useState([]);
    var [selectedAsset, setSelectedAsset] = useState(null);
    var [externalWalletId, setExternalWalletId] = useState(null);
    var [selectedCoin, setSelectedCoin] = useState(null);
    var [assetWallet, setAssetWallet] = useState(null);
    var [assetMetadata, setAssetMetadata] = useState({});
    const getVault = async () => {
        setSupportedAssets(await walletApiService.instance.getSupportedAssets());
        setVault(await walletApiService.instance.getVault());
    }
    const purchaseFuelToken = async () => {
        await tokenApiService.instance.purchase(purchaseFuelTokenQty);
        modelStore.instance.setData(modelStoreNameEnum.FuelToken, (await tokenApiService.instance.get()));
    }
    const triggerDepositFlow = async () => {
        if (!selectedAsset){
            alert("Select an asset");
            return;
        }
        try{
            var res = await walletApiService.instance.getWalletAddress(selectedAsset);
            setAssetWallet(res);
        }
        catch{
            alert("This asset is not supported, please select another one");
        }
    }
    useEffect(() => {
        if(selectedAsset){
            triggerDepositFlow()
        }
    }, [selectedAsset]);
    const loadAllData = async () => {
        getVault();
        webhookService.instance.subscribe(subOwnerName, webhookTypeEnum.TransactionUpdate, (msg) => {
            getVault();
            getMtvClaimed();
            getTransactions();
        })
        console.log("Sending test msg to websocket")
        webhookService.instance.sendMessage(webhookTypeEnum.Test, "my test msg");
        webhookService.instance.subscribe(subOwnerName, webhookTypeEnum.Test, (msg) => {
            console.log("Received test message from websocket: " + msg);
        });
    }
    const getImgUrlInternal = async (coinSymbol) => {
        var metadata = await cryptoCoinApiService.instance.getMetadata(coinSymbol);
        if (!metadata.data) return '';
        var s = coinSymbol.split(",");
        for (var a of s){
            if (!metadata.data[a]) continue;
            var res = metadata.data[a];
            assetMetadata[a] = res;
        }
        setAssetMetadata(assetMetadata);
    }
    const triggerImgUrl = () => {
        if (!vault || !vault.assets) return;
        var symbols = [];
        for (var a of vault.assets){
            symbols.push(a.coinSymbol);
        }
        getImgUrlInternal(symbols.join(","));
    }
    useEffect(() => {
        triggerImgUrl();
    },[vault]);
    const getUsdPrice = (asset) => {
        if (!listingResults) return;
        var sa = supportedAssets.find(q => q.coinSymbol == asset.coinSymbol);
        if (!sa) return;
        var sn = sa.supportedNetworks.find(q => q.id == asset.id);
        if (!sn) return;
        // start with address
        var fromAddress = listingResults.find(q => q.platform && q.platform.token_address == sn.contractAddress)
        if (fromAddress) return +fromAddress.quote.USD.price;

        var f = listingResults.find(q => q.symbol.toLowerCase() == asset.coinSymbol.toLowerCase());
        if (!f) return null;
        return +f.quote.USD.price;
    }
    useEffect(() => {
        if (!user) return;
        var githubCon = (user.connectors.find(t => t.connectorType == 0));
        setGithubConnected(githubCon);
        loadAllData();

        var res = authTokenProvider.instance.isMockingUser();
        if (res){
            SetIsMockingUser(res);
        }
    }, [user])

    var [mockUserWalletId, SetMockUserWalletId] = useState(null);
    var [isMockingUser, SetIsMockingUser] = useState(false);
    var [loadingMock, SetLoadingMock] = useState(false);

    const mockUser = async () => {
        if (!mockUserWalletId || loadingMock) return;
        SetLoadingMock(true)
        var resp = await authService.instance.mockUser(mockUserWalletId);
        authTokenProvider.instance.setAsMockUser(resp);
        location.reload();
    }
    const stopMockUser = async() =>{
        if (loadingMock) return;
        SetLoadingMock(true)
        authTokenProvider.instance.revertMockUser();
        location.reload();
    }

    var [taskStatus, setTaskStatus] = useState(null);
    var [listingResults, setListingResults] = useState([]);
    var [fuelToken, setFuelTokenResult] = useState({});
    var [exploreList, setExploreList] = useState({});
    var [activity, setActivity] = useState({});
    var [estimate, setEstimate] = useState(null);
    var [repoId, setRepoId] = useState('617228488');
    var [purchaseFuelTokenQty, setPurchaseFuelTokenQty] = useState(0);
    var [issueId, setIssueId] = useState('22');
    var [assetId, setAssetId] = useState('');
    var [amount, setAmount] = useState(0);
    var [pageExplore, setPageExplore] = useState(0);
    var [pageActivity, setPageActivity] = useState(0);

    const getExplore = async() => {
        var data = await taskApiService.instance.listExplore("value", "desc", 4, pageExplore);
        setExploreList(data);
    }
    const nextPageExplore = async() => {
        setPageExplore(pageExplore+1)
        await getExplore()
    }
    const getActivity = async() => {
        var data = await taskApiService.instance.listActivity(4, pageActivity, false);
        setActivity(data);
    }
    const getActivityAsFunder = async() => {
        var data = await taskApiService.instance.listActivity(4, pageActivity, true);
        setActivity(data);
    }
    const getTaskStatus = async() => {
        try{
            var res = await taskApiService.instance.getTaskStatus(+repoId, +issueId);
            var res2 = await taskApiService.instance.getTaskStatusArr([
                {RepositoryId: +repoId, IssueId: +issueId}
            ]);
            setTaskStatus(res);
        }
        catch(e){
            if (e.response.status == 404){
                alert('not funded');
            }
            else{
                alert("error: " + e.Message)
            }
        }
    }
    const getTaskSubmissions = async() => {
        try{
            var res = await taskApiService.instance.listTaskSubmissions(+repoId, +issueId);
        }
        catch(e){
            if (e.response.status == 404){
                alert('no submissions');
            }
            else{
                alert("error: " + e.Message)
            }
        }
    }
    const triggerWatch = () => {
        taskApiService.instance.triggerWatch();
    }
    const getEstimate = async() => {
        setEstimate(await taskApiService.instance.getEstimate(assetId));
    }
    const getEstimateWithdraw = async() => {
        setEstimate(await taskApiService.instance.getEstimate(assetId, false));
    }
    const transferToFund = async() => {
        try{
            var res = await walletApiService.instance.transferToMtv(assetId, amount)
        }
        catch(e){
            //alert("error: " + e.Message)
        }
    }
    const withdrawToExternal = async() => {
        try{
            var res = await walletApiService.instance.withdraw(assetId, amount, externalWalletId)
        }
        catch(e){
            alert("error: " + e.Message)
        }
    }
    const fund = async() => {
        try{
            var res = await taskApiService.instance.fund(assetId, amount, repoId, issueId)
        }
        catch(e){
            alert("error: " + e.Message)
        }
    }

    var [mtvClaimed, setMtvClaimed] = useState(null);
    var [assetId, setAssetId] = useState('');
    var [amount, setAmount] = useState(0);

    const getMtvClaimed = async () => {
    }
    
    useEffect(() => {
        if (!authToken) return;
        getMtvClaimed();
    }, [address]);

    var [transactions, setTransactions] = useState(null);
    const getTransactions = async () => {
        setTransactions(await walletApiService.instance.getTransactions());
    }

    useEffect(() => {
        if (!authToken) return;
        getTransactions();
    }, [address]);

    return (
        <View style={{marginTop:'1em', paddingLeft: '5rem', paddingRight: '5rem', paddingBottom: '5rem', overflowY:'scroll', height:'100%'}}>
            <Row>
                <Col right>
                    <Web3Button block color='#6A727C' />
                </Col>
            </Row>
            <Row style={{marginTop:'1em'}}>
                <Text>
                    { <Text>API Path is {API_BASE_PATH}</Text> }
                    {user && (
                        <div>
                            <Text>Logged in</Text>
                            {isGithubConnected && (
                                <div>
                                    <span>Github connected</span>
                                    <button onClick={disconnectGithub}>Disconnect github</button>
                                </div>
                            )}
                            {!isGithubConnected && (
                                <div>
                                    <button onClick={connectGithub}>Connect github</button>
                                </div>
                            )}
                            <button onClick={logout}>Logout</button>
                        </div>
                    )}
                </Text>
            </Row>
            {user && (
            <View>
                <Row>
                    <View>
                        {isMockingUser && user && (
                            <Row>
                                <h1 style={{color: 'red'}}>Mocking USER {user.id}</h1>
                                <button disabled={loadingMock} onClick={stopMockUser} style={{marginLeft:'1em'}}>Stop mock</button>
                            </Row>
                        )}
                        {!isMockingUser && (
                            <View>
                                <Row>
                                    <h3>Mock user</h3>
                                </Row>
                                <Row>
                                    <input style={{width:'50vh'}} type='text' onChange={e => SetMockUserWalletId(e.target.value)} value={mockUserWalletId} placeholder='wallet id'></input>
                                    <button disabled={!mockUserWalletId || loadingMock} onClick={mockUser} style={{marginLeft:'1em'}}>Mock</button>
                                </Row>
                            </View>
                        )}
                    </View>
                </Row>
                <Row xs={12} lg={12} xl={8}>
                    <View style={styles.contentContainer}>
                        <View style={styles.headerMargin}>
                            <Text style={styles.textHeader}>Wallet Overview</Text> 
                            <button style={{width:'30%'}} onClick={getVault}>Refresh</button>   
                        </View>
                        <View elevation={2} style={styles.walletCard}> 
                            <View>
                                <Row>
                                    <Col style={{marginBottom:'1em'}}>
                                        <Row>
                                            <Col>
                                                <select onChange={e => { setAssetWallet(null); setSelectedAsset(null); setSelectedCoin(JSON.parse(e.target.value)); }} >
                                                    <option>Select an asset to deposit</option>
                                                    {supportedAssets && supportedAssets.length > 0 &&
                                                    supportedAssets.map((a,indexa)=>{return (
                                                        <option key={indexa} value={JSON.stringify(a)}>
                                                            {a.coinSymbol}
                                                        </option>
                                                    )})}
                                                </select>
                                            </Col>
                                        </Row>
                                        {selectedCoin && (
                                            <Row>
                                                <Col>
                                                    <select onChange={e => { setAssetWallet(null); setSelectedAsset(JSON.parse(e.target.value).id); }} >
                                                        <option>Select a network</option>
                                                        {selectedCoin && selectedCoin.supportedNetworks &&
                                                        selectedCoin.supportedNetworks.map((a,indexa)=>{return (
                                                            <option key={indexa} value={JSON.stringify(a)}>
                                                                {a.nativeAsset} ({a.id})
                                                            </option>
                                                        )})}
                                                    </select>
                                                </Col>
                                            </Row>
                                        )}
                                        <Row>
                                            {assetWallet && (
                                                <div>
                                                    <span>Send a deposit of {selectedAsset} to address {assetWallet.address}</span>
                                                </div>
                                            )}
                                        </Row>
                                    </Col>
                                </Row>
                                {vault && vault.assets && vault.assets.map((a, indexa) => {return (
                                        <Row key={indexa}>
                                            <View>
                                                <Row>
                                                    <Col xs={3} lg={3} xl={3}>
                                                    <Row>
                                                        <Col xs={6} style={styles.walletOverviewCoinContainer}>
                                                        </Col>
                                                        <Col style={styles.display}>
                                                            <Text>
                                                            <span style={styles.walletOverviewTextBoldT}>
                                                                {a.id} | TOtal: {a.total.toFixed(6)}
                                                            </span>
                                                            <span style={styles.walletOverviewTextBoldT}>
                                                                {a.id} | Locked: {a.locked.toFixed(6)}
                                                            </span>
                                                            <span style={styles.walletOverviewTextBoldT}>
                                                                {a.id} | Avail: {a.available.toFixed(6)}
                                                            </span>
                                                            {(assetMetadata[a.coinSymbol] && !assetMetadata[a.coinSymbol].unknown) && 
                                                                <img style={{width:'64px',height:'64px'}} src={assetMetadata[a.coinSymbol].logo}></img>
                                                            }
                                                            <span>USD ${getUsdPrice(a)}</span>
                                                            </Text>
                                                        </Col>
                                                    </Row>
                                                    </Col>
                                                </Row>
                                                <hr/>
                                            </View>
                                        </Row>
                                )})}
                            </View>
                        </View>
                    </View>
                </Row>
                </View>
            )}
            {user && (
                <>
                    <View style={styles.headerMargin}>
                        <Text style={styles.textHeader}>Explore</Text> 
                        <View elevation={2}>
                            {exploreList && exploreList.results && exploreList.results.map((t, i) => {return (
                                <View key={i}>
                                        <Text>{t.fuelTaskId} | {t.lastUpdated} | Submissions: {t.nbOfSubmissions} | ${t.amountUsd.toFixed(5)} USDT</Text>
                                        {t.submissions && t.submissions.map((s, k) => {return (
                                            <View key={k}>
                                                    <Text>       Submission: ID #{s.number} {s.author} | {s.createdAt} | {s.state}</Text>
                                            </View>
                                        )})}
                                </View>
                            )})}
                        </View>
                    </View>
                    <View elevation={2}>
                        <View>
                            <Col>
                            <button onClick={getExplore} style={{marginLeft:'1em'}}>Explore</button>
                            </Col>
                            <Col>
                            <button onClick={nextPageExplore} style={{marginLeft:'1em'}}>Next page</button>
                            </Col>
                        </View>
                    </View>
                    <View style={styles.headerMargin}>
                        <Text style={styles.textHeader}>Activity</Text> 
                        <View elevation={2}>
                            {activity && activity.results && activity.results.map((t, i) => {return (
                                <View key={i}>
                                        <Text>{t.metadata.fuelTaskId} | {t.metadata.lastUpdated} | AsFunder: {t.asFunder} | Submissions: {t.metadata.nbOfSubmissions} | ${t.metadata.amountUsd.toFixed(5)} USDT</Text>
                                        {t.metadata.submissions && t.metadata.submissions.map((s, k) => {return (
                                            <View key={k}>
                                                    <Text>       Submission: ID #{s.number} {s.author} | {s.createdAt} | {s.state}</Text>
                                            </View>
                                        )})}
                                        <Text>Fundings:</Text>
                                        {t.fundingVault && t.fundingVault.assets.map((a, i) => {return (
                                            <View>
                                                <Text>        {a.available} {a.assetId}</Text>
                                            </View>
                                        )})}
                                </View>
                            )})}
                        </View>
                    </View>
                    <View elevation={2}>
                        <View>
                            <Col>
                            <button onClick={getActivityAsFunder} style={{marginLeft:'1em'}}>Activity as funder</button>
                            </Col>
                            <Col>
                            <button onClick={getActivity} style={{marginLeft:'1em'}}>Activity as dev</button>
                            </Col>
                        </View>
                    </View>
                    <View style={styles.headerMargin}>
                        <Text style={styles.textHeader}>Task status</Text> 
                    </View>
                    <View elevation={2}>
                        <View>
                            <Row>
                                <Col>
                                    <input type='number' onChange={e => setRepoId(+e.target.value)} value={repoId} placeholder='repository id'></input>
                                </Col> 
                                <Col>
                                    <input type='number' onChange={e => setIssueId(+e.target.value)} value={issueId} style={{marginLeft:'1em'}} placeholder='issue id'></input>
                                </Col>
                                <Col>
                                    <button disabled={!repoId || !issueId} onClick={getTaskStatus} style={{marginLeft:'1em'}}>Status</button>
                                </Col>
                                <Col>
                                    <button disabled={!repoId || !issueId} onClick={getTaskSubmissions} style={{marginLeft:'1em'}}>Submissions</button>
                                </Col>
                            </Row>
                            <Row style={{marginTop:'1em'}}>
                                <Col>
                                    <input name='taskAssetId' type='text' onChange={e => setAssetId(e.target.value)} value={assetId} placeholder='asset id'></input>
                                </Col>
                                <Col>
                                    <input type='number' onChange={e => setAmount(+e.target.value)} value={amount} style={{marginLeft:'1em'}} placeholder='amount'></input>
                                </Col>
                                <Col>
                                    <button disabled={!assetId} onClick={getEstimate} style={{marginLeft:'1em'}}>Estimate</button>
                                </Col>
                                <Col>
                                    <button disabled={!assetId} onClick={getEstimateWithdraw} style={{marginLeft:'1em'}}>Estimate withdraw</button>
                                </Col>
                                <Col>
                                    <button disabled={!assetId || !amount} onClick={fund} style={{marginLeft:'1em'}}>Fund</button>
                                </Col>
                                <Col>
                                    <input name='externalWalletId' type='text' onChange={e => setExternalWalletId(e.target.value)} value={externalWalletId} placeholder='External wallet id'></input>
                                </Col>
                                <Col>
                                    <button disabled={!assetId || !externalWalletId} onClick={withdrawToExternal} style={{marginLeft:'1em'}}>Withdraw to external (metamask)</button>
                                </Col>
                                <Col>
                                    <button onClick={triggerWatch} style={{marginLeft:'1em'}}>Watch</button>
                                </Col>
                            </Row>
                            {estimate && (
                                <div>
                                    <Row>
                                        <Text>Will cost you {estimate.fuelFees.fuelTokensFee} {estimate.fuelFees.fuelTokenAssetId}</Text>
                                    </Row>
                                </div>
                            )}
                            {taskStatus && 
                                <div>               
                                    <Row>         
                                    <Text>{taskStatus.fuelTaskResponse.title} ({taskStatus.fuelTaskResponse.id}) ({taskStatus.fuelTaskResponse.description})</Text>
                                    </Row>    
                                    <Row>
                                    <Text>Created: {taskStatus.fuelTaskResponse.createdAt}</Text>
                                    </Row>
                                    <Row>
                                    <Text>Status: {taskStatus.fuelTaskResponse.statusStr}</Text>
                                    </Row>
                                    <Row>
                                    <Text>Closed by: {taskStatus.fuelTaskResponse.closerLogin}</Text>
                                    </Row>
                                    <Row>
                                    <Text>Closed on: {taskStatus.fuelTaskResponse.closedAt}</Text>
                                    </Row>
                                    <View>
                                        <Text>Assets</Text>
                                        {taskStatus.fuelTaskResponse.vaultAccount.assets.map((a,indexa) => {return (
                                            <View key={indexa}>
                                                <>
                                                    <Row><Text>{a.assetId}</Text></Row>
                                                    <Row><Text>Available: ({a.available})</Text></Row>
                                                    <Row><Text>Pending: ({a.pending})</Text></Row>
                                                    <Row><Text>Locked: ({a.locked})</Text></Row>
                                                </>
                                            </View>
                                        )})}
                                    </View>
                                </div>
                            }
                        </View>
                    </View>
                </>
            )}
            {user && (
                <>
                    <Col xs={12} lg={12} xl={8} style={{marginTop:'1em'}}>
                        <View>
                        <   View>
                                <Text style={styles.textHeader}>Transactions</Text>    
                                <button onClick={getTransactions} style={{width:'10%'}}>Refresh</button>
                            </View>
                            <View elevation={2}>
                                {transactions && transactions.map((t, i) => {return (
                                    <View key={i}>
                                         <Text>{t.created} | {t.transactionTypeStr} {t.isIncoming && <Text>RECEIVED </Text>}{!t.isIncoming && <Text>SENT </Text>}{t.assetId} {t.amount && t.amount.toFixed(5)} {t.amountUsd && ('$' + t.amountUsd.toFixed(2) + ' USD')} ({t.fuelFeeInfo ? t.fuelFeeInfo.feePercentage*100 : 0}% {t.fuelFeeInfo ? t.fuelFeeInfo.feeAbsoluteAmount : 0}) ({t.gasFee ? t.gasFee.amount : 'no GAS fee'}) ({t.fuelTask ? t.fuelTask.id : ''})</Text>
                                    </View>
                                )})}
                            </View>
                        </View>
                    </Col>
                </>
            )}
        </View>
    )
}

export default BackendTest;

const styles = StyleSheet.create ({
    contentContainer : {
        marginTop: 50,
    },
    walletCard: {
        height: '100%',
        padding: 10,
    },
    textHeader: {
        fontFamily: 'Inter_600SemiBold',
        fontSize: 15,
        color: '#6A727C',
    },
    mobiletextHeader: {
        fontFamily: 'Inter_600SemiBold',
        fontSize: 13,
        color: '#6A727C',
    },
    headerMargin: {
        marginBottom: 10,
    },
    walletOverviewValueAmountContainer: {
        width: '36.666667%',
    },
    mobilewalletOverviewValueAmountContainer: {
        width: '100%',
    },
    ipadwalletOverviewValueAmountContainer: {
        width: '50%',
    },
    walletValueText: {
        position: 'relative',
        top: 4,
        fontFamily: 'Inter_500Medium',
        fontSize: 10,
        color: '#B0B7C3',
    },
    moneytaryValue: {
        position: 'relative',
        top: 4,
        fontFamily: 'Inter_600SemiBold',
        fontSize: 25,
        color: '#6A727C',
    },
    mobilemoneytaryValue: {
        position: 'relative',
        top: 4,
        fontFamily: 'Inter_600SemiBold',
        fontSize: 18,
        color: '#6A727C',
    },
    ipadmoneytaryValue: {
        position: 'relative',
        top: 4,
        fontFamily: 'Inter_600SemiBold',
        fontSize: 20,
        color: '#6A727C',
    },
    btnTransaction: {
        position: 'relative',
        top: 4,
    },
    mobilebtnTransaction: {
        position: 'relative',
        top: 4,
        fontSize: 10,
    },
    walletOverviewHeadingsMarginTop: {
        marginTop: '25px',
    },
    walletOverviewHeadings: {
        fontSize: 10,
        color: '#6A727C'
    },
    hr: {
        height: 1,
        marginTop: 0,
    },
    walletOverviewCoin: {
        height: 20,
        width: 20,
        borderRadius: '50%',
    },
    walletOverviewCoinContainer: {
        paddingRight: 0,
        width: '20%',
    },
    walletOverviewCoinPadding:{
        padding: 5,
        width: 30,
        height: 30,
        borderRadius: '50%',
    },
    walletOverviewTextBold: {
        position: 'relative',
        fontFamily: 'Inter_600SemiBold',
        fontSize: 12,
        color: '#6A727C',
    },
    mobilewalletOverviewTextBold: {
        position: 'relative',
        fontFamily: 'Inter_600SemiBold',
        fontSize: 10,
        color: '#6A727C',
    },
    walletOverviewTextBoldT: {
        position: 'relative',
        top: -4,
        fontFamily: 'Inter_600SemiBold',
        fontSize: 12,
        color: '#6A727C',
    },
    mobilewalletOverviewTextBoldT: {
        position: 'relative',
        top: 0,
        fontFamily: 'Inter_600SemiBold',
        fontSize: 10,
        color: '#6A727C',
    },
    walletOverviewText: {
        position: 'relative',
        top: -15,
        fontFamily: 'Inter_500Medium',
        fontSize: 10,
        textTransform: 'uppercase',
        color: '#B0B7C3',
    },
    mobilewalletOverviewText: {
        position: 'relative',
        top: -11,
        fontFamily: 'Inter_500Medium',
        fontSize: 8,
        textTransform: 'uppercase',
        color: '#B0B7C3',
    },
    walletOverviewProgress: {
        position: 'relative',
        top: 9,
    },
    mobilewalletOverviewProgress: {
        position: 'relative',
        top: 0,
    },
    walletOverviewAllocation: {
        width: 64,
        paddingRight: 0,
    },
    mobilewalletOverviewAllocation: {
        width: '100%',
        paddingRight: 0,
    },
    walletOverviewColPl0: {
        paddingLeft: 0,
    },
    display: {
        display: 'block',
    },
    mobileDisplay: {
        display: 'none',
    }
});