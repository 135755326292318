import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { walletApiService } from '../services/walletApiService';
import { cryptoCoinApiService } from '../services/cryptoCoinApiService';
import { taskApiService } from '../services/taskApiService';
import { modelStore, modelStoreNameEnum } from '../services/modelStore';
import { useNavigation, useRoute } from '@react-navigation/native';
import { View, StyleSheet, Dimensions, FlatList, TextInput, TouchableOpacity, ActivityIndicator } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Stepper } from './stepper';
import { useFonts, Inter_500Medium, Inter_600SemiBold, Inter_700Bold, } from '@expo-google-fonts/inter';
import { Card, Text } from 'react-native-paper';
import { Column as Col, Row } from 'react-native-flexbox-grid';
import { colors } from '../config/theme';
import { ThemeContext } from '../contexts/themeContext';
import { Provider as PaperProvider } from 'react-native-paper';
import tw from 'twrnc';
import Clipboard from '@react-native-clipboard/clipboard';
import QRCode from 'react-native-qrcode-svg';
import FuelToken from '../assets/images/fuelToken';
import Button from './button';
import Hr from './hr';
import LinearProgressBar from './linearProgress';
import TipsLightMode from '../assets/images/tipsLightMode';
import TipsDarkMode from '../assets/images/tipsDarkMode';
import WithdrawIconDarkMode from '../assets/images/withdrawIconDarkMode';
import WithdrawIconLightMode from '../assets/images/withdrawIconLightMode';
import DepositIconDarkMode from '../assets/images/depositIconDarkMode';
import DepositIconLightMode from '../assets/images/depositIconLightMode';
import CustomPicker from './customPicker';
import { useIsFocused } from "@react-navigation/native";

export default function WalletOverview({ screenType, columnSizes, depositWithdrawColumsSizes}) {
  let [fontsLoaded] = useFonts({
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
  });

  // Logic to store previous screen
  const [lastVisitedRoute, setLastVisitedRoute] = useState('Dashboard'); // Default route

  // In your component or effect handling the navigation
  useEffect(() => {
    const storedLastVisitedRoute = localStorage.getItem('lastVisitedRoute');
    setLastVisitedRoute(storedLastVisitedRoute || 'Dashboard');
  }, []);


  // Check if the current screen is the wallet screen
  const isWalletScreen = screenType === 'My Wallet';

  const navigation = useNavigation();
  const route = useRoute();
  const { theme } = useContext(ThemeContext);
  const activeColors = colors[theme.mode];
  const [user, setUser] = useState(null);
  const [supportedAssets, setSupportedAssets] = useState([]);
  const [vault, setVault] = useState(null);
  const [listingResults, setListingResults] = useState([]);
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [destinationAddress, setDestinationAddress] = useState('');
  const [withdrawCoinSelected, setWithdrawCoinSelected] = useState('');
  const [assetId, setAssetId] = useState('');
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [assetWallet, setAssetWallet] = useState(null);
  const [isWithdrawConfirmed, setIsWithdrawConfirmed] = useState(false);
  const [isWithdrawalProcessing, setIsWithdrawalProcessing] = useState('');
  const [isWithdrawalProcessed, setIsWithdrawalProcessed] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [estimate, setEstimate] = useState(null);
  const [estimateToken, setEstimateToken] = useState(null);
  const [assetMetadata, setAssetMetadata] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isWithdrawLoading, setIsWithdrawLoading] = useState(false);

  var subOwnerName = "mainWalletOverview";

  const isFocused = useIsFocused();
  useEffect(() => {
    modelStore.instance.subscribe(subOwnerName, modelStoreNameEnum.User, (usr) => {
        setUser(usr);
    });

    modelStore.instance.subscribe(subOwnerName, modelStoreNameEnum.Metadata, (meta) => {
      setAssetMetadata(meta);
    });

    modelStore.instance.subscribe(subOwnerName, modelStoreNameEnum.SupportedAssets, (sa) => {
      setSupportedAssets(sa);
    });

    modelStore.instance.subscribe(subOwnerName, modelStoreNameEnum.Vault, (vault) => {
        setVault(vault);
    });

    modelStore.instance.subscribe(subOwnerName, modelStoreNameEnum.Listings, (listings) => {
        setListingResults(listings);
    });
  }, []);
  useEffect(() => {
    if (!isFocused) return;
    modelStore.instance.reloadData(modelStoreNameEnum.Vault);
  }, [isFocused])

  // Responsive column grid
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width);

  useEffect(() => {
    const handleWindowResize = ({ window }) => {
      setWindowWidth(window.width);
    };

    Dimensions.addEventListener('change', handleWindowResize);

    return () => {
      Dimensions.removeEventListener('change', handleWindowResize);
    };
  }, []);

  const calculateWidth = (sm, md, lg) => {
    let columns = 12; // Default column number for extra small devices

    if (windowWidth >= 320 && windowWidth < 1200) {
      columns = sm;
    } else if (windowWidth >= 1200 && windowWidth < 1230) {
      columns = md;
    } else if (windowWidth >= 1230) {
      columns = lg;
    }

    // Calculate percentage (i.e., '4 columns' out of '12 columns' is '33.33%')
    return `${(columns / 12) * 100}%`;
  };

  const notMobile = windowWidth >= 1200; // Not mobile devices

  // Get Vault and all supported assets
  const triggerDepositFlow = async () => {
    setShowActivityIndicator(true);
    setShowQrCardHolder(true);
    if (!selectedAsset){
      setErrorMessage("Select an asset");
      setShowActivityIndicator(false);
      setShowQrCardHolder(true);
      return;
    }
    try{
      var res = await walletApiService.instance.getWalletAddress(selectedAsset);
      setAssetWallet(res);
      setShowActivityIndicator(false);
      setShowQrCardHolder(false);
    }
    catch{
      setErrorMessage("This asset is not supported, please select another one");
      setShowActivityIndicator(false);
      setShowQrCardHolder(true);
    }
  }

  useEffect(() => {
    if(selectedAsset){
      triggerDepositFlow();
    }
  }, [selectedAsset]);

  // Get USDT value of an asset
  const getUsdPrice = (asset) => {
    if (!listingResults) return;
    var sa = supportedAssets.find(q => q.coinSymbol == asset.coinSymbol);
    if (!sa) return;
    var sn = sa.supportedNetworks.find(q => q.id == asset.id);
    if (!sn) return;
    // start with address
    var fromAddress = listingResults.find(q => q.platform && q.platform.token_address == sn.contractAddress)
    if (fromAddress) return +fromAddress.quote.USD.price;

    var f = listingResults.find(q => q.symbol.toLowerCase() == asset.coinSymbol.toLowerCase());
    if (!f) return null;
    return +f.quote.USD.price;
  }

  // Calculation of total quantity
  const totalQuantity = vault && vault.assets ? vault.assets.reduce((total, a) => total + a.available, 0) : 0;
  // Function to calculate percentage
  const calculatePercentage = (available) => {
    if (totalQuantity === 0) {
      return 0;
    }
    return (available / totalQuantity) * 100;
  };

  const totalValue = vault && vault.assets
  ? vault.assets.reduce((total, asset) => {
      const usdPrice = getUsdPrice(asset);
      const assetValue = isNaN(usdPrice) ? 0 : usdPrice * asset.available;
      return total + assetValue;
    }, 0)
  : 0;


  // Calculate the price percentage based on total value
  const calculatePricePercentage = (price) => {
    if (totalValue === 0) {
      return 0;
    }
    return (price / totalValue) * 100;
  };

  // Pagination variables
  const [currentPage, setCurrentPage] = useState(1);
  const vaultPerPage = screenType === 'Dashboard' ? 4 : 10;
  const paginationRange = screenType === 'Dashboard' ? 5 : 10;

  // Sorting variables
  const [sortingCriteria, setSortingCriteria] = useState('highest'); // Default: 'Sort by'

  const [startPageIndex, setStartPageIndex] = useState(1);
  const [endPageIndex, setEndPageIndex] = useState(
    vault ? Math.min(startPageIndex + paginationRange - 1, Math.ceil(vault.length / vaultPerPage)) : 0
  );

  useEffect(() => {
    if (vault && Array.isArray(vault.assets)) {
      setEndPageIndex(Math.min(startPageIndex + paginationRange - 1, Math.ceil(vault.length / vaultPerPage)));
    }
  }, [vault, startPageIndex, currentPage]);

  const handleSortingChange = (criteria) => {
    setSortingCriteria(criteria);
    setCurrentPage(1); // Reset the current page when sorting criteria changes
    setStartPageIndex(1); // Reset the startPageIndex to 1 when sorting criteria changes
  };

  // Get paginated vault data based on current page
  const getPaginatedVault = () => {
    if (!vault || !Array.isArray(vault.assets)) {
      return []; // Return an empty array or handle the error case appropriately
    }

    let sortedAssets = vault.assets; // Default sorting: no change

    if (sortingCriteria === 'lowest') {
      sortedAssets = vault.assets.slice().sort((a, b) => calculatePricePercentage(getUsdPrice(a) * a.available) - calculatePricePercentage(getUsdPrice(b) * b.available));
    } else if (sortingCriteria === 'highest') {
      sortedAssets = vault.assets.slice().sort((a, b) => calculatePricePercentage(getUsdPrice(b) * b.available) - calculatePricePercentage(getUsdPrice(a) * a.available));
    }

    const startIndex = (currentPage - 1) * vaultPerPage;
    const endIndex = startIndex + vaultPerPage;
    return sortedAssets.slice(startIndex, endIndex);
  };

  //const paginatedVaults = getPaginatedVault();

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    const newStartPageIndex = Math.max(pageNumber - Math.floor(paginationRange / 2), 1);
    let newEndPageIndex = Math.min(newStartPageIndex + paginationRange - 1, Math.ceil(vault.length / vaultPerPage));

    // Adjust the endPageIndex based on the available transactions
    if (newEndPageIndex > Math.ceil(vault.length / vaultPerPage)) {
      newEndPageIndex = Math.ceil(vault.length / vaultPerPage);
    }

    // Adjust the startPageIndex if there are fewer pages than the paginationRange
    if (newEndPageIndex - newStartPageIndex + 1 < paginationRange) {
      const diff = paginationRange - (newEndPageIndex - newStartPageIndex + 1);
      newStartPageIndex = Math.max(newStartPageIndex - diff, 1);
    }

    setStartPageIndex(newStartPageIndex);
    setEndPageIndex(newEndPageIndex);
  };

  useEffect(() => {
    const paginatedVaults = getPaginatedVault();
    setEndPageIndex(
      Math.min(startPageIndex + paginationRange - 1, Math.ceil(paginatedVaults.length / vaultPerPage))
    );
  }, [sortingCriteria, currentPage, vaultPerPage]);

// Add icons for back and next pagination buttons
  const BackIcon = <Ionicons name={"chevron-back-outline"} size={24} style={{ color: activeColors.buttonColor }} />;
  const NextIcon = <Ionicons name={"chevron-forward-outline"} size={24} style={{ color: activeColors.buttonColor }} />;

// Render pagination buttons
  const renderPaginationButtons = () => {
    const buttons = [];
    const maxButtons = Math.min(paginationRange, Math.ceil(sortedAssets.length / vaultPerPage));

    for (let i = startPageIndex; i <= endPageIndex; i++) {
      if (i <= maxButtons) {
        const startIndex = (i - 1) * vaultPerPage;
        const endIndex = startIndex + vaultPerPage;
        const pageVaults = sortedAssets.slice(startIndex, endIndex);

        buttons.push(
          <TouchableOpacity
            key={i}
            onPress={() => handlePageClick(i)}
            style={[
              styles.paginationButton,
              currentPage === i && styles.activePaginationButton,
              pageVaults.length === 0 && styles.disabledPaginationButton, // Disable pagination  button if no transactions on the page
            ]}
            disabled={pageVaults.length === 0} // Disable pagination button if no transactions on the page
          >
            <Text
              style={[
                styles.paginationButtonText,
                currentPage === i && styles.activePaginationButtonText,
                pageVaults.length === 0 && styles.disabledPaginationButtonText, // Disabled button style
              ]}
            >
              {i}
            </Text>
          </TouchableOpacity>
        );
      }
    }

    return buttons;
  };

  
  const { showWithdrawForm: withdrawFormParam } = route.params || {};
  const [showWithdrawForm, setShowWithdrawForm] = useState(withdrawFormParam);
  const { showDepositForm: depositFormParam } = route.params || {};
  const [showDepositForm, setShowDepositForm] = useState(depositFormParam);

  // Withdrawal Form

  const handleWithdrawButtonClick = () => {
    // Check if you're already on the "My Wallet" screen
    if (screenType === 'My Wallet') {
      setShowWithdrawForm(true);
    } else {
      if (lastVisitedRoute === 'My Wallet') {
        setShowWithdrawForm(true);
      } else {
        navigation.navigate('My Wallet', { showWithdrawForm: true });
      }
    }
  };

  // Get estimate
  const handleWithdrawAmountChange = (newWithdrawAmount) => {
    setWithdrawAmount(newWithdrawAmount);
    let numericText = newWithdrawAmount.replace(/[^0-9.]/g, ''); // Keep the numbers and the decimal point
    const decimalPointIndex = numericText.indexOf('.');
    if (decimalPointIndex !== -1) { 
      // If there's more than one '.', keep only the first
      const integerPart = numericText.slice(0, decimalPointIndex);
      let decimalPart = numericText.slice(decimalPointIndex);
      // If there's more than 4 digits after the '.', keep only the first four
      if (decimalPart.length > 5) { 
        decimalPart = decimalPart.slice(0, 5);
      }
      numericText = integerPart + decimalPart;
    }
    setWithdrawAmount(numericText);
    setErrorMessage('');
    setIsWithdrawalProcessed('');
  };

  const getEstimateWithdraw = async () => {
    setIsLoading(true); // Set loading state to true
    if (withdrawAmount !== '' && parseFloat(withdrawAmount) !== 0) {
      try {
        const response = await taskApiService.instance.getEstimate(assetId, false);
        const fuelTokensFee = response.fuelFees.fuelTokensFee; 
        const fuelTokenId = response.fuelFees.fuelTokenAssetId;
        setEstimate(fuelTokensFee); 
        setEstimateToken(fuelTokenId); 
        setIsLoading(false); 
      } catch (error) {
        //console.error('Error:', error);
        setErrorMessage("Could not process gas fee estimation");
        setIsLoading(false);
        setWithdrawAmount('');
        return;
      }
    }
  };

  useEffect(() => {
    getEstimateWithdraw();
  }, [withdrawAmount, withdrawCoinSelected]);
  
  /* 
    Calculate the total amount of available token to withdraw
    and the estimate cost fee
  */
  const availableBalance = vault && vault.assets ? vault.assets.find((a) => a.id === assetId)?.available || 0 : 0;
  const totalAmount = availableBalance + estimate;

  // Withdraw to external wallet
  const withdrawToExternal = async() => {
    try{
      setIsWithdrawLoading(true); // Set isWithdrawalProcessing to true when starting the withdrawal process
      // Withdraw form error handling
      if (!withdrawCoinSelected){
        setErrorMessage("Select an asset");
        setIsWithdrawConfirmed(false);
        setIsWithdrawLoading(false); // Set isWithdrawalProcessing to false when there is error
        return;
      }

      if (!withdrawAmount){
        setErrorMessage("Enter an amount");
        setIsWithdrawConfirmed(false);
        setIsWithdrawLoading(false); // Set isWithdrawalProcessing to false when there is error
        return;
      }

      if (!withdrawAmount || withdrawAmount <= 0) {
        // Fields are empty or amount is not greater than zero
        setErrorMessage("Please ensure the amount is greater than zero.");
        setIsWithdrawConfirmed(false);
        setIsWithdrawLoading(false); // Set isWithdrawalProcessing to false when there is error
        return;
      }

      if (parseFloat(withdrawAmount) > totalAmount) {
        setErrorMessage('You do not have enough to cover the gas fees');
        setIsWithdrawConfirmed(false);
        setIsWithdrawLoading(false); // Set isWithdrawalProcessing to false when there is error
        return;
      }

      if (!destinationAddress) {
        // Fields are empty or amount is not greater than zero
        setErrorMessage("Enter a wallet destination address");
        setIsWithdrawConfirmed(false);
        setIsWithdrawLoading(false); // Set isWithdrawalProcessing to false when there is error
        return;
      }
      
      // Check if fields are empty or amount is not greater than zero
      if (!withdrawCoinSelected || !withdrawAmount || withdrawAmount <= 0 || !destinationAddress) {
        // Fields are empty or amount is not greater than zero
        setErrorMessage("Please fill in all the fields and ensure the amount is greater than zero.");
        setIsWithdrawLoading(false); // Set isWithdrawalProcessing to false when there is error
        setIsWithdrawConfirmed(false);
        return;
      }
      setIsWithdrawConfirmed(true);
      setIsWithdrawalProcessing("We are processing your fund withdrawal to external wallet...");
      var res = await walletApiService.instance.withdraw(withdrawCoinSelected, withdrawAmount, destinationAddress);
      // Set deposit to fuel fields to empty.
      setWithdrawAmount('');
      setAssetId('');
      setDestinationAddress('');
      setErrorMessage('');
      setIsWithdrawalProcessing('');
      setIsWithdrawalProcessed("Your fund withdrawal has been processed");
      setIsWithdrawLoading(false); // Set isWithdrawalProcessing to false when there is error
      }
      catch(e){
        setIsWithdrawalProcessing('');
        setErrorMessage("We could not process request, please make sure you have enough coin to withdraw in your wallet");
        setIsWithdrawLoading(false); // Set isWithdrawalProcessing to false when there is error
        setIsWithdrawConfirmed(false);

      }
  }

  /* Use useEffect to pass withdrawFormParam value will be 
    passed through the navigation  parameter when navigating 
    to the Wallet screen, and the mainWallet component will 
    update accordingly in Wallet screen
  */
  useEffect(() => {
    if (withdrawFormParam) {
      setShowWithdrawForm(true);
      setShowDepositForm(false);
    }
  }, [withdrawFormParam]);

  const handleWithdrawBackButtonClick = () => {
    setShowWithdrawForm(false);
  };

  // Deposit form
  const handleDepositButtonClick = () => {
    // Check if you're already on the "My Wallet" screen
    if (screenType === 'My Wallet') {
      setShowDepositForm(true);
    } else {
      if (lastVisitedRoute === 'My Wallet') {
        setShowDepositForm(true);
      } else {
        navigation.navigate('My Wallet', { showDepositForm: true });
      }
    }
  };

  /* Use useEffect to pass depositFormParam value will be 
    passed through the navigation  parameter when navigating 
    to the Wallet screen, and the mainWallet component will 
    update accordingly in Wallet screen
  */
  useEffect(() => {
    if (depositFormParam) {
      setShowWithdrawForm(false);
      setShowDepositForm(true);
    }
  }, [depositFormParam]);
  
  const handleDepositBackButtonClick = () => {
    setShowDepositForm(false);
  };

  // To copy wallet address with a copied notification
  const [copied, setCopied] = useState(false);
  const [showAddress, setShowAddress] = useState(true);
  const [showEstimate, setShowEstimate] = useState(true);
  const [showActivityIndicator, setShowActivityIndicator]  = useState(true);
  const [showQrCardHolder, setShowQrCardHolder] = useState(true);

  const handleCopyText = () => {
    Clipboard.setString(assetWallet.address);
    setCopied(true);

    // Reset the copied state after 2 seconds
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  // Deposit clear form button
  const handleDepositClear = () => {
    setSelectedAsset('');
    setSelectedCoin('');
    setErrorMessage('');
    setShowAddress(false);
    setShowActivityIndicator(false);
    setShowQrCardHolder(true);
    setCopied(false);
  };

  // Withdraw clear form button
  const handleWithdrawClear = () => {
    setWithdrawAmount('');
    setAssetId('');
    setDestinationAddress('');
    setIsWithdrawalProcessed('');
    setErrorMessage('');
  };

  // Hide deposit to Fuel wallet succesful message after 5 secs
  useEffect(() => {
    if (isWithdrawalProcessed) {
      const timer = setTimeout(() => {
        setIsWithdrawalProcessed(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isWithdrawalProcessed]);

  const tipsData = [
    "Ensure that the cryptocurrency wallet you plan to transfer from is compatible with the selected network.",
    "Before initiating the transfer, carefully verify the deposit address provided matches the address provided.",
    "Consider transaction times: Different cryptocurrencies have varying confirmation times. Bitcoin, for example, typically takes longer than other cryptocurrencies due to its network congestion.",
    "After initiating the transfer, keep an eye on the deposit status in your account. Depending on network congestion, it may take some time for the deposit to be confirmed and reflected in your account balance."
  ];

  // Deposit Form select coin picker
  const itemsList = supportedAssets?.map((asset) => ({
    label: asset.coinSymbol,
    value: asset.coinSymbol, // use coinSymbol as a value
  }));

  const networkList = useMemo(() => {
    // Look up the selected coin object in the supportedAssets array
    const selectedCoinObject = supportedAssets?.find(asset => asset.coinSymbol === selectedCoin);

    if (!selectedCoinObject || !selectedCoinObject.supportedNetworks || selectedCoinObject.supportedNetworks.length === 0) {
      return [];
    }

    setShowAddress(true);
    
    return selectedCoinObject.supportedNetworks.map((network) => ({
      label: network.nativeAsset,
      value: network.id,
    }));
    
  }, [selectedCoin, supportedAssets]); // Add supportedAssets as a dependency to the useMemo hook

  // Replace the button text with loading animation when withdrwa request is being processed
  const buttonTitle = isWithdrawLoading ? (
    <ActivityIndicator color={activeColors.primaryTextColor} size="small" style={tw`items-end`} />
  ) : "Confirm";

  return (
    <PaperProvider>
      <Card elevation={0} style={[tw`mb-2 pt-0.5 pr-2.5 pl-2.5 pb-2`]}>
        <Card.Content style={[tw`rounded-xl`, { backgroundColor: activeColors.cardBgColor }, theme.mode === 'dark' ? styles.darkBoxshadow : styles.boxShadow]}>
          {showWithdrawForm ? (
            <Row size={12} style={tw`relative, top-2`}>
              <Col style={{ width: calculateWidth(12, 5, 5) }}>
                <View style={tw`w-28`}>
                  <Button iconName="chevron-back-outline" title="Withdraw" 
                    onClick={handleWithdrawBackButtonClick} 
                    buttonStyle={[tw`justify-start items-start pr-0 pl-0 mb-3 bg-transparent`]}
                    buttonTextStyle = {{color: activeColors.primaryTextColor}}
                    iconStyle = {{color: activeColors.primaryTextColor}}
                  />
                </View>

                <View style={{ flexDirection: 'row', alignItems: 'flex-start', zIndex: 9000, elevation: 5}}>
                  <Stepper step={1} filled={assetId} />
                  <View style={{ flex: 1 }}> 
                    <Text style={[tw`mb-2 text-xs`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interRegular }]}>Select Coin</Text>
                    <CustomPicker
                      items={vault && vault.assets && vault.assets.length > 0 ? vault.assets.map((a) => ({label: a.id, value: a.id, available: a.available})) : []}
                      activeColors={activeColors}
                      selectedValue={assetId} 
                      onValueChange={(itemValue) => {
                        setAssetId(itemValue);
                        setWithdrawCoinSelected(itemValue); // Check if selected value is not empty
                        setErrorMessage('');
                      }}
                      placeholder="Select Coin"
                    />
                    {assetId && vault && vault.assets.length > 0 && (
                      <Text style={[tw`mt-1 mb-1 text-xs`,{ color: activeColors.secondaryTextColor }]}>
                        Available: {(
                          vault.assets.find((a) => a.id === assetId)?.available || 0
                        ).toFixed(3)}
                      </Text>
                    )}
                  </View>
                </View>

                <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                  <Stepper step={2} filled={assetId && withdrawAmount} />
                   <View style={{ flex: 1 }}> 
                   <Text style={[tw`mb-2 text-xs`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interRegular }]}>Quantity</Text>
                    <TextInput
                      onChangeText={handleWithdrawAmountChange}
                      value={withdrawAmount}
                      style={[
                        { backgroundColor: 'transparent', borderWidth: 1, borderColor: activeColors.textInputOutline, color: activeColors.primaryTextColor },
                        tw`rounded-lg h-12 text-xs mb-2 pl-2`,
                      ]}
                      placeholder="Quantity"
                      placeholderTextColor={activeColors.secondaryTextColor}
                    />
                  </View>
                </View>

                <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                  <Stepper step={3} filled={assetId && withdrawAmount && destinationAddress} />
                    <View style={{ flex: 1 }}>
                      <Text style={[tw`mb-2 text-xs`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interRegular }]}>Destination Address</Text>
                      <TextInput
                        keyboardType="default"
                        value={destinationAddress}
                        onChangeText={text => setDestinationAddress(text)}
                        editable={true}
                        style={[
                          { backgroundColor: 'transparent', borderWidth: 1, borderColor: activeColors.textInputOutline, color: activeColors.primaryTextColor },
                          tw`rounded-lg h-12 text-xs mb-5 pl-2`,
                        ]}
                        placeholder="Destination Address"
                        placeholderTextColor={activeColors.secondaryTextColor}
                    />
                  </View>
                </View>

                <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                  <Stepper step={4} filled={assetId && withdrawAmount && destinationAddress && withdrawAmount > 0 && isWithdrawConfirmed} isLastStep={true} />
                  <View style={{ flex: 1 }}>
                    <Row size={12} style={[tw`mb-10`, {position: 'relative', top: 22}]}>
                      <Col sm={8} style={tw`pr-3`}>
                        
                        <Button
                          title={buttonTitle}
                          buttonStyle={[
                            tw`mr-3 w-full`,
                            {
                              pointerEvents: isWithdrawLoading ? 'none' : 'auto',
                            },
                          ]}
                          onClick={withdrawToExternal}
                          disabled={isWithdrawLoading}
                          disabledStyle={tw`opacity-50`}
                        />
                      </Col>
                      <Col sm={4}>
                        <Button title="Clear" onClick={handleWithdrawClear} 
                          buttonStyle={[tw`bg-transparent w-full`, {borderWidth: 1, borderColor: activeColors.buttonClearColor, pointerEvents: isWithdrawLoading ? 'none' : 'auto',}]}
                          buttonTextStyle= {{color: activeColors.clearButtonText}}
                          disabled={isWithdrawLoading}
                          disabledStyle={tw`opacity-50`}
                        />    
                      </Col>
                    </Row>
                  </View>
                </View>
                {showEstimate && assetId && withdrawAmount && destinationAddress && (
                  <>
                    <Hr thickness={0.5} style={[tw`mb-1 mt-2`, { backgroundColor: activeColors.hrColor }]} />

                    <Row size={12} style={tw`justify-between flex-1 w-full mb-18`}>
                      <Col sm={9} md={9} lg={9}>
                        {errorMessage ? <Text style={[tw`mb-3 text-xs`, {color: activeColors.errorColor}]}>{errorMessage}</Text> : null}
                        {isWithdrawalProcessing ? <Text style={[tw`mb-3 text-xs`, {color: activeColors.primaryTextColor}]}>{isWithdrawalProcessing}</Text> : null}
                      </Col>
                      <Col sm={3} md={3} lg={3}>
                        <Text style={[tw`mb-1 text-right text-xs`, { color: activeColors.secondaryTextColor, fontFamily: activeColors.interMedium }]}>
                          Network Fee
                        </Text>
                        {isLoading ? (
                          <View>
                            <ActivityIndicator color={activeColors.primaryTextColor} size="small" style={tw`items-end`} />
                          </View>
                        ) : (
                          <Text style={[tw`text-right text-xs`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interMedium }]}>
                            {estimate > 0 ? estimate.toFixed(5) : 0} {estimateToken}
                          </Text>
                        )}
                      </Col>
                    </Row>
                  </>
                )}

                {isWithdrawalProcessed && (
                  <>
                    <Hr thickness={0.5} style={[tw`mb-1 mt-2`, { backgroundColor: activeColors.hrColor }]} />
                    <Row size={12} style={tw`flex-1 w-full mb-18`}>
                      <Col sm={12} md={12} lg={12}>
                        {isWithdrawalProcessed ? <Text style={[tw`mb-3 text-xs`, {color: activeColors.primaryTextColor}]}>{isWithdrawalProcessed}</Text> : null}
                      </Col>
                    </Row>
                  </>
                )}

              </Col>

              <Col style={[notMobile ? tw`pl-4 pr-4` : tw`pl-0 pr-0`, { width: calculateWidth(12, 7, 7) }]}>
                <View style={[notMobile? tw`mb-5 rounded-md pt-5 pl-5 pr-5 pb-3 relative top-18` : tw`mb-5 rounded-md pt-5 pl-5 pr-5 pb-3 relative top-0`, { backgroundColor: activeColors.paginationButtonColor, alignSelf: 'flex-start', borderRadius: 20 }, notMobile ? tw`ml-6` : tw`ml-0`]}>
                  <Text style={[tw`mb-2 text-xs`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interRegular }]}>
                    {theme.mode === 'dark' ? <TipsDarkMode style={tw`relative top-1`}/> : <TipsLightMode style={tw`relative top-1`} />} Tips
                  </Text>
                  <FlatList
                    data={tipsData}
                    keyExtractor={(item, index) => index.toString()}
                    renderItem={({ item }) => (
                      <View style={tw`flex-row`}>
                        <Ionicons name="ios-ellipse-sharp" size={8} color={activeColors.primaryTextColor} style={tw`mt-1 mr-1`} />
                        <Text style={[tw`mb-6 text-xs`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interRegular }]}>
                          {item}
                        </Text>
                      </View>
                    )}
                  />
                </View>   
              </Col>
            </Row>
          ) : showDepositForm ? (
            <Row size={12} style={tw`relative, top-2`}>
              <Col style={{ width: calculateWidth(12, 5, 5) }}>
                <View style={tw`w-28`}>
                  <Button iconName="chevron-back-outline" title="Deposit" 
                    onClick={handleDepositBackButtonClick} 
                    buttonStyle={[tw`justify-start items-start pr-0 pl-0 mb-3 bg-transparent`]}
                    buttonTextStyle = {{color: activeColors.primaryTextColor}}
                    iconStyle = {{color: activeColors.primaryTextColor}}
                  />
                </View>
                
                {errorMessage ? <Text style={[tw`mb-3 text-xs`, {color: activeColors.errorColor}]}>{errorMessage}</Text> : null}
                
                <View style={{ flexDirection: 'row', alignItems: 'flex-start', zIndex: 9000, elevation: 5 }}>
                  <Stepper step={1} filled={selectedCoin} />
                  <View style={{ flex: 1 }}>
                    <Text style={[tw`mb-2 text-xs`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interRegular }]}>Select Coin</Text> 
                    <CustomPicker
                      items={itemsList}
                      activeColors={activeColors}
                      selectedValue={selectedCoin} // selectedCoin is expected to be a coin symbol string
                      onValueChange={(coinSymbol) => {
                        setSelectedCoin(coinSymbol);
                        setSelectedAsset('');
                        setAssetWallet(null);
                        setErrorMessage('');
                      }}
                      placeholder="Select Coin"
                      searchable={true}
                    />
                  </View>
                </View>

                <View style={{ flexDirection: 'row', alignItems: 'flex-start', zIndex: 8000, elevation: 4 }}>
                  <Stepper step={2} filled={selectedAsset}/>
                  <View style={{ flex: 1 }}>
                    <Text style={[tw`mb-2 text-xs`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interRegular }]}>Select Network</Text>
                    <CustomPicker
                      items={networkList}
                        activeColors={activeColors}
                        selectedValue={selectedAsset || ''}
                        onValueChange={(networkId) => {
                          setSelectedAsset(networkId);
                          setAssetWallet(null);
                          setErrorMessage('');
                        }}
                      placeholder="Select Network"
                    />
                  </View>
                </View>

                <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                  <Stepper step={3} showLine={showAddress && assetWallet} filled={selectedCoin && selectedAsset && assetWallet } isLastStep={true} />
                  <View style={{ flex: 1 }}>
                    <View style={tw`w-full`}>
                      <Text style={[tw`mb-2 text-xs`,{ color: activeColors.primaryTextColor, fontFamily: activeColors.interRegular }]}>
                        Deposit address 
                      </Text>
                      <View style={[tw`p-4 rounded-xl`, {backgroundColor: activeColors.paginationButtonColor, minHeight: 160}]}>
                        <Row size={12}>
                          <Col style={[notMobile ? tw`overflow-hidden` : tw`justify-center items-center`, { width: calculateWidth(12, 4, 4) }]}>
                            { showQrCardHolder && (
                              <View style={[tw`w-36 h-32 justify-center items-center`, {backgroundColor: activeColors.cardBgColor}]}> 
                                {selectedAsset && selectedCoin && showActivityIndicator && (
                                  <>
                                    <ActivityIndicator size="small" color={activeColors.primaryTextColor} />
                                  </>
                                )}
                              </View>
                            )}
                            {showAddress && assetWallet &&(
                              <View style={[tw`mb-3 p-3 justify-center items-center`, {backgroundColor: activeColors.cardBgColor}]}> 
                                {assetWallet.address && <QRCode value={assetWallet.address} size={100} />}
                              </View>
                            )}
                          </Col>
                          <Col style={[notMobile ? tw`pl-2` : tw`justify-center items-center pl-0`, { width: calculateWidth(12, 8, 8) }] }>
                            {showAddress && assetWallet && (
                              <>
                                <Text style={[tw`mb-3 text-xs`, 
                                    { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold }]}>
                                    Address 
                                </Text>
                                <Text style={[tw`mb-3 text-xs`, 
                                  { color: activeColors.primaryTextColor, fontFamily: activeColors.interBold }]}>
                                  {assetWallet.address}
                                </Text>
                              
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                  <TouchableOpacity onPress={handleCopyText}>
                                    <Ionicons name="copy-outline" size={20} color={activeColors.primaryTextColor} />
                                  </TouchableOpacity>
                                  {copied && <Text style={{ color: activeColors.primaryTextColor, marginLeft: 5 }}>Copied!</Text>}
                                </View>
                            
                                <Hr thickness={0.5} style={[tw`mb-1 mt-2`, { backgroundColor: activeColors.hrColor }]} />
                              
                                <Row size={12} style={tw`justify-between flex-1 w-full`}>
                                  <Col style={{ width: calculateWidth(6, 6, 6) }}>
                                    <Text style={[styles.subText, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold }]}>
                                      Coin
                                    </Text>
                                  </Col>
                                  <Col style={{ width: calculateWidth(6, 6, 6) }}>
                                    <Text style={[tw`text-right`, styles.subText, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold }]}>
                                      {selectedAsset}
                                    </Text>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Col>
                        </Row>
                      </View>
                    </View>
                  </View>
                </View>
              
                <Row size={12} style={ notMobile ? tw`relative, top-2 mb-18` : tw`relative, top-2 mb-5`}>
                  <Col style={{ width: calculateWidth(1, 1, 1) }}></Col>
                  <Col style={{ width: calculateWidth(11, 11, 11) }}>
                    <View style={tw`flex-row mt-5 mb-3 pl-2.5`}>
                      <Button title="Clear" onClick={handleDepositClear} 
                        buttonStyle={[tw`bg-transparent pr-14 pl-14`, {borderWidth: 1, borderColor: activeColors.buttonClearColor}]}
                        buttonTextStyle= {{color: activeColors.clearButtonText}}
                      />      
                    </View>
                  </Col>
                </Row>
                
              </Col>

              <Col style={[notMobile ? tw`pl-4 pr-4` : tw`pl-0 pr-0`, { width: calculateWidth(12, 7, 7) }]}>
                <View style={[notMobile? tw`mb-5 rounded-md pt-5 pl-5 pr-5 pb-3 relative top-18` : tw`mb-5 rounded-md pt-5 pl-5 pr-5 pb-3 relative top-0`, { backgroundColor: activeColors.paginationButtonColor, alignSelf: 'flex-start', borderRadius: 20 }, notMobile ? tw`ml-6` : tw`ml-0`]}>
                  <Text style={[tw`mb-2 text-xs`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interRegular }]}>
                    {theme.mode === 'dark' ? <TipsDarkMode style={tw`relative top-1`}/> : <TipsLightMode style={tw`relative top-1`} />} Tips
                  </Text>
                  <FlatList
                    data={tipsData}
                    keyExtractor={(item, index) => index.toString()}
                    renderItem={({ item }) => (
                      <View style={tw`flex-row`}>
                        <Ionicons name="ios-ellipse-sharp" size={8} color={activeColors.primaryTextColor} style={tw`mt-1 mr-1`} />
                        <Text style={[tw`mb-6 text-xs`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interRegular }]}>
                          {item}
                        </Text>
                      </View>
                    )}
                  />
                </View>
              </Col>
            </Row>
          ) : (
            <>
              <Row size={12}>
                <Col style={{ width: calculateWidth(12, 12, 12) }} >
                  <Text
                    style={[tw`mt-4 text-xs relative top-2`, styles.walletValueText, 
                    { color: activeColors.secondaryTextColor, fontFamily: activeColors.interMedium, letterSpacing: 1.3 }]}
                  >
                    TOTAL BALANCE
                  </Text>
                </Col>
                <Col style={{ width: calculateWidth(12, 12, 12) }} >
                  <Row size={12}>
                    <Col style={[styles.walletOverviewValueAmountContainer, { width: calculateWidth(4, 6, 7) }]}>
                      <Text
                        style={[
                          tw`relative top-4`,
                          notMobile ? tw`text-2xl mb-6` : tw`text-base mb-3`,
                          { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold },
                        ]}
                      >
                        ${totalValue.toFixed(2)}
                      </Text>
                    </Col>
                    <Col style={{ width: calculateWidth(8, 6, 5) }}>
                      <Row size={12} style={[tw`justify-end`, { flexDirection: 'row-reverse' }]}>
                        <Col style={[tw`mb-3`, { width: calculateWidth(4, 6, 6) }]} sm={depositWithdrawColumsSizes && depositWithdrawColumsSizes[1]}>
                          <Button 
                            icon={theme.mode === 'dark' ? <WithdrawIconDarkMode /> : <WithdrawIconLightMode /> }
                            iconStyle={tw`mr-2`}
                            onClick={handleWithdrawButtonClick} 
                            title="Withdraw" 
                            buttonStyle={[tw`items-end`, { backgroundColor: 'transparent', borderWidth: 0, borderColor: activeColors.outlineButtonColor }, tw`pr-0 pl-0`]}
                            buttonTextStyle={{ color: activeColors.primaryTextColor }}
                          />
                        </Col>
                        <Col style={[tw`mb-3`, { width: calculateWidth(4, 6, 6) }]} sm={depositWithdrawColumsSizes && depositWithdrawColumsSizes[0]}>
                          <Button
                            icon={theme.mode === 'dark' ? <DepositIconDarkMode /> : <DepositIconLightMode /> }
                            iconStyle={tw`mr-2`}
                            onClick={handleDepositButtonClick} 
                            title="Deposit"
                            buttonStyle={[tw`items-end`, { backgroundColor: 'transparent', borderWidth: 0, borderColor: activeColors.outlineButtonColor }]}
                            buttonTextStyle={{ color: activeColors.primaryTextColor }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </Card.Content>
      </Card>
      {isWalletScreen && vault && vault.assets && vault.assets.length > 0 && (
        <View style={[tw`mt-3 mb-6 pr-2.5 pl-2.5`, { zIndex: 9000, elevation: 5 }]}>
          <Row size={12}>
            <Col style={{ width: calculateWidth(12, 12, 12) }}>
              <View style={tw`flex-row justify-end items-center`}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    zIndex: 9000,
                    elevation: 5,
                  }}
                >
                  <View style={tw`relative top-4 mr-2`}>
                    <Text style={[tw`text-xs`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interMedium }]}>
                      Sort by:
                    </Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <CustomPicker
                      items={[
                        { label: 'Lowest Holdings', value: 'lowest' },
                        { label: 'Highest Holdings', value: 'highest' },
                      ]}
                      activeColors={activeColors}
                      selectedValue={sortingCriteria}
                      onValueChange={(itemValue) => handleSortingChange(itemValue)}
                      containerStyle={tw`rounded-lg h-11 w-40 text-xs`}
                      dropdownStyle={{
                        color: activeColors.paginationButtonTextColor,
                        borderColor: activeColors.paginationButtonColor,
                        borderWidth: 0,
                        borderRadius: 8,
                        backgroundColor: activeColors.selectionBgColor,
                        width: 160,
                      }}
                    />
                  </View>
                </View>
              </View>
            </Col>
          </Row>
        </View>
      )}

      {vault && vault.assets && vault.assets.length > 0 && (
        <Card elevation={0} style={[tw`mb-0 pt-0.5 pr-2.5 pl-2.5 pb-2`]}>
          <Card.Content style={[tw`rounded-xl`, { backgroundColor: activeColors.cardBgColor }, theme.mode === 'dark' ? styles.darkBoxshadow : styles.boxShadow]}>
            <View style={{ zIndex: 8000, elevation: 4}}>
              <Row size={12} style={tw`items-center`}>
                <Col style={[tw`pr-3`, { width: calculateWidth(4, 3, 3) }]}>
                  <Text style={[tw`relative text-xs`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interMedium }]}>Assets</Text>
                </Col>
                <Col style={[tw`pr-3`, { width: calculateWidth(4, 2, 2) }]}>
                  <Text style={[tw`relative text-xs`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interMedium }]}>Price</Text>
                </Col>
                <Col style={[tw`pr-3`, notMobile ? tw`block` : tw`hidden`, { width: calculateWidth(4, 5, 5) }]}>
                  <Text style={[tw`relative text-xs`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interMedium }]}>Allocation</Text>
                </Col>
                <Col style={[tw`pr-3`, { width: calculateWidth(4, 2, 2) }]}>
                  <Text style={[tw`relative text-xs`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interMedium }]}>Holdings</Text>
                </Col>
              </Row>
            </View>               
          </Card.Content>
        </Card>
      )}

      {user && (
        <>
          {vault && vault.assets && vault.assets.length > 0 && (
            <>
              <FlatList
                data={getPaginatedVault()}
                keyExtractor={(item, index) => index.toString()}

                renderItem={({ item: a }) => {
                  const progressValue = (getUsdPrice(a) * a.available) / totalValue * 100;
                  return (
                    <Card elevation={0} style={[tw`mb-0 pt-0.5 pr-2.5 pl-2.5 pb-2`]}>
                      <Card.Content style={[tw`rounded-xl`, { backgroundColor: activeColors.cardBgColor }, theme.mode === 'dark' ? styles.darkBoxshadow : styles.boxShadow]}>
                        <Row size={12} style={tw`items-center`}>
                          <Col style={[tw`pr-3 items-center`, { width: calculateWidth(4, 3, 3) }]}>
                            <View style={tw`flex-row mr-2 w-full`}>
                              {(assetMetadata[a.coinSymbol] && !assetMetadata[a.coinSymbol].unknown) && 
                                <View>
                                  <img style={notMobile ? tw`relative w-12 h-12 mr-3` : tw`relative w-10 h-10 mr-3`} src={assetMetadata[a.coinSymbol].logo}></img>
                                </View>
                                }
                                {(!assetMetadata[a.coinSymbol] || assetMetadata[a.coinSymbol].unknown) &&
                                  <FuelToken style={notMobile ? tw`relative w-12 h-12 mr-3` : tw`relative w-10 h-10 mr-3`} />
                                }
                              <View style={tw`flex-1`}>
                                <Text style={[notMobile ? tw`text-base` : tw`text-sm`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold }]}>{a.coinSymbol}</Text>
                                <Text style={[notMobile ? tw`text-sm` : tw`text-xs`, { color: activeColors.secondaryTextColor, fontFamily: activeColors.interMedium, textTransform: 'capitalize' }]}>{a.id}</Text>
                              </View>
                            </View>
                          </Col>
                          <Col style={[tw`pr-3`, { width: calculateWidth(4, 2, 2) }]}>
                            <Text style={[notMobile ? tw`text-base` : tw`text-sm`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold  }]}> 
                              {getUsdPrice(a) ? `$${getUsdPrice(a).toFixed(2)}` : '$0.00'}
                            </Text>
                          </Col>
                          <Col style={[tw`pr-3`, notMobile ? tw`block` : tw`hidden`, { width: calculateWidth(4, 5, 5) }]}>
                            <Row size={12} style={tw`items-center`}>
                              <Col sm={columnSizes && columnSizes[0]}>
                                <Text style={[notMobile ? tw`text-base` : tw`text-sm`,{ color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold }]}>
                                  {totalValue !== 0 && !isNaN(progressValue) ? `${progressValue.toFixed(2).replace(/\.?0+$/, '')}%` : '0%'}
                                </Text>
                              </Col>
                              <Col sm={columnSizes && columnSizes[1]} style={[tw`pr-16 items-center justify-center text-right`, { width: calculateWidth(12, 3, 3) }]}>
                                  <LinearProgressBar progress={progressValue} style={[tw`w-full`]} />
                              </Col>
                            </Row>
                          </Col>
                          <Col style={[tw`pr-3`, { width: calculateWidth(4, 2, 2) }]}>
                            <View style={tw`flex-1`}>
                              <Text style={[notMobile ? tw`text-base` : tw`text-sm`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold }]}>
                                {a.coinSymbol} {a.available.toString().substring(0, 8)}
                              </Text>
                              <Text style={[notMobile ? tw`text-sm` : tw`text-xs`, { color: activeColors.secondaryTextColor, fontFamily: activeColors.interMedium }]}>{getUsdPrice(a) ? `$${(getUsdPrice(a) * a.available).toFixed(2)}` : '$0.00'}</Text>
                            </View>
                          </Col>
                        </Row>
                      </Card.Content>
                    </Card>
                  );
                }}
              />
              {isWalletScreen && (
                <View>
                  {vault.assets.length > vaultPerPage && (
                    <Row size={12} style={tw`mt-6.5 mb-5 justify-center`}>
                      <Col style={{ width: calculateWidth(6, 6, 6) }}>
                        <Card elevation={0} style={[tw`mb-0 pt-0.5 pr-2.5 pl-2.5 pb-2`]}>
                          <Card.Content style={[tw`rounded-xl`, { backgroundColor: activeColors.cardBgColor, borderRadius: 50 }, theme.mode === 'dark' ? styles.darkBoxshadow : styles.boxShadow]}>
                            <View style={tw`flex-row justify-center`}>
                              {currentPage > 1 && (
                                <Button
                                  title={BackIcon}
                                  onClick={() => handlePageClick(currentPage - 1)}
                                  buttonStyle={[tw`relative, mx-1 mb-0 rounded-full bg-transparent`, { top: 6, width: 28, height: 28, }]}
                                />
                              )}
                          
                              {Array.from({ length: Math.min(paginationRange, Math.ceil(vault.assets.length / vaultPerPage)) }, (_, index) => {
                                const pageNumber = startPageIndex + index;
                                return (
                                  <View style={[tw`relative`, { top: 4 }]}>
                                    <Button
                                      key={pageNumber}
                                      title={pageNumber.toString()}
                                      style={[
                                        tw`h-6 w-6 mx-1 rounded-full`,
                                        pageNumber < startPageIndex || pageNumber > endPageIndex ? tw`hidden` : null // Hide buttons outside the range
                                      ]}
                                      onClick={() => handlePageClick(pageNumber)}
                                      buttonStyle={[
                                        { width: 28, height: 28, borderRadius: '50%', padding: 10, marginLeft: 3, marginRight: 3, 
                                        backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center' },
                                        currentPage === pageNumber && { backgroundColor: activeColors.paginationActiveButtonColor }
                                      ]}
                                      buttonTextStyle={[
                                        { fontSize: 12, color: activeColors.paginationButtonTextColor },
                                        currentPage === pageNumber && { color: activeColors.paginationActiveButtonTextColor }
                                      ]}
                                    />
                                  </View>
                                );
                              })}

                              {currentPage < Math.ceil(vault.assets.length / vaultPerPage) && (
                                <Button
                                  title={NextIcon}
                                  onClick={() => handlePageClick(currentPage + 1)}
                                  buttonStyle={[tw`relative, mx-1 mb-0 rounded-full bg-transparent`, { top: 6, width: 28, height: 28, }]}
                                />
                              )}
                            </View>
                           </Card.Content>
                        </Card>
                      </Col>
                    </Row>
                  )}
                </View>
              )}
            </>
          )}

          {vault && vault.assets && vault.assets.length === 0 && (
            <Card elevation={0} style={[tw`mb-0 pt-0.5 pr-2.5 pl-2.5 pb-2`]}>
              <Card.Content style={[tw`rounded-xl`, { backgroundColor: activeColors.cardBgColor }, theme.mode === 'dark' ? styles.darkBoxshadow : styles.boxShadow, notMobile ? {height: 290} : tw`h-122`]}>
                <View style={{ zIndex: 5000, elevation: 1, flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <View>
                    <Ionicons name="wallet-outline" size={80} color={activeColors.secondaryTextColor} />
                  </View>
                  <Text style={[tw`mt-5 text-base`, { color: activeColors.secondaryTextColor, fontFamily: activeColors.interMedium }]}>
                    No asset found.
                  </Text>
                </View>
              </Card.Content>
            </Card>
          )}
        </>
      )}
    </PaperProvider>
  );
}

const styles = StyleSheet.create({
  boxShadow: {
    boxShadow: '0px 2px 14px 0px rgba(189, 193, 209, 0.30)',
  },
  darkBoxshadow: {
    boxShadow: 'none',
  },
   walletOverviewValueAmountContainer: {
    width: '36.666667%',
  },
  subText: {
    fontSize: 10,
  },
  rightArrow: {
    position: "absolute",
    width: 15,
    height: 15,
    bottom: -15,
    borderBottomLeftRadius: 25,
    right: 44
  },
  rightArrowOverlap: {
    position: "absolute",
    width: 20,
    height: 35,
    bottom: -6,
    borderBottomLeftRadius: 18,
    right: -20
  },
});