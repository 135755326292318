import * as React from "react";
import Svg, { Line, Path } from "react-native-svg";
const WithdrawIconLightMode = (props) => (
  <Svg
    width={16}
    height={14}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Line
      x1={1}
      y1={12.8}
      x2={14.6694}
      y2={12.8}
      stroke="#6A727C"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M12.6494 5.30017L7.8364 1.00002M7.8364 1.00002L3.02352 5.30017M7.8364 1.00002L7.83653 9.5"
      stroke="#6A727C"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default WithdrawIconLightMode;
