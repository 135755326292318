import * as React from "react";
import Svg, { Path } from "react-native-svg";
const FuelToken = (props) => (
  <Svg
    width={57}
    height={73}
    viewBox="0 0 57 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M55.3893 35.6832C53.0764 29.3636 48.7049 24.9105 43.5545 21.1109C42.553 20.3713 41.5237 19.6591 40.4746 18.9626C38.5114 17.6595 36.6913 16.1999 35.3361 14.5095C33.4881 12.2047 32.5105 9.47334 33.2338 6.10418C33.8657 3.16545 35.7852 2.23022 33.2219 0.496725C31.1395 -0.911986 28.4967 0.981948 26.4659 2.37501C20.3101 6.60114 16.793 15.3078 19.869 24.0418C21.236 27.9196 23.537 31.0736 26.3467 33.8401C27.5588 35.0336 28.8623 36.1567 30.2334 37.2328C30.7699 37.6554 31.3143 38.0623 31.8389 38.4889C32.8086 39.2754 33.7226 40.1167 34.4022 41.1889C34.7757 41.545 35.1056 41.9441 35.3878 42.3785C36.0435 43.392 36.425 44.5894 36.425 45.8807C36.425 47.442 35.8686 48.8703 34.9427 49.9933C33.7266 51.4647 31.8747 52.4077 29.7962 52.4077C26.1361 52.4077 23.1674 49.4846 23.1674 45.8807C23.1674 45.3407 23.08 44.2646 23.08 44.2646C23.0482 44.4563 22.9608 42.4489 22.9886 41.5176C22.9966 41.0167 22.9648 40.5628 22.9052 40.148C22.3011 35.8593 18.4542 36.0119 15.3266 38.3402C12.4334 40.6998 9.60787 40.0463 10.9273 35.7184C13.0216 29.5827 6.12658 25.877 3.35267 31.7623C3.34869 31.774 3.34075 31.7818 3.33677 31.7936C-6.06591 47.1211 5.58213 71.0457 23.5609 72.744C24.928 72.9632 26.2792 73.0375 27.6025 72.9827C31.3421 73.1079 35.1811 72.5171 38.9247 71.1201C42.553 69.7662 45.7402 67.6883 48.391 65.1096C51.757 61.8343 54.2488 57.753 55.6635 53.3234C57.4638 47.6846 57.5154 41.4902 55.3893 35.6832Z"
      fill="#E06365"
    />
  </Svg>
);
export default FuelToken;