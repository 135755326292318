import React, { useRef, useEffect, useState } from 'react';
import { View, Text, Animated, TouchableWithoutFeedback } from 'react-native';

const CustomTooltip = ({ id, text, children, tooltipTop, tooltipBgColor, tooltipColor, tooltipFont, tooltipFontSize, tooltipWidth, toolTipRightAlign, toolTipTopArrowAlign, toolTipPaddingBottom, isActive, setActiveTooltipId }) => {
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const tooltipRef = useRef(null);
  const triggerRef = useRef(null);
  const [hideTimeout, setHideTimeout] = useState(null);

  const handlePressOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target) && !triggerRef.current.contains(event.target)) {
      hideTooltip();
    }
  };

  const hideTooltip = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 200,
      useNativeDriver: true,
    }).start(() => {
      setActiveTooltipId(null);
    });
  };

  const handleTriggerPress = () => {
    clearTimeout(hideTimeout); // Clear the timeout when the trigger is pressed
    setActiveTooltipId((prevId) => (prevId === id ? null : id));
  };

  useEffect(() => {
    document.addEventListener('click', handlePressOutside);

    return () => {
      document.removeEventListener('click', handlePressOutside);
    };
  }, [isActive, setActiveTooltipId]);

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: isActive ? 1 : 0,
      duration: 200,
      useNativeDriver: true,
    }).start();

    // Set a timeout to hide the tooltip after 10 seconds of inactivity
    if (isActive) {
      const timeout = setTimeout(() => {
        hideTooltip();
      }, 10000);
      setHideTimeout(timeout);
    }

    return () => {
      clearTimeout(hideTimeout); // Clear the timeout on component unmount or when isActive changes
    };
  }, [isActive]);

  return (
    <View style={{ position: 'relative', cursor: 'pointer' }}>
      <TouchableWithoutFeedback onPress={handleTriggerPress} ref={triggerRef}>
        <View>
          {children}
        </View>
      </TouchableWithoutFeedback>
      {isActive && (
        <Animated.View
          style={{
            position: 'absolute',
            backgroundColor: tooltipBgColor,
            borderRadius: 5,
            paddingTop: 5,
            paddingBottom: toolTipPaddingBottom !== undefined ? toolTipPaddingBottom : 5,
            paddingLeft: 10,
            paddingRight: 10,
            opacity: fadeAnim,
            top: tooltipTop || 30,
            right: toolTipRightAlign, //Tooltip horizontal alignment
            zIndex: 9999,
          }}
          ref={tooltipRef}
        >
          <View
            style={{
              position: 'absolute',
              top: -8,
              right: toolTipTopArrowAlign || 10,
              width: 0,
              height: 0,
              borderLeftWidth: 10,
              borderLeftColor: 'transparent',
              borderRightWidth: 10,
              borderRightColor: 'transparent',
              borderBottomWidth: 10,
              borderBottomColor: tooltipBgColor,
            }}
          />
          <TouchableWithoutFeedback onPress={hideTooltip}>
            <Text
              style={{ color: tooltipColor, fontFamily: tooltipFont, fontSize: tooltipFontSize, width: tooltipWidth || 148, top: tooltipTop }}
              numberOfLines={0}
            >
              {text}
            </Text>
          </TouchableWithoutFeedback>
        </Animated.View>
      )}
    </View>
  );
};

export default CustomTooltip;
