import React, { useState, useContext } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ScrollView } from 'react-native';
import { colors } from '../config/theme';
import { ThemeContext } from '../contexts/themeContext';
import tw from 'twrnc';
import {
  useFonts,
  Inter_500Medium,
  Inter_600SemiBold,
} from '@expo-google-fonts/inter';

const SwitchableContentTab = ({ tabs, defaultActiveTab, activeTabColor }) => {
  let [fontsLoaded] = useFonts({
    Inter_500Medium,
    Inter_600SemiBold,
  });

  const { theme } = useContext(ThemeContext);
  let activeColors = colors[theme.mode];

  const [activeTab, setActiveTab] = useState(defaultActiveTab || 0);

  const handleTabPress = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <View style={tw`flex-1`}>
      <View style={tw`flex-row w-72`}>
        {tabs.map((tab, index) => (
          <TouchableOpacity
            key={index}
            style={[
              tw`items-center justify-center text-center pt-4 pb-4 pl-2.5 pr-2.5 rounded-t-xl`,
              { width: tab.width },
              activeTab === index ? { backgroundColor: activeTabColor  } : { backgroundColor: 'transparent'  }
            ]}
            onPress={() => handleTabPress(index)}
          >
            <View style={styles.tabTextContainer}>
              <Text style={[tw`text-xs`,
                { 
                  color: activeTab === index 
                    ? activeColors.switchTabActiveTextColour 
                    : activeColors.switchTabTextColour 
                },
                { 
                  fontFamily: activeColors.interSemiBold, 
                  letterSpacing: 1.3
                }
              ]}>
                {tab.name} {tab.icon}
              </Text>
              {activeTab === index ? (
                <View style={[styles.underline, {backgroundColor: activeColors.switchTabActiveTextBorder}]}></View>
              ) : (
                <View style={[styles.underline, {backgroundColor: activeColors.switchTabInActiveTextBorder}]}></View>
              )}
            </View>
          </TouchableOpacity>
        ))}
      </View>
      <View style={tw`flex-1`}>
        <ScrollView contentContainerStyle={tw`flex-1`}>
          {tabs.map((tab, index) => (
            <View
              key={index}
              style={[activeTab === index ? tw`flex` : null, tw`w-full`]}
            >
              {activeTab === index && (
                <>{tab.content}</>
              )}
            </View>
          ))}
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  tabTextContainer: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  underline: {
    height: 1.5,
    marginTop: 4,
    marginLeft: -3,
    width: '100%',
  },
});

export default SwitchableContentTab;
