import * as React from "react";
import Svg, { Path } from "react-native-svg";
const WalletIconDarkMode = (props) => (
  <Svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.3344 10.6665C29.3344 7.72126 26.9463 5.33337 24.0009 5.33337C19.5607 5.33337 12.4391 5.33337 8.00028 5.33337C5.05484 5.33337 2.66675 7.72126 2.66675 10.6665V21.3326C2.66675 24.2778 5.05484 26.6657 8.00028 26.6657H24.0009C26.9463 26.6657 29.3344 24.2778 29.3344 21.3326V10.6665ZM26.6676 21.3326H5.33351C5.33351 22.8059 6.52689 23.9992 8.00028 23.9992H24.0009C25.4729 23.9992 26.6676 22.8059 26.6676 21.3326ZM5.33351 17.3328V18.6661H26.6676V17.3328H5.33351ZM26.6676 14.6663V10.6665C26.6676 9.19453 25.4729 7.99991 24.0009 7.99991C19.5607 7.99991 12.4391 7.99991 8.00028 7.99991C6.52689 7.99991 5.33351 9.19453 5.33351 10.6665V14.6663H26.6676Z"
      fill="#FFFFFF"
    />
  </Svg>
);
export default WalletIconDarkMode;
