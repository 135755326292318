import * as React from "react";
import Svg, { Line, Path } from "react-native-svg";
const DepositIconDarkMode = (props) => (
  <Svg
    width={16}
    height={14}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Line
      x1={1}
      y1={12.8}
      x2={14.6694}
      y2={12.8}
      stroke="#B0B7C3"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M3.02246 5.19983L7.83547 9.49998M7.83547 9.49998L12.6484 5.19983M7.83547 9.49998L7.83534 1"
      stroke="#B0B7C3"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default DepositIconDarkMode;
