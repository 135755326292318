import {API_BASE_PATH} from '@env'
import axios from 'axios';

export class cryptoCoinApiService {
    static instance: cryptoCoinApiService = new cryptoCoinApiService();
    internalCache = {};
    async getListings() {
        var res = await axios.get(`${API_BASE_PATH}/cryptoCoin/listings`);
        return res.data;
    }
    async getQuotes(symbols: string) {
        var res = await axios.get(`${API_BASE_PATH}/cryptoCoin/quotes?symbols=${symbols}`);
        return res.data;
    }
    async getMetadata(symbols: string) {
        var split = symbols.split(",");
        var newArr = [];
        for (var s of split){
            if (this.internalCache[s]) continue;
            newArr.push(s);
        }
        if (newArr.length == 0) return { data : this.internalCache };

        symbols = newArr.join(",");
        var res = await axios.get(`${API_BASE_PATH}/cryptoCoin/metadata?symbols=${symbols}`);
        
        for (var a of newArr){
            if (res.data.data[a]){
                this.internalCache[a] = res.data.data[a];
            }
            else{
                this.internalCache[a] = {logo: 'default', unknown: true}
            }
        }

        return res.data;
    }
}