import React, { useContext, useState, useEffect } from 'react';
import {
  useFonts,
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
} from '@expo-google-fonts/inter';
import { StyleSheet, View, SafeAreaView, ScrollView, Platform, Dimensions } from 'react-native';
import { Column as Col, Row } from 'react-native-flexbox-grid';
import { Text } from 'react-native-paper';
import tw from 'twrnc';
import ContainerWrapper from '../components/containerWrapper';
import { colors } from '../config/theme';
import { ThemeContext } from '../contexts/themeContext';
import Header from '../components/header';
import Transactions from '../components/mainTransaction';
import WalletOverview from '../components/mainWallet';
import SwitchableContentTab from '../components/switchTab';
import { modelStore, modelStoreNameEnum } from '../services/modelStore';
import { useRoute } from '@react-navigation/native';

export default function Wallet() {
  const { theme } = useContext(ThemeContext);
  const activeColors = colors[theme.mode];

  const isWeb = Platform.OS === 'web';

  // Responsive column grid
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width);

  useEffect(() => {
    const handleWindowResize = ({ window }) => {
      setWindowWidth(window.width);
    };

    Dimensions.addEventListener('change', handleWindowResize);

    return () => {
      Dimensions.removeEventListener('change', handleWindowResize);
    };
  }, []);

  const calculateWidth = (sm, md, lg) => {
    let columns = 12; // Default column number for extra small devices

    if (windowWidth >= 320 && windowWidth < 1200) {
      columns = sm;
    } else if (windowWidth >= 1200 && windowWidth < 1230) {
      columns = md;
    } else if (windowWidth >= 1230) {
      columns = lg;
    }

    // Calculate percentage (i.e., '4 columns' out of '12 columns' is '33.33%')
    return `${(columns / 12) * 100}%`;
  };

  const columnSizes = [2, 10]; // Set allocation width from mainWallet component to a different width in wallet screen

  const toolTips = [45]; // Transaction and network fees tooltip position

  const depositWithdrawColumsSizes = [4, 4]; // Set withdraw and deposit column width from mainWallet component to a different width in wallet screen

  /* Logic display the deposit form instantly on the wallet screen, 
  when the user clicks on the deposit button on the home screen to navigate to the wallet screen */
  const [showDepositForm, setShowDepositForm] = useState(false);
  const handleDepositBackButtonClick = () => {
    setShowDepositForm(false);
  };

  /* Logic display the widthdraw form instantly on the wallet screen, 
  when the user clicks on the withdraw button on the home screen to navigate to the wallet screen */
  const [showWithdrawForm, setShowWithdrawForm] = useState(false);
  const handleWithdrawBackButtonClick = () => {
    setShowWithdrawForm(false);
  };

  // Switch tab
  const walletTabs = [
    { name: 'MAIN', content: <WalletOverview columnSizes={columnSizes} depositWithdrawColumsSizes={depositWithdrawColumsSizes} screenType="My Wallet" showDepositFormProp={showDepositForm} showWithdrawFormProp={showWithdrawForm} /> },
    { name: 'TRANSACTIONS', width: 150, content: <Transactions screenType="My Wallet" /> },
  ];

  var [user, setUser] = useState(null);
  var subOwnerName = 'wallet';
  useEffect(() => {
    modelStore.instance.subscribe(subOwnerName, modelStoreNameEnum.User, (usr) => {
        setUser(usr);
    });
  }, []);

  return (
    <SafeAreaView style={{ backgroundColor: activeColors.appBackgroundColor, overflowY: 'scroll', height: '100%' }}>
      <Header />
      <ContainerWrapper>
        <ScrollView>
          {user && (
            <View>
              <Row size={12}>
                <Col style={[isWeb ? tw`mb-10` : tw`mb-3`, { width: calculateWidth(12, 12, 12) }]}>
                  <View>
                    <Text
                      style={[
                        tw`mb-7 text-lg leading-7 pr-2.5 pl-2.5`,
                        { color: activeColors.primaryTextColor, fontFamily: activeColors.interSemiBold },
                      ]}
                    >
                      Wallet Overview
                    </Text>
                  </View>
                  <SwitchableContentTab tabs={walletTabs} />
                </Col>
              </Row>
            </View>
          )}
        </ScrollView>
      </ContainerWrapper>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  boxShadow: {
    boxShadow: '4px 3px 19px 0px rgba(189, 193, 209, 0.30)',
    shadowOffset: {
      width: 4,
      height: 3,
    },
    shadowOpacity: 1,
    shadowRadius: 19,
  },
  darkBoxshadow: {
    shadowColor: 'none',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0,
    shadowRadius: 0,
  },
});