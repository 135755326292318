import { BACKEND_BASE_PATH } from '@env';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { authTokenProvider } from './authTokenProvider';

export var webhookTypeEnum = {
    TransactionUpdate: 'TransactionUpdate',
    Test: 'Test'
}

export class webhookService {
    static instance: webhookService = new webhookService();
    connection: HubConnection;
    connectedWithToken:string;
    cbs = {};
    async disconnect(){
        if (!this.connection) return;
        try{
            await this.connection.stop();
        }catch{}
    }

    subscribe(owner: string, topic: string, cb: (...args: any[]) => any){
        if (!this.cbs[topic]){
            this.cbs[topic] = [];
        }
        if (this.cbs[topic].findIndex(q => q.owner == owner) != -1) return;
        this.cbs[topic].push({owner: owner, cb: (msg) => { cb(msg) }});
    }

    private setupConn(topicName:string){
        this.connection.on(topicName, message => {
            if (this.cbs[topicName]){
                for (var c of this.cbs[topicName]){
                    c.cb(message);
                }
            }
        });
    }

    private setupSubscriptions(){
        for (var t of Object.keys(webhookTypeEnum)){
            var topicName = t.toString() + "";
            this.setupConn(topicName);
        }
    }

    async sendMessage(methodName: string, msg: string) {
        this.connection.send(methodName, msg);
    }

    async connect(){
        const authToken = authTokenProvider.instance.authToken;
        if (this.connectedWithToken == authToken) return;
        this.connectedWithToken = authToken;
        this.connection = new HubConnectionBuilder()
            .withUrl(BACKEND_BASE_PATH + '/notificationHub?token=' + authToken)
            .withAutomaticReconnect()
            .build();
        await this.connection.start();
        if (this.connection.state == HubConnectionState.Connected){
            //console.log('Connected!');
            var res = await this.connection.send('SubscribeToGroup');
            this.setupSubscriptions();
        }
    }
}