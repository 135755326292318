import {API_BASE_PATH} from '@env'
import axios from 'axios'

export class userApiService {
	static instance: userApiService = new userApiService();

    async getUser(forceFetch: boolean = false) {
		var res = await axios.get(`${API_BASE_PATH}/user`);
		return res.data;
    }
	async connectConnector(ct: string, code: string) {
		var res = await axios.post(`${API_BASE_PATH}/user/connector/${ct}?code=${code}`);
		return res.data;
    }
	async disconnectConnector(ct: string) {
		var res = await axios.delete(`${API_BASE_PATH}/user/connector/${ct}`);
		return res.data;
    }
}