import * as React from "react";
import Svg, { Path } from "react-native-svg";
const TipsDarkMode = (props) => (
  <Svg
    width={15}
    height={15}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M13.7833 6.55L13.4 5.71667L12.5667 5.33333L13.4 4.95L13.7833 4.11667L14.1667 4.95L15 5.33333L14.1667 5.71667L13.7833 6.55ZM11.6667 3.63333L11.0833 2.4L9.85 1.81667L11.0833 1.23333L11.6667 0L12.25 1.23333L13.4833 1.81667L12.25 2.4L11.6667 3.63333ZM5 14.6667C4.62222 14.6667 4.30278 14.5361 4.04167 14.275C3.78056 14.0139 3.65 13.6944 3.65 13.3167H6.35C6.35 13.6944 6.21944 14.0139 5.95833 14.275C5.69722 14.5361 5.37778 14.6667 5 14.6667ZM2.3 12.2833V11.2833H7.7V12.2833H2.3ZM2.38333 10.2667C1.65 9.78889 1.06944 9.19167 0.641667 8.475C0.213889 7.75833 0 6.95 0 6.05C0 4.69444 0.494445 3.52222 1.48333 2.53333C2.47222 1.54444 3.64444 1.05 5 1.05C6.35556 1.05 7.52778 1.54444 8.51667 2.53333C9.50556 3.52222 10 4.69444 10 6.05C10 6.95 9.78889 7.75833 9.36667 8.475C8.94444 9.19167 8.36111 9.78889 7.61667 10.2667H2.38333ZM2.75 9.26667H7.26667C7.8 8.91111 8.22222 8.45 8.53333 7.88333C8.84444 7.31667 9 6.70556 9 6.05C9 4.95 8.60833 4.00833 7.825 3.225C7.04167 2.44167 6.1 2.05 5 2.05C3.9 2.05 2.95833 2.44167 2.175 3.225C1.39167 4.00833 1 4.95 1 6.05C1 6.70556 1.15556 7.31667 1.46667 7.88333C1.77778 8.45 2.20556 8.91111 2.75 9.26667Z"
      fill="#B0B7C3"
    />
  </Svg>
);
export default TipsDarkMode;