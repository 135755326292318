import {API_BASE_PATH} from '@env'
import axios from 'axios';

export class taskApiService {
    static instance: taskApiService = new taskApiService();

    async getTaskStatus(repoId: number, taskId: number) {
        var res = await axios.post(`${API_BASE_PATH}/task/github/status`, {RepositoryId: repoId, IssueId: taskId});
        return res.data;
    }
    async listTaskSubmissions(repoId: number, taskId: number) {
        var res = await axios.post(`${API_BASE_PATH}/task/github/submissions`, {RepositoryId: repoId, IssueId: taskId});
        return res.data;
    }
    async listExplore(sortBy:string = 'value', sortOrder = 'desc', pageSize: number = 10, page: number = 0, popFilter: string = "") {
        var res = await axios.get(`${API_BASE_PATH}/task/explore?sortBy=${sortBy}&sortOrder=${sortOrder}&pageSize=${pageSize}&page=${page}&popFilter=${popFilter}`);
        return res.data;
    }
    async listActivity(pageSize: number = 10, page: number = 0, asFunder: boolean = false) {
        var res = await axios.get(`${API_BASE_PATH}/task/activity?pageSize=${pageSize}&page=${page}&asFunder=${asFunder}`);
        return res.data;
    }
    async getTaskStatusArr(req: TaskStatusRequest[]) {
        var res = await axios.post(`${API_BASE_PATH}/task/github/statusArr`, {Tasks: req});
        return res.data;
    }
    async getEstimate(assetId: string, forFund: boolean = true) {
        var res = await axios.post(`${API_BASE_PATH}/task/estimate?forFund=${forFund}`, {AssetId: assetId, ProviderTask: {}});
        return res.data;
    }
    async fund(assetId: string, amount:number, repoId: number, issueId: number) {
        var res = await axios.post(`${API_BASE_PATH}/task/github/fund`, {AssetId: assetId, FundAmount: +amount, ProviderTask: {RepositoryId: +repoId, IssueId: +issueId}});
        return res.data;
    }
    async triggerWatch(token: string) {
        var res = await axios.post(`${API_BASE_PATH}/watch/issues`);
        return res.data;
    }
}

export class TaskStatusRequest{
    RepositoryId: number;
    IssueId: number;
}