export const colors = {
    light: {
        appBackgroundColor: "#FBFBFB", //App background colour
        navbarBgColor: "#FCFCFC", // Navbar background colour
        navbarBorderRightColor: "#E6E6E6", //N avbar right border colour
        navbarActiveBgColor: "#F2F2F2", // Navbar active background colour
        navbarActiveBorder: "#454545", // Navbar active left border colour
        navbarActiveItemColor: "#5C80ED", // Navbar active icon colour
        navbarItemColor: "#43535B", // Navbar icon and text colour
        activeTextColor: "#43535B", // Navbar active text colour
        walletBtnColour: "#454545", // Connect wallet button colour
        bannerBtnColour: '#E06365', // Banner button colour
        btnBgColour: "#454545",
        textInputOutline: "#999", // Text input outline colour
        buttonColor: "#547BED", // Button colour
        buttonTextColor: "#FFFFFF", // Button text colour
        buttonClearColor: "#547BED", // Clear button colour
        clearButtonText: "#547BED", // Clear button text colour
        outlineButtonColor: "#547BED", // Outline button colour,
        outlineButtoTextColor: "#547BED", // Button text colour
        switchTabTextColour: "#43535B", // Switch tab text colour
        switchTabActiveTextColour: "#5C80ED", // Switch tab active text colour
        switchTabActiveTextBorder: "#5C80ED",
        switchTabInActiveTextBorder: "#F5F5F5",
        paginationButtonColor: "#EBECF0", // Pagination button background colour
        paginationActiveButtonColor: "#454545", // Pagination active background button colour
        paginationButtonTextColor: "#43535B", // Pagination button text colour
        paginationActiveButtonTextColor: "#FFFFFF", // Pagination active button text colour
        paginationButtonFont: "Lato",
        paginationNextBackBtnColor: "rgba(169, 169, 169, 0.73)",
        stepperButtonColor: "#EBECF0", // Stepper
        stepperActiveButtonColor: "#547BED", 
        stepperButtonTextColor: "#43535B", 
        stepperActiveButtonTextColor: "#FFFFFF", 
        stepperDotActiveColor: "#AEBFF6",
        customPickerIcon: "#727B93",
        progressBgColor: "#B0B7C3",
        primaryTextColor: "#43535B", // Primary text colour
        secondaryTextColor: "#999", // Secondary text colour
        tertiaryTextColor: "#E06365", // Tertiary text colour
        rewardTextColor: "#43535B",
        cardBgColor: "#FFFFFF", // Card background colour
        selectionBgColor: "#EFF0F5", // Custom picker background
        toolTipBgColor: "#EFF0F5",
        notificationBgColor: "#5C80ED", // Notification background 
        topBarBgColor: "#FCFCFC", // Top bar background to display user image and connect wallet
        lowerSubmission: "#7BC950",
        mediumSubmission: "#ECB17C",
        higherSubmission: "#E06365",
        whiteColor: "#FFFFFF", // Notification text colour
        bannerTextColor: "#5E5E5E",
        blackColor: "#000000",
        greyColor: "#141414",
        exploreActivityTextColor: '#5C80ED',
        errorColor: "#E06365", //Error text colour
        hrColor: "#999", // Hr colour
        interExtraBold: 'Inter_800ExtraBold',
        interBold: 'Inter_700Bold',
        interSemiBold: 'Inter_600SemiBold',
        interMedium: 'Inter_500Medium',
        interRegular: 'Inter_400Regular',
        interLight: 'Inter_300Light'
    },
    dark: {
        appBackgroundColor: "#0B0E0F", // App background colour
        navbarBgColor: "#161B1D", // Navbar background colour
        navbarBorderRightColor: "#21292C", // Navbar right border colour
        navbarActiveBgColor: "#21282C", // Navbar active background colour
        navbarActiveBorder: "#5C80ED", // Navbar active left border colour
        navbarActiveItemColor: "#5C80ED", // Navbar active icon colour
        navbarItemColor: "#666970", // Navbar icon and text colour
        activeTextColour: "#547BED", // Navbar active text colour
        walletBtnColour: "#405159", // Connect wallet button colour
        bannerBtnColour: '#E06365', // Banner button colour
        btnBgColour: "#405159",
        textInputOutline: "#405159", // Text input outline colour
        buttonColor: "#405159", // Button colour
        buttonTextColor: "#B0B7C3", // Button text color
        buttonClearColor: "#405159", // Clear button colour
        clearButtonText: "#405159", // Clear button text colour
        outlineButtonColor: "#405159", // Outline button colour,
        outlineButtoTextColor: "#B0B7C3", // Button text colour
        switchTabTextColour: "#666970", // Switch tab text colour
        switchTabActiveTextColour: "#B0B7C3", // Switch tab active text colour
        switchTabActiveTextBorder: "#405159",
        switchTabInActiveTextBorder: '#0B0E0F',
        paginationButtonColor: "#20282C", // Pagination button background colour
        paginationActiveButtonColor: "#405159", // Pagination active background button colour
        paginationButtonTextColor: "#405159", // Pagination button text colour
        paginationActiveButtonTextColor: "#B0B7C3", // Pagination active button text colour
        paginationButtonFont: "Lato",
        paginationNextBackBtnColor: "#405159",
        stepperButtonColor: "#20282C", 
        stepperActiveButtonColor: "#405159", 
        stepperButtonTextColor: "#B0B7C3", 
        stepperActiveButtonTextColor: "#B0B7C3", 
        stepperDotActiveColor: "#20282c",
        customPickerIcon: "#405159",
        progressBgColor: "#405159",
        primaryTextColor: "#B0B7C3", // Primary text colour
        secondaryTextColor: "#666970", // Secondary text colour
        tertiaryTextColor: "#E06365", // Tertiary text colour
        rewardTextColor: "#666970",
        cardBgColor: "#161B1D", // Card background colour
        selectionBgColor: "#161B1D", // Custom picker background
        toolTipBgColor: "#21282C",
        notificationBgColor: "#5C80ED", // Notification background 
        topBarBgColor: "#161B1D", // Top bar background to display user image and connect wallet
        lowerSubmission: "#7BC950",
        mediumSubmission: "#ECB17C",
        higherSubmission: "#E06365",
        whiteColor: "#FFFFFF", // Notification text colour
        bannerTextColor: "#5E5E5E",
        switchTrackColorFalse: "#666970",
        switchTrackColorTrue: "#666970",
        switchThumbActiveColour : "#5C80ED",
        blackColor: "#000000",
        greyColor: "#141414",
        errorColor: "#E06365", // Error text colour
        exploreActivityTextColor: '#5C80ED',
        hrColor: "#405159", // Hr colour
        interExtraBold: 'Inter_800ExtraBold',
        interBold: 'Inter_700Bold',
        interSemiBold: 'Inter_600SemiBold',
        interMedium: 'Inter_500Medium',
        interRegular: 'Inter_400Regular',
        interLight: 'Inter_300Light'
    }
}