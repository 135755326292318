import * as React from "react";
import Svg, { Path } from "react-native-svg";
const ExploreIconDefault = (props) => (
  <Svg
    width={27}
    height={33}
    viewBox="0 0 27 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M24.3501 16.4548C23.4168 13.8948 21.6529 12.0909 19.5746 10.5518C19.1705 10.2522 18.7552 9.96367 18.3318 9.68151C17.5397 9.15366 16.8052 8.5624 16.2584 7.87762C15.5128 6.94397 15.1183 5.83754 15.4101 4.47273C15.6651 3.28229 16.4396 2.90344 15.4053 2.20122C14.565 1.63057 13.4987 2.39778 12.6792 2.96209C10.1953 4.67404 8.77613 8.20099 10.0173 11.739C10.5689 13.3099 11.4974 14.5875 12.6311 15.7082C13.1202 16.1917 13.6462 16.6466 14.1994 17.0825C14.4159 17.2537 14.6356 17.4186 14.8473 17.5914C15.2385 17.91 15.6074 18.2508 15.8816 18.6851C16.0323 18.8294 16.1654 18.9911 16.2793 19.167C16.5439 19.5776 16.6978 20.0626 16.6978 20.5857C16.6978 21.2182 16.4733 21.7968 16.0997 22.2517C15.609 22.8477 14.8617 23.2297 14.023 23.2297C12.5461 23.2297 11.3483 22.0456 11.3483 20.5857C11.2761 18.8183 11.313 19.9311 11.313 19.9311C11.3002 20.0087 11.2649 19.1955 11.2761 18.8183C11.2793 18.6154 11.2665 18.4315 11.2424 18.2635C10.9987 16.5262 9.44643 16.588 8.18441 17.5311C7.01701 18.487 5.87686 18.2223 6.40925 16.4691C7.25433 13.9836 4.47213 12.4825 3.35283 14.8665C3.35123 14.8713 3.34802 14.8744 3.34642 14.8792C-0.447648 21.0882 4.25244 30.7798 11.507 31.4677C12.0587 31.5565 12.6039 31.5866 13.1379 31.5644C14.6468 31.6152 16.1959 31.3758 17.7065 30.8099C19.1705 30.2614 20.4566 29.4197 21.5262 28.3751C22.8844 27.0484 23.8898 25.395 24.4607 23.6007C25.1871 21.3165 25.208 18.8072 24.3501 16.4548Z"
      stroke="#B5BCC7"
      strokeWidth={2.3}
      strokeMiterlimit={5.75877}
    />
  </Svg>
);
export default ExploreIconDefault;
