export enum modelStoreNameEnum {
    User,
    Vault,
    SupportedAssets,
    Listings,
    FuelToken,
    Metadata
}

export class modelStore {
    static instance: modelStore = new modelStore();

    data = {};
    subscriptions = {};
    dataLoader = {};

    subscribe(owner: string, modelName: modelStoreNameEnum, cb: (...args: any[]) => any){
        if (!this.subscriptions[modelName]){
            this.subscriptions[modelName] = [];
        }
        var subObj = {owner: owner, cb: cb};
        var idx = this.subscriptions[modelName].findIndex(q => q.owner == owner);
        if (idx != -1) {
            this.subscriptions[modelName].splice(idx, 1, subObj);
        }
        else{
            this.subscriptions[modelName].push(subObj);
        }
        if (this.data[modelName]){
            cb(this.data[modelName]);
        }
    }

    getData(modelName: modelStoreNameEnum){
        return this.data[modelName];
    }

    setData(modelName: modelStoreNameEnum, data: any){
        this.data[modelName] = data;
        if (this.subscriptions[modelName]){
            for (var s of this.subscriptions[modelName]){
                s.cb(data);
            }
        }
    }

    reloadData(modelName: modelStoreNameEnum){
        let cb = this.dataLoader[modelName];
        if (!cb){
            return;
        }
        cb(modelName);
    }

    assignDataLoader(modelName: modelStoreNameEnum, cb: (...args: any[]) => any){
        this.dataLoader[modelName] = cb;
    }
}