import * as React from "react";
import Svg, { Circle } from "react-native-svg";
const StatusClose = (props) => (
  <Svg
    width={13}
    height={13}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={6.92} cy={6.5} r={5.5} fill="#E06365" stroke="white" />
  </Svg>
);
export default StatusClose;
