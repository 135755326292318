import React, { useState } from 'react';
import { View, Text, TouchableOpacity, Modal, StyleSheet, Image, Platform } from 'react-native';
import { WebView } from 'react-native-webview';

const CustomPopup = ({ videoUrl, thumbnailSource, onClose }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleThumbnailClick = () => {
    setModalVisible(true);
  };

  const handleClose = () => {
    setModalVisible(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <View>
      <TouchableOpacity onPress={handleThumbnailClick}>
        <Image source={thumbnailSource} style={styles.thumbnail} />
      </TouchableOpacity>

      <Modal
        animationType="fade"
        transparent={true} // Set transparent to true
        visible={modalVisible}
        onRequestClose={handleClose}
      >
        <View style={styles.modalContainer}>
          <View style={styles.popupContainer}>
            {Platform.OS === 'web' ? (
              <iframe
                src={videoUrl}
                width="100%"
                height="100%"
                frameBorder="0"
                allow="autoplay; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <WebView
                source={{ uri: videoUrl }}
                originWhitelist={['*']}
              />
            )}

            <TouchableOpacity onPress={handleClose} style={styles.closeButton}>
              <Text style={styles.closeButtonText}>Close</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
  },
  popupContainer: {
    width: '50%', // Adjust the width of the popup as needed
    height: '50%',
    backgroundColor: 'white', // Background color of the popup
    borderRadius: 8, // Add border radius for a rounded appearance
    overflow: 'hidden', // Hide overflow content outside the rounded border
  },
  closeButton: {
    position: 'absolute',
    top: 10, // Adjust the top position as needed
    right: 10, // Adjust the right position as needed
    backgroundColor: 'black',
    borderRadius: 4,
    padding: 10,
    width: '50px',
    alignItems: 'center',
  },
  closeButtonText: {
    color: 'white',
  },
  thumbnail: {
    width: 100,
    height: 100,
  },
});

export default CustomPopup;
