import React, { useContext, useState, useEffect } from 'react';
import { StyleSheet, View, Dimensions, TouchableOpacity, TouchableHighlight, Linking,} from 'react-native';
import { Card, Text } from 'react-native-paper';
import { Column as Col, Row } from 'react-native-flexbox-grid';
import tw from 'twrnc';
import { colors } from '../config/theme';
import { ThemeContext } from '../contexts/themeContext';
import MenuFuelLogo from '../assets/images/menuFuelLogo';
import Chrome from '../assets/images/chrome';
import Brave from '../assets/images/brave';
import Firefox from '../assets/images/firefox';
import {
  useFonts,
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
} from '@expo-google-fonts/inter';

export default function Guide() {
  let [fontsLoaded] = useFonts({
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
  });

  const { theme } = useContext(ThemeContext);
  let activeColors = colors[theme.mode];

  // Responsive column grid
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width);

  useEffect(() => {
    const handleWindowResize = ({ window }) => {
      setWindowWidth(window.width);
    };

    Dimensions.addEventListener('change', handleWindowResize);

    return () => {
      Dimensions.removeEventListener('change', handleWindowResize);
    };
  }, []);

  const calculateWidth = (sm, md, lg) => {
    let columns = 12; // Default column number for extra small devices

    if (windowWidth >= 320 && windowWidth < 1200) {
      columns = sm;
    } else if (windowWidth >= 1200 && windowWidth < 1230) {
      columns = md;
    } else if (windowWidth >= 1230) {
      columns = lg;
    }

    // Calculate percentage (i.e., '4 columns' out of '12 columns' is '33.33%')
    return `${(columns / 12) * 100}%`;
  };

  const notMobile = windowWidth >= 1200; // Not mobile devices

  const handleLinkPress = () => {
    const url = "https://chromewebstore.google.com/u/1/detail/fuel/hpnicckmeegjmdcbkknpogfnapnkjdfe?hl=en";
    Linking.openURL(url);
  };
  
  return (
    <Card elevation={0} style={[tw`mb-0 pt-0.5 pr-2.5 pl-2.5 pb-2`]}>
      <Card.Content style={[tw`rounded-xl`, { backgroundColor: activeColors.cardBgColor }, theme.mode === 'dark' ? styles.darkBoxshadow : styles.boxShadow]}>
        <Row size={12}>
          <Col style={[tw`justify-center items-center`, { width: calculateWidth(12, 12, 12) } ]}>
            <MenuFuelLogo style={[tw`mt-10`, {width: 94, height: 131}]} />
            <Text style={[tw`text-xs leading-5 mt-10 mb-10 text-center`, { color: activeColors.primaryTextColor, fontFamily: activeColors.interMedium }]}>
              Download Fuel extension 
              <br></br>
              to find or fund tasks on GitHub
            </Text>
          </Col>
        </Row>
        <View style={tw`flex-1 justify-center items-center w-full mb-9`}>
          <Row size={12}>
            <Col style={[tw`justify-end items-center pr-1.5 pl-1.5`, { width: calculateWidth(6, 6, 6) } ]}>
              <View style={[tw`justify-center items-center w-16 h-16 rounded-xl`, theme.mode === 'dark' ? styles.darkBoxshadow : styles.boxShadow,  theme.mode === 'dark' ? {backgroundColor: activeColors.buttonColor} : {backgroundColor: 'transparent'},]}>
                <TouchableHighlight onPress={handleLinkPress} underlayColor="transparent">
                  <Chrome style={tw`p-0.5`} />
                </TouchableHighlight>
              </View>
            </Col>
            <Col style={[tw`justify-center items-center pr-1.5 pl-1.5`, { width: calculateWidth(6, 6, 6) } ]}>
              <View style={[tw`justify-center items-center w-16 h-16 rounded-xl`, theme.mode === 'dark' ? styles.darkBoxshadow : styles.boxShadow,  theme.mode === 'dark' ? {backgroundColor: activeColors.buttonColor} : {backgroundColor: 'transparent'},]}>
                <TouchableHighlight onPress={handleLinkPress} underlayColor="transparent">
                  <Brave style={tw`p-0.5`} />
                </TouchableHighlight>
              </View>
            </Col>
          </Row>
        </View>
      </Card.Content>
    </Card>
  );
}

const styles = StyleSheet.create({
  boxShadow: {
    boxShadow: '0px 2px 14px 0px rgba(189, 193, 209, 0.30)',
  },
  darkBoxshadow: {
    boxShadow: 'none',
  },
  fuelText: {
    marginTop: 20,
    fontFamily: 'Inter_700Bold',
    fontSize: 12,
    color: '#6A727C',
  },
});