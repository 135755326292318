import React, { useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import tw from 'twrnc';
import { colors } from '../config/theme';
import { ThemeContext } from '../contexts/themeContext';

const LinearProgressBar = ({ progress, style }) => {
  const { theme } = useContext(ThemeContext);
  const activeColors = colors[theme.mode];

  let progressColor = activeColors.secondaryTextColor;

  if (progress >= 0 && progress < 25) {
    progressColor = 'linear-gradient(90deg, #627EEA 0%, #6298EA 74.13%)'; // Blue gradient
  } else if (progress >= 25 && progress < 50) {
    progressColor = 'linear-gradient(90deg, #F0B90B 0%, #F0CB0B 74.13%)'; // Yellow gradient
  } else if (progress >= 50 && progress < 100) {
    progressColor = 'linear-gradient(90deg, #E06365 0%, #ECB17C 74.13%)'; // Red and Orange gradient
  } else if (progress === 100) {
    progressColor = 'linear-gradient(90deg, #2DAF7D 0%, #60C884 74.13%)'; // Green gradient
  }

  return (
    <View style={[tw`w-full h-1.5 overflow-hidden rounded-3xl`, { backgroundColor: activeColors.progressBgColor }, style]}>
      <View style={[tw`h-full`, { background: progressColor }, { width: `${progress}%` }]} />
    </View>
  );
};

export default LinearProgressBar;