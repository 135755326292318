import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, Text, View, Dimensions, Platform } from 'react-native';
import ContainerWrapper from './containerWrapper';
import tw from 'twrnc';
import { colors } from '../config/theme';
import { useWeb3ModalTheme, useWeb3Modal, Web3Button } from '@web3modal/react';
import { ThemeContext } from '../contexts/themeContext';
import {
  useFonts,
  Inter_100Thin,
  Inter_200ExtraLight,
  Inter_300Light,
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
  Inter_700Bold,
  Inter_800ExtraBold,
  Inter_900Black,
} from '@expo-google-fonts/inter';
import { useAccount, useSignMessage, useDisconnect } from 'wagmi';
import { authTokenProvider } from '../services/authTokenProvider';
import { authService } from '../services/authService';
import { userApiService } from '../services/userApiService';
import { modelStore, modelStoreNameEnum } from '../services/modelStore';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Button from '../components/button';

function Header() {
  let [fontsLoaded] = useFonts({
    Inter_100Thin,
    Inter_200ExtraLight,
    Inter_300Light,
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_800ExtraBold,
    Inter_900Black,
  });

  const navigation = useNavigation(); // Initialize navigation
  const isWeb = Platform.OS === 'web'; // Check if it's web
  const { theme } = useContext(ThemeContext);
  const activeColors = colors[theme.mode];
  const { setTheme } = useWeb3ModalTheme(); // To customise web3modal
  const { address, isConnected, status, isDisconnected } = useAccount();
  const { data, isError, isLoading, isSuccess, signMessage, signMessageAsync } = useSignMessage();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [user, setUser] = useState(null);
  const { disconnectAsync  } = useDisconnect();
  const { open } = useWeb3Modal()
  var authToken = authTokenProvider.instance.authToken;

  const doAuth = async () => {
    var resp = await authService.instance.auth(address);
    try{
        var sigData = await signMessageAsync({message:resp.nonce});
    }
    catch(e){
        await disconnectAsync();
        return;
    }
    resp = await authService.instance.verify(address, sigData);
    authTokenProvider.instance.set(resp);
    modelStore.instance.setData(modelStoreNameEnum.User, await userApiService.instance.getUser(forceRefresh));
  }

  useFocusEffect(
    React.useCallback(() => {
      if (isDisconnected && navigation) {
        authTokenProvider.instance.logout();
        AsyncStorage.setItem('lastVisitedRoute', '');
        window.location.reload();
        navigation.navigate('Fuel');
      }
    }, [isDisconnected, navigation])
  );

  var [isMockingUser, SetIsMockingUser] = useState(false);
  var [loadingMock, SetLoadingMock] = useState(false);
  useEffect(() => {
    if (status !== 'connected') return;
    if (address && !authToken) {
      doAuth();
    }
    if (!address) {
      authTokenProvider.instance.logout();
      user = null;
    }
    else{
      modelStore.instance.subscribe("headerjs", modelStoreNameEnum.User, (usr) => {
        setUser(usr);
        var res = authTokenProvider.instance.isMockingUser();
        if (res){
            SetIsMockingUser(res);
        }
      });
    }
  }, [address, status, isDisconnected, navigation]);

  const stopMockUser = async() =>{
    if (loadingMock) return;
    SetLoadingMock(true)
    authTokenProvider.instance.revertMockUser();
    location.reload();
}

  // Set modal theme
  useEffect(() => {
    setTheme({
      themeMode: 'dark',
      themeVariables: {
        '--w3m-font-family': activeColors.interLight,
        '--w3m-accent-color': activeColors.walletBtnColour,
        '--w3m-background-color': activeColors.greyColor,
        '--w3m-logo-image-url': 'https://res.cloudinary.com/doguh0jje/image/upload/v1701102662/Fuel_logo_3D50px50px_b91n0k.png',
        '--w3m-text-medium-regular-size': '0.85rem',
        
      },
    });
  }, []);

  // Responsive column grid
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width);

  useEffect(() => {
    const handleWindowResize = ({ window }) => {
      setWindowWidth(window.width);
    };

    Dimensions.addEventListener('change', handleWindowResize);

    return () => {
      Dimensions.removeEventListener('change', handleWindowResize);
    };
  }, []);

  const calculateWidth = (sm, md, lg) => {
    let columns = 12; // Default column number for extra small devices

    if (windowWidth >= 320 && windowWidth < 1200) {
      columns = sm;
    } else if (windowWidth >= 1200 && windowWidth < 1230) {
      columns = md;
    } else if (windowWidth >= 1230) {
      columns = lg;
    }

    // Calculate percentage (i.e., '4 columns' out of '12 columns' is '33.33%')
    return `${(columns / 12) * 100}%`;
  };

  const notMobile = windowWidth >= 1200; // Not mobile devices

  return (
    <View
      style={[
        tw`w-full`,
        notMobile ? tw`mb-10` : tw`mb-5`,
        theme.mode === 'dark' ? styles.darkBoxshadow : styles.boxShadow,
      ]}
    >
      {/* <View style={[tw`p-2.5 justify-center items-center, h-11`, { backgroundColor: activeColors.notificationBgColor }]}>
        <Text style={[tw`text-center`, { color: activeColors.whiteColor, fontFamily: activeColors.interSemiBold}, notMobile ? tw`text-sm` : styles.subText]}>Scheduled system maintenance on June 18th, 8AM-11PM EST. Our services will be temporarily unavailable. We apologize for any inconvenience.</Text>
      </View> */}
      <View style={[tw`w-full py-3`, { backgroundColor: activeColors.topBarBgColor }]}>
        <ContainerWrapper>
          <View style={tw`flex-row items-center mr-2 w-full justify-end`}>
            <View style={[isWeb ? tw`block` : tw`hidden`]}>
              {/* <Button title={isConnected ? `${address.slice(0, 4)}...${address.slice(-4)}` : "Connect"}
                icon={isConnected ? <Ionicons name="person-circle-sharp" size={18} /> : '' }
                buttonStyle={[tw`items-center justify-center h-12 w-36 mt-3 mb-8`, { backgroundColor: activeColors.walletBtnColour }]}
                buttonTextStyle = {[{ color: activeColors.whiteColor, fontFamily: activeColors.interMedium}, notMobile ? tw`text-base` : tw`text-sm`]}
                iconStyle = {[tw`mr-1`,{ color: activeColors.whiteColor}]}
                onClick={() => open()}
              /> */}
              <Web3Button block icon="false"/>
            </View>
            {user && isMockingUser && (
              <View>
                  <h1 style={{color: 'red'}}>Mocking USER {user.id}</h1>
                  <button disabled={loadingMock} onClick={stopMockUser} style={{marginLeft:'1em'}}>Stop mock</button>
              </View>
            )}
          </View>
        </ContainerWrapper>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  boxShadow: {
    shadowColor: 'rgba(189, 193, 209, 0.3)',
    shadowOffset: { width: 4, height: 3 },
    shadowOpacity: 1,
    shadowRadius: 19,
  },
  darkBoxshadow: {
    shadowColor: 'transparent',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    shadowRadius: 0,
  },
  subText: {
    fontSize: 10,
  },
  avatar: {
    height: 72,
    width: 72,
  },
  
});

export default Header;