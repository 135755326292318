import * as React from "react";
import Svg, { Path } from "react-native-svg";
const HomeIconDarkMode = (props) => (
  <Svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3333 28C17.3333 28.7373 17.9293 29.3333 18.6667 29.3333H22.6667C24.0813 29.3333 25.4373 28.772 26.4373 27.772C27.4373 26.772 28 25.4146 28 24C28 23.2346 28 22.6666 28 22.6666C28 21.9306 27.4027 21.3333 26.6667 21.3333H18.6667C17.9293 21.3333 17.3333 21.9306 17.3333 22.6666V28ZM13.3333 29.3333C14.0693 29.3333 14.6667 28.7373 14.6667 28V14.6666C14.6667 13.9306 14.0693 13.3333 13.3333 13.3333H5.33333C4.596 13.3333 4 13.9306 4 14.6666V24C4 25.4146 4.56133 26.772 5.56133 27.772C6.56133 28.772 7.91867 29.3333 9.33333 29.3333H13.3333ZM25.3333 24H20V26.6666H22.6667C23.3733 26.6666 24.052 26.3866 24.552 25.8866C25.052 25.3866 25.3333 24.708 25.3333 24ZM12 26.6666V16H6.66667V24C6.66667 24.708 6.94666 25.3866 7.448 25.8866C7.948 26.3866 8.62533 26.6666 9.33333 26.6666H12ZM18.6667 2.66663C17.9293 2.66663 17.3333 3.26396 17.3333 3.99996V17.3333C17.3333 18.0706 17.9293 18.6666 18.6667 18.6666H26.6667C27.4027 18.6666 28 18.0706 28 17.3333V7.99996C28 6.58663 27.4373 5.22929 26.4373 4.22929C25.4373 3.22929 24.0813 2.66663 22.6667 2.66663H18.6667ZM20 5.33329V16H25.3333V7.99996C25.3333 7.29329 25.052 6.61462 24.552 6.11462C24.052 5.61462 23.3733 5.33329 22.6667 5.33329H20ZM14.6667 3.99996C14.6667 3.26396 14.0693 2.66663 13.3333 2.66663H9.33333C7.91867 2.66663 6.56133 3.22929 5.56133 4.22929C4.56133 5.22929 4 6.58663 4 7.99996V9.33329C4 10.0706 4.596 10.6666 5.33333 10.6666H13.3333C14.0693 10.6666 14.6667 10.0706 14.6667 9.33329V3.99996ZM12 5.33329H9.33333C8.62533 5.33329 7.948 5.61462 7.448 6.11462C6.94666 6.61462 6.66667 7.29329 6.66667 7.99996H12V5.33329Z"
      fill="#FFFFFF"
    />
  </Svg>
);
export default HomeIconDarkMode;
