import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SubmissionLow = (props) => (
  <Svg
    width={16}
    height={13}
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M5.71686 1.06636C5.71686 0.669016 5.39611 0.348267 4.99876 0.348267H3.09342C2.69607 0.348267 2.37532 0.669016 2.37532 1.06636V10.8755H5.71686V1.06636ZM10.1308 4.32173C10.1308 3.92438 9.81 3.60363 9.41266 3.60363H7.50731C7.10996 3.60363 6.78921 3.92438 6.78921 4.32173V10.8755H10.1308V4.32173ZM14.4824 7.3473C14.4824 6.94995 14.1617 6.62921 13.7643 6.62921H11.859C11.4616 6.62921 11.1409 6.94995 11.1409 7.3473V10.8755H14.4824V7.3473ZM15.5165 11.6942H1.39871C1.13541 11.6942 0.919983 11.9096 0.919983 12.1729C0.919983 12.4362 1.13541 12.6516 1.39871 12.6516H15.5213C15.7846 12.6516 16 12.4362 16 12.1729C16 11.9096 15.7846 11.6942 15.5213 11.6942H15.5165Z"
      fill="#7BC950"
    />
  </Svg>
);
export default SubmissionLow;
