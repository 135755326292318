import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
const Successful = (props) => (
  <Svg
    width={10}
    height={9}
    viewBox="0 0 10 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M8.61517 0.9541C8.91664 0.631099 9.42287 0.613643 9.74587 0.91511C10.0689 1.21658 10.0863 1.72281 9.78486 2.04581L4.18486 8.04581C3.89237 8.35919 3.40485 8.38633 3.07938 8.10736L0.279381 5.70736C-0.0560793 5.41982 -0.0949285 4.91478 0.192609 4.57932C0.480147 4.24386 0.985187 4.20501 1.32065 4.49255L3.53841 6.39349L8.61517 0.9541Z"
      fill="#5C80ED"
    />
  </Svg>
);
export default Successful;
