import {API_BASE_PATH} from '@env'
import { authTokenProvider } from './authTokenProvider';
import axios from 'axios';
export class authService {
    static instance: authService = new authService();

    async auth(address: string) {
        var res = await axios.post(`${API_BASE_PATH}/auth`, {walletId: address});
        return res.data;
    }

    async verify(address: string, signature: string){
        var res = await axios.post(`${API_BASE_PATH}/auth/verify`, {walletId: address, signature: signature});
        return res.data;
    }

    async mockUser(address: string){
        var res = await axios.post(`${API_BASE_PATH}/admin/mockUser`, {walletId: address});
        return res.data;
    }
}