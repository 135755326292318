import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { View, Text, TouchableOpacity } from 'react-native';
import Home from "../screens/home";
import Wallet from "../screens/wallet";
import Explore from '../screens/explore';
import Settings from '../screens/settings';
import Ionicons from '@expo/vector-icons/Ionicons';
import tw from 'twrnc';
import { colors } from "../config/theme";
import { useContext } from 'react';
import { ThemeContext } from '../contexts/themeContext';

const Tab = createBottomTabNavigator();

function MobileMenuNavigator(){

    const {theme} = useContext(ThemeContext);
    let activeColors = colors [theme.mode];

    return (
         <Tab.Navigator
            screenOptions={({ route }) => ({
                tabBarActiveTintColor: activeColors.navbarItemColor,
                tabBarShowLabel: false,
                headerShown: false,
                tabBarStyle: {
                    position: 'absolute',
                    height: 90,
                    paddingLeft: 15,
                    paddingRight: 15,
                    elevation: 0,
                    backgroundColor: activeColors.navbarBgColor,
                    borderTopWidth: 0,
                },
            })}
         >
            <Tab.Screen 
                name='Dashboard' 
                component={Home} 
                options={{
                    tabBarIcon: ({focused}) => (
                    <View style={{alignItems: 'center', justifyContent: 'center', top: 5}}>
                        <Ionicons 
                        name='md-grid-outline' size={22} 
                        style={{
                            color: focused ? activeColors.navbarActiveItemColor : activeColors.navbarItemColor,
                        }}
                        />
                        <Text style={[tw`text-xs mt-2`, {color: focused ? activeColors.navbarItemColor : activeColors.navbarItemColor,}]}>Dashboard</Text>
                    </View>
                    ),
                    tabBarActiveTintColor: activeColors.navbarActiveItemColor,
                    tabBarInactiveTintColor: activeColors.navbarItemColor,
                }} 
            />
            <Tab.Screen 
                name='Explore' 
                component={Explore} 
                options={{
                    tabBarIcon: ({focused}) => (
                    <View style={{alignItems: 'center', justifyContent: 'center', top: 5}}>
                        <Ionicons 
                        name='ios-git-branch-outline' size={22} 
                        style={{
                            color: focused ? activeColors.navbarActiveItemColor : activeColors.navbarItemColor,
                        }}
                        />
                        <Text style={[tw`text-xs mt-2`, {color: focused ? activeColors.navbarItemColor : activeColors.navbarItemColor,}]}>Explore</Text>
                    </View>
                    ),
                    tabBarActiveTintColor: activeColors.navbarActiveItemColor,
                    tabBarInactiveTintColor: activeColors.navbarItemColor,
                }} 
            />
            <Tab.Screen 
                name='Wallet' 
                component={Wallet} 
                options={{
                    tabBarIcon: ({focused}) => (
                    <View style={{alignItems: 'center', justifyContent: 'center', top: 5}}>
                        <Ionicons 
                        name='ios-wallet-outline' size={22} 
                        style={{
                            color: focused ? activeColors.navbarActiveItemColor : activeColors.navbarItemColor,
                        }}
                        />
                        <Text style={[tw`text-xs mt-2`, {color: focused ? activeColors.navbarItemColor : activeColors.navbarItemColor,}]}>My Wallet</Text>
                    </View>
                    ),
                    tabBarActiveTintColor: activeColors.navbarActiveItemColor,
                    tabBarInactiveTintColor: activeColors.navbarItemColor,
                }} 
            />
            <Tab.Screen 
                name='Settings' 
                component={Settings} 
                options={{
                    tabBarIcon: ({focused}) => (
                    <View style={{alignItems: 'center', justifyContent: 'center', top: 5}}>
                        <Ionicons 
                        name='ios-settings-outline' size={22} 
                        style={{
                            color: focused ? activeColors.navbarActiveItemColor : activeColors.navbarItemColor,
                        }}
                        />
                        <Text style={[tw`text-xs mt-2`, {color: focused ? activeColors.navbarItemColor : activeColors.navbarItemColor,}]}>Settings</Text>
                    </View>
                    ),
                    tabBarActiveTintColor: activeColors.navbarActiveItemColor,
                    tabBarInactiveTintColor: activeColors.navbarItemColor,
                }} 
            />
         </Tab.Navigator>
    );
}

export default MobileMenuNavigator