import * as React from "react";
import Svg, { G, Path, Defs, ClipPath, Rect } from "react-native-svg";
const SubmissionMedium = (props) => (
  <Svg
    width={16}
    height={13}
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G clipPath="url(#clip0_1506_5180)">
      <Path
        d="M10.1355 7.35209C10.1355 6.95474 9.81479 6.63399 9.41744 6.63399H7.5121C7.11475 6.63399 6.794 6.95474 6.794 7.35209V10.8803H10.1355V7.35209ZM14.4872 1.06636C14.4872 0.669016 14.1664 0.348267 13.7691 0.348267H11.8638C11.4664 0.348267 11.1457 0.669016 11.1457 1.06636V10.8755H14.4872V1.06636ZM15.5213 11.6942H1.39871C1.13541 11.6942 0.919983 11.9096 0.919983 12.1729C0.919983 12.4362 1.13541 12.6516 1.39871 12.6516H15.5213C15.7846 12.6516 16 12.4362 16 12.1729C16 11.9096 15.7846 11.6942 15.5213 11.6942ZM5.82218 4.32173C5.82218 3.92438 5.50143 3.60363 5.10408 3.60363H3.19874C2.80139 3.60363 2.48064 3.92438 2.48064 4.32173V10.8755H5.82218V4.32173Z"
        fill="#ECB17C"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_1506_5180">
        <Rect
          width={15.08}
          height={12.3034}
          fill="white"
          transform="translate(0.919983 0.348267)"
        />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SubmissionMedium;

