import React, { useContext, useState, useEffect } from 'react';
import { View, ScrollView, TouchableOpacity, Text, Dimensions } from 'react-native';
import { colors } from '../config/theme';
import { ThemeContext } from '../contexts/themeContext';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withSpring,
} from 'react-native-reanimated';
import tw from 'twrnc';
import { Ionicons } from '@expo/vector-icons';

const CustomCarousel = ({ texts }) => {
  const [isBackDisabled, setIsBackDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const { theme } = useContext(ThemeContext);
  const activeColors = colors[theme.mode];

  const currentIndex = useSharedValue(0);
  const { width: windowWidth } = Dimensions.get('window');

  useEffect(() => {
    const updateButtonStates = (index) => {
      setIsBackDisabled(index === 0);
      setIsNextDisabled(index === texts.length - 1);
    };

    updateButtonStates(currentIndex.value);
  }, [currentIndex.value, texts.length]);

  const calculateWidth = (xxsm, xsm, sm, md, lg, xlg, xxlg, xxxlg, xxxxlg) => {
    let columns = 12; // Default column number for extra small devices

    if (windowWidth >= 320 && windowWidth <= 767) {
      columns = xxsm;
    } else if (windowWidth >= 768 && windowWidth <= 991) {
      columns = xsm;
    } else if (windowWidth >= 992 && windowWidth <= 1019) {
      columns = sm;
    } else if (windowWidth >= 1020 && windowWidth <= 1119) {
      columns = md;
    }  else if (windowWidth >= 1120 && windowWidth <= 1199) {
      columns = lg;
    } else if (windowWidth >= 1200 && windowWidth <= 1399) {
      columns = xlg;
    } else if (windowWidth > 1400 && windowWidth <= 1499) {
      columns = xxlg;
    } else if (windowWidth > 1500 && windowWidth <= 1519) {
      columns = xxxlg;
    } else if (windowWidth > 1520 && windowWidth <= 1920) {
      columns = xxxxlg;
    }

    // Use the pixel values directly based on the screen width
    switch (columns) {
      case 1:
        return 300;
      case 2:
        return 500;
      case 3:
        return 678;
      case 4:
        return 720;
      case 5:
        return 768;
      case 6:
        return 920;
      case 7:
        return 1120;
      case 8:
        return 1140;
      case 9:
        return 1240;
      default:
        return 1240;
    }
  };

  const parentWidth = calculateWidth(1, 2, 3, 4, 5, 6, 7, 8, 9);

  const goToNextText = () => {
    if (!isAnimating) {
      const nextIndex = (currentIndex.value + 1) % texts.length;
      setIsAnimating(true);
      currentIndex.value = withSpring(nextIndex, {}, () => {
        setIsAnimating(false);
      });
    }
  };

  const goToPreviousText = () => {
    if (!isAnimating) {
      const prevIndex = (currentIndex.value - 1 + texts.length) % texts.length;
      setIsAnimating(true);
      currentIndex.value = withSpring(prevIndex, {}, () => {
        setIsAnimating(false);
      });
    }
  };

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: `${-currentIndex.value * parentWidth}px` }],
    };
  });

  return (
    <View style={tw`flex-1 -mb-10`}>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        onLayout={() => {
          // Recalculate width on layout changes
          currentIndex.value = 0;
        }}
      >
        <Animated.View style={[tw`flex-row`, animatedStyle]}>
          {texts.map((item, index) => (
            <View key={index} style={[tw`h-44`, { width: parentWidth }]}>
              <View style={{ flex: 1 }}>
                <Text>{item.content}</Text>
              </View>
            </View>
          ))}
        </Animated.View>
      </ScrollView>
      <View style={tw`flex-row justify-end relative -top-16 right-1.5 z-50 `}>
        <TouchableOpacity onPress={goToPreviousText} disabled={isBackDisabled}>
          <Ionicons
            name="chevron-back-outline"
            size={24}
            style={[tw`mr-2.5`, {color: activeColors.customPickerIcon}, isBackDisabled &&  {color: activeColors.customPickerIcon, opacity: 0.5}]}
          />
        </TouchableOpacity>
        <TouchableOpacity onPress={goToNextText} disabled={isNextDisabled}>
          <Ionicons 
            name="chevron-forward-outline" 
            size={24} style={[{color: activeColors.customPickerIcon}, isNextDisabled && {color: activeColors.customPickerIcon, opacity: 0.5}]} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default CustomCarousel;
