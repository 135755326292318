import React, { useContext } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { colors } from '../config/theme';
import { ThemeContext } from '../contexts/themeContext';
import tw from 'twrnc';

export function Stepper({ step, isLastStep, filled, showLine = true }) {
  const { theme } = useContext(ThemeContext);
  const activeColors = colors[theme.mode];
  
  const stepperButtonColor = filled ? activeColors.stepperActiveButtonColor : activeColors.stepperButtonColor;
  const stepperColor = filled ? activeColors.stepperActiveButtonTextColor : activeColors.stepperButtonTextColor;
  const dotColor = filled ? activeColors.stepperDotActiveColor : activeColors.stepperButtonColor;
  const dotOpacity = filled ? 1 : 0.5; // Set opacity based on filled

  const dotCount = 5; // Change this number to increase or decrease the length of the dotted line

  return (
    <View style={{ marginRight: 10, alignItems: 'center', position: 'relative', top: 25 }}>
      <View style={[
        styles.stepperButton,
        { backgroundColor: stepperButtonColor }
      ]}>
        <Text style={{ fontSize: 12, fontWeight: 'bold', color: stepperColor }}>{step}</Text>
      </View>
     <View style={tw`-mt-1`}>
        {!isLastStep && showLine && Array(dotCount).fill().map((_, i) => (
          <View key={i} style={{ width: 8, height: 8, borderRadius: 4, backgroundColor: dotColor, marginVertical: 1.5, opacity: dotOpacity }} />
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  stepperButton: {
    width: 43,
    height: 43,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 3,
  },
});
