import React, { useState, useEffect, useContext } from 'react';
import { View, Dimensions, Platform, Text } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { ThemeContext } from './contexts/themeContext';
import { colors } from './config/theme';
import { storeData, getData } from './config/asyncStorage';
import { arbitrum, avalanche, bsc, fantom, gnosis, mainnet, optimism, polygon } from 'wagmi/chains';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createConfig, WagmiConfig, useAccount } from 'wagmi';
import { authTokenProvider } from './services/authTokenProvider';
import {WALLET_CONNECT_PROJECTID} from '@env';
import { webhookService, webhookTypeEnum } from './services/webhookService';
import { userApiService } from './services/userApiService';
import { authService } from './services/authService';
import { walletApiService } from './services/walletApiService';
import { modelStore, modelStoreNameEnum } from './services/modelStore';
import { cryptoCoinApiService } from './services/cryptoCoinApiService';
import { MaterialIcons } from '@expo/vector-icons';
import { tokenApiService } from './services/tokenService';
import {
  useFonts,
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
} from '@expo-google-fonts/inter';
import tw from 'twrnc';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SplashScreen from "expo-splash-screen";
import axios from 'axios';
import SideMenu from './navigations/sideMenu';
import MobileMenuNavigator from './navigations/mobileMenu';
import Home from './screens/home';
import Wallet from './screens/wallet';
import Settings from './screens/settings';
import Explore from './screens/explore';
import Activity from './screens/activity';
import Login from './screens/login';
import BackendTest from './testcomponents/backendTest';

// To keep the splash screen visible while we fetch the app resources
SplashScreen.preventAutoHideAsync();

const Stack = createStackNavigator();

const chains = [mainnet, polygon, avalanche, arbitrum, bsc, optimism, gnosis, fantom]
const projectId = WALLET_CONNECT_PROJECTID;
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 2, chains }),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)

axios.interceptors.request.use(
  config => {
    const token = authTokenProvider.instance.authToken;
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    config.headers['Content-Type'] = 'application/json';
    return config
  },
  error => {
    Promise.reject(error)
  }
)
axios.interceptors.response.use(
  res => res,
  err => {
    if (err.response && err.response.data && err.response.data.JoinFuelApiException){
      throw err.response.data.JoinFuelApiException;
    }
    throw err;
  }
)

const App = () => {

  let [fontsLoaded] = useFonts({
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
  });

  const isWeb = Platform.OS === 'web';
  
  const [user, setUser] = useState(null);
  const [showMessage, setShowMessage] = useState(Dimensions.get('window').width < 768);

  useEffect(() => {
    const handleWindowResize = ({ window }) => {
      setShowMessage(window.width < 768);
    };

    Dimensions.addEventListener('change', handleWindowResize);

    return () => {
      Dimensions.removeEventListener('change', handleWindowResize);
    };
  }, []);


  const [theme, setTheme] = useState({mode: "light"});

  const updateTheme = (newTheme) => {
    let mode;
    if(!newTheme){
      mode = theme.mode === "dark" ? "light" : "dark";
      newTheme = {mode};
    }
    setTheme(newTheme);
    storeData("newFuelTheme", newTheme)
  }

  const activeColors = colors[theme.mode];

  const fetchStoredTheme = async () => {
    try {
      const themeData = await getData("newFuelTheme");
      if (themeData) {
        updateTheme(themeData);
      }
    } catch ({message}) {
      alert (message);
    } finally {
      await setTimeout(() => SplashScreen.hideAsync(), 1000);
    }
  };

  useEffect(() => {
    fetchStoredTheme();
  }, []);

  var authToken = authTokenProvider.instance.authToken;
  const { address, status } = useAccount()

  const getUser = async (forceRefresh = false) => {
    modelStore.instance.setData(modelStoreNameEnum.User, await userApiService.instance.getUser(forceRefresh));
  }

  const loadData = async () => {
    getVault();
    modelStore.instance.setData(modelStoreNameEnum.SupportedAssets, await walletApiService.instance.getSupportedAssets());
    //modelStore.instance.setData(modelStoreNameEnum.Listings, (await cryptoCoinApiService.instance.getListings()).data);
    webhookService.instance.subscribe("App", webhookTypeEnum.TransactionUpdate, (msg) => {
      getVault();
    })
    webhookService.instance.subscribe("App", webhookTypeEnum.Test, (msg) => {
      console.log("testing webhook");
    })
    modelStore.instance.assignDataLoader(modelStoreNameEnum.Vault, () => {
      getVault();
    });
  }

  const getVault = async() => {
    var vault = await walletApiService.instance.getVault();
    modelStore.instance.setData(modelStoreNameEnum.Vault, vault);
    let coinSymbol = vault.assets.map(t => t.coinSymbol).join(',');
    var metadata = await cryptoCoinApiService.instance.getMetadata(coinSymbol);
    var assetMetadata = await modelStore.instance.getData(modelStoreNameEnum.Metadata);
    if (!assetMetadata){
      assetMetadata = {};
    }
    var s = coinSymbol.split(",");
    for (var a of s){
        if (!metadata.data[a]) {
          continue;
        }
        var res = metadata.data[a];
        assetMetadata[a] = res;
    }
    var newOne = JSON.parse(JSON.stringify(assetMetadata));
    await modelStore.instance.setData(modelStoreNameEnum.Metadata, newOne);
    var resQuote = (await cryptoCoinApiService.instance.getQuotes(coinSymbol)).data;
    var listings = [];
    for (var k of Object.keys(resQuote)){
      listings.push(resQuote[k]);
    }
    modelStore.instance.setData(modelStoreNameEnum.Listings, listings);
  }
  
  useEffect(() => {
    modelStore.instance.subscribe("App", modelStoreNameEnum.User, (user) => {
      setUser(user);
      if (!authTokenProvider.instance.authToken) {
        webhookService.instance.disconnect();
      }
      else{
        webhookService.instance.connect();
      }
    });
  }, []);

  useEffect(() => {
    if (address && authToken) {
      loadData();
    }
  }, [address, authToken]);

  useEffect(() => {
      if (status != 'connected') return;
      if (address && authToken){
          getUser();
      }
  }, [address,status,authToken]);

  // Check for authToken in Fuel app local storage and dispatch to fuel plugin extension
  useEffect(() => {
    const handleAuthTokenRequest = () => {
      const authToken = localStorage.getItem('authToken') || null; 
      const event = new CustomEvent('provideAuthToken', { detail: authToken });
      document.dispatchEvent(event);
    };

    document.addEventListener('requestAuthToken', handleAuthTokenRequest);
    return () => {
      document.removeEventListener('requestAuthToken', handleAuthTokenRequest);
    };
  }, []);

  // Logic to store previous screen
  const [lastVisitedRoute, setLastVisitedRoute] = useState('Dashboard'); // Default route

  useEffect(() => {
    // Use AsyncStorage to get the lastVisitedRoute
    AsyncStorage.getItem('lastVisitedRoute').then((storedLastVisitedRoute) => {
      if (storedLastVisitedRoute) {
        setLastVisitedRoute(storedLastVisitedRoute);
      }
    });
  }, []);

  // Function to update lastVisitedRoute
  const updateLastVisitedRoute = (routeName) => {
    setLastVisitedRoute(routeName);
    // Use AsyncStorage to save the lastVisitedRoute
    AsyncStorage.setItem('lastVisitedRoute', routeName);
  };

  const AuthStack = (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Fuel" component={Login} />
    </Stack.Navigator>
  );

  const AppStack = (
    <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={lastVisitedRoute}>
      <Stack.Screen name="Dashboard" component={Home}
        listeners={{
          focus: () => updateLastVisitedRoute('Dashboard'),
        }}
      />
      <Stack.Screen name="Explore" component={Explore}
        listeners={{
          focus: () => updateLastVisitedRoute('Explore'),
        }}
      />
      <Stack.Screen name="Activity Log" component={Activity}
        listeners={{
          focus: () => updateLastVisitedRoute('Activity Log'),
        }}
      />
      <Stack.Screen name="My Wallet" component={Wallet} 
        listeners={{
          focus: () => updateLastVisitedRoute('My Wallet'),
        }}
      />
      <Stack.Screen name="Settings" component={Settings}
        listeners={{
          focus: () => updateLastVisitedRoute('Settings'),
        }}
      />
      <Stack.Screen name="Admin" component={BackendTest} 
        listeners={{
          focus: () => updateLastVisitedRoute('Admin'),
        }}
      />
    </Stack.Navigator>
  );


  return (
    <>
      {showMessage &&  (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: activeColors.appBackgroundColor }}>
          <MaterialIcons name="browser-not-supported" size={120} style={{ color: activeColors.primaryTextColor }} />
          <Text style={[ tw`mt-5 mb-7 text-base`,{ fontSize: 24, textAlign: 'center', color: activeColors.primaryTextColor, fontFamily: activeColors.interRegular }]}>
            Screen size is not supported.
          </Text>
        </View>
      )}
      {!showMessage && (
        <>
          <WagmiConfig config={wagmiConfig}>
            <ThemeContext.Provider value={{ theme, updateTheme }}>
              <NavigationContainer theme={{ colors: { background: theme.mode === 'dark' ? '#000000' : '#ffffff' } }}  initialRouteName={lastVisitedRoute}>
                {user ? (
                  Platform.OS === 'web' ? (
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                      <SideMenu />
                      <View style={{ flex: 1 }}>{AppStack}</View>
                    </View>
                  ) : (
                    <MobileMenuNavigator />
                  )
                ) : (
                  <Stack.Navigator screenOptions={{ headerShown: false }}>
                    <Stack.Screen name="Fuel" component={Login} />
                  </Stack.Navigator>
                )}
                <StatusBar
                  style={theme.mode === 'dark' ? 'light' : 'dark'}
                  color={theme.mode === 'dark' ? '#000000' : '#ffffff'}
                />
              </NavigationContainer>
            </ThemeContext.Provider>
          </WagmiConfig>
          <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
        </>
      )}
    </>
  );
};

export default App;